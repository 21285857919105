import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountingEntriesFilterAccountingMonthSelector,
  accountingEntriesChangeFilterAccountingMonth,
} from '../../../state';
import DatePickerFlexSales, { DatePickerFlexSalesView } from '../../DatePickerFlexSales';

const views: DatePickerFlexSalesView[] = ['year', 'month'];

const minDate = new Date(2015, 0, 1);
const maxDate = new Date();

const AccountingMonthFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const value = useSelector(accountingEntriesFilterAccountingMonthSelector);

  const handleChange = useCallback(
    (newValue: Date | null) => {
      dispatch(accountingEntriesChangeFilterAccountingMonth(newValue));
    },
    [dispatch]
  );

  return (
    <DatePickerFlexSales
      views={views}
      value={value.accept}
      onChange={handleChange}
      label={t('accountingEntries.columns.accountingMonth')}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default AccountingMonthFilter;
