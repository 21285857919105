import { ReactNode, RefAttributes, useState } from 'react';
import Stack from '@mui/material/Stack';
import DatePickerFlexSales from './DatePickerFlexSales';

export type DateRangeFlexSalesValue<TDate> = [TDate | null, TDate | null];

export interface DateRangeFlexSalesProps<TDate> extends RefAttributes<HTMLDivElement> {
  startText: ReactNode;
  endText: ReactNode;
  minDate?: TDate;
  maxDate?: TDate;
  spacing?: number;
  value: DateRangeFlexSalesValue<TDate>;
  onChange: (value: DateRangeFlexSalesValue<TDate>) => void;
}

const DateRangeFlexSales = <TDate,>({
  startText,
  endText,
  minDate,
  maxDate,
  spacing,
  value,
  onChange,
}: DateRangeFlexSalesProps<TDate>) => {
  const values = value || [null, null];
  // const [startError, setStartError] = useState<DateValidationError | undefined>(undefined);
  // const [endError, setEndError] = useState<DateValidationError | undefined>(undefined);
  const [startValue, setStartValue] = useState<TDate | null>(values[0]);
  const [endValue, setEndValue] = useState<TDate | null>(values[1]);

  const onChangeStartValue = (value: TDate | null) => {
    onChange([value, endValue]);
    setStartValue(value);
  };

  const onChangeEndValue = (value: TDate | null) => {
    onChange([startValue, value]);
    setEndValue(value);
  };

  return (
    <Stack direction="row" spacing={spacing}>
      <DatePickerFlexSales
        label={startText}
        minDate={minDate || undefined}
        maxDate={endValue || maxDate || undefined}
        value={startValue}
        onChange={onChangeStartValue}
      />
      <DatePickerFlexSales
        label={endText}
        minDate={startValue || minDate || undefined}
        maxDate={maxDate || undefined}
        value={endValue}
        onChange={onChangeEndValue}
      />
    </Stack>
  );
};

export default DateRangeFlexSales;
