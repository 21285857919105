import JsPDF from 'jspdf';
import autoTable, { CellHook, PageHook, Styles, UserOptions } from 'jspdf-autotable';
import { TablesExportBase } from './TablesExportBase';

const logoBase64 = [
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAACAASURBVHic7Z17fFxVub+fd+3JdWbSlmu5KCilzSRQgQqKqLQcL9g2SQtaUVERFeQoBxQUQc/P4hVR0KMepF44IiISBZqkrSBo8YqK5dpcilUrIJQitM1MkiaZvd7fH5O0uczs2TOZycyk83w+0Gb22mu90+zvXrd3vS+UKVOmTJkyZcqUyTFSaAP2R3TNooro4dFjjJijrOUwgZeichjCIQh1qswWCJP4r2bC3cNgYqC7UPpUtA+VHSL6NJjtKvq0o+bpPYHBLQcu3dpbiO83kygLJM/sal/wMkfMKaguBKkHaUD0GJSKaWj+GdAeRLYoPGQwDwZDB3fKkvvj09D2jKAskByiqzH9Jza8QrFnYGSJKqcABxfarrEI9Ck8LMIfEP1VUIO/laZN/YW2q1gpC2SK7LzrhNkBM3gmhhaUNwIHFtqmDBkEHhDVdXEbWDt75ea/FdqgYqIskCx4YcO8ugq38mxB3wmcPk3DpelBeBzVn7lu4NayWMoC8Y2uxvQtqn+DqpwPNDNp8jzjUIQHQG7ZY91bD27ZEi20QYWgLJA09HbMPwjrnC+GC1COKbQ9BSKKcqtgvx1q2fJYoY2ZTsoCScGu9gUvc5BLQT4A1BbanuJBfq9iv1zX1NNRaEumg7JAJhBdFzkOy38DZwNOgcxQhO0ozyK8COzEsktgF4IFsKK7UFEQx6B1KhIU1RoV6kAOQnUuwuEos/JhoAh/snBNeHl3mwiajzaKgbJARuhdXz9fXPMZ0HMAM03NPgN0qsrjiHaJ6N/ceOCpWcP6lKzqHMpFA9p6ZE1vTfhILPMdtF6RBUA9hoU5Es8msX',
  'wytKL7vhzUVXTs9wLpvbP+QAnIZ4ELgEAem4oCf0b4g1F5IF4pf5p1ZueLeWzPE12NiS6aP9/gnKLIyaieCpxI9i+He43RK4PLezbl0MyCs98KRDcuDsRiO/4T1dXAnDw04QJ/Br3binN33UDnJlmFm4d2ckbvnfUHimPOAH2jCG9UODrDKlTgJivuJ+uanvh3PmycbvZLgfS1LXilxXwX4YQcVz0I/FzQVrfSuaeQPUQuiLUtWKiYtyHyVtD6DG59QVWvDD/c831ZnZgzlSr7lUC237MwWLsn/lkRvYTcTcBdhV8Y1duHBofWHrDq77tzVG9REWtbsNDivFdE3wUc6vO2P6q476treqInn7blk/1GINH2+tMF+T+Fl+Woyn+BfN+x5nu1KzY/laM6ix7duDgQjW1/CyofEjiT9HOWAYGrgg91f6MUe5MZLxDduDgQiz63Gvgkueg1RDaqla+HBzvXF/ucIt/0rq+fL9ZcjOp5QChN8V/HXT1vzsqebfm3LHfMaIEMrI8cFXflVtDTpliVFdhgRb9Y19TzQE6Mm0G8sGFeXUW84iKBK/Be8OhFzPvDTZ0/my7bpsqMFUhvR32TqPwIqJtCNRbkJ6ruZ+tatmzJlW0zlRdbXz6roqbqYpTLgNkpiqmi14XDc68shXMpM04gqkisPfJphNVMYcNP4efGMVeFlnU+kjvr9g96O+YfJAQ+A3qhh6fz70Sdt4ZaNj83rcZlyIwSyPNtC8LVmJsRVmZdifIIRj8abuq5P3eW7Z8kvBPky8CKZNcV/mmsNoVW9Dw+zab5ZsYIJLa+ca66dgOJ3eCMEeiz8Llw+NDrSqHrLyWiHfWLsbIGYX6SyzEVfWexOj/OCIHs7ogc6yj3ZL+EKz82w3p58OzuZ3NrWZlR0uxBuYh8JNzUdWMhbPOi5AXS1954ssWuJ5uz38KzqnpBXXPPutxbViYZfevqF1krNwELJ15T1avqWnq+VACzUlLSAuld1/',
  'A6sbqB9GvwSZAf20q5uNTdQUoR3Xh0dSxafS3IR5jwDIpwbXB59yeLxYW+ZAUyBXHERPhwqKn7h/mwq4x/drdFlhnh/5jc+98Qaur+SDGIpCQFkr049FFVfXt5T6N46Lsjcpit4MfA4nEXhP8JN3VfWhCjxjBdB4Nyxu72yKuzEofqraE9sVPL4igugmd3Pxt6tvZNwA3jLiiXxDoiXy6MVfsoqR6kt23BAhHzO+CgDG5zBflkqLnrq/myq0xuiLbXXwDyTaBy74eiq8NNPVcXyqaSEUhf24LDVczvMznEI9AnwqpgU/eGPJpWJoeMDJ/vYkwAPkXfV9fc84NC2FMSAnlhw7y6ynjgNyCv8H+X7jA4y4PNnQ/mz7Iy+SC6trEBce9B5MiRj4ZQe2a4ZcvG6bal6OcguhpTFa+4NRNxCPzDdQOvKYujNAmv6OwKBOS1wOh8sRIxd/R2zM/kVGNOKHqBxE6MXK2w3PcNyhMmMPz6ctjM0qZmWfc/CQy/DhgNAjFHcNZFN8yb1mDgRS2QaHv92QifyuCWLhNnce3SrU/nzagy00Z46dbnXRN4I0rCo1o5BrdirW48unq6bChagUTXHRcB+QH+50l/FXXOKPtTzSxmL398JxXDbwI6AVBeE+ut+dp0tV+UAtEN86qw7q343etQfTru6puK/WxBmewIL936PPGKM4BuAIQPxToa3jUdbRelQGLximvw77b+b0XfUGpnnctkRvisx3Y41nkziWiUqOoNu9sa5+W73aITSGxd/ZuBS3wW36NqV5R3x/cPaldsfsoITQJ9QJ0Re4e2HpnXNBRFJZDddzceoNb3vENR3lfXsuX3eTarTBERbOp+yArvBiywMFYdzquHRFFtFMba629S5H2+ChfYBaEY0dbGylitHCOueyTo4SpyJCJzFQ2LSgjRWShBhL5xN1rdJUZ6rbIb5N8GfUrRJ11jn5oVPPzJYjxh2dsR+bgo1wKgena4pefOfLRTNA',
  'KJtjeeAfY+fNgksC74UHdLKQYiyxW9d9Yf6FTIq6yVUxCOA21AmJeHdHBDQA9CF8pmEX1w0In/sRhSTEfbG24lkQbv3+KY40PLOrfnuo2iEMj2exYGg4Pxx0Bfnraw8DdXAifPXv74zmkwrWhIJAsdeqMYPVOV04D5FO73Z4Eu0N8D98bd6l/OWfnIruk24oUN8+oq3YqHRjJ/rQ03d2cfrCMFRSGQWEfDF1X1Sh9Fh4xwarCp+6G8G1UE9HfMP8Kqs0qVlQinkt/0DFMhDvInFbvOxgM/nU4vhpEjvL8HqkTl3aGWrh/lsv6CCySR6sx0AWl3RxU+WdfcXfAzAvnk+bYF4SrkHBF5J/B6imwhxSebVGkNGPfW2qYn/pXvxqLtDReDfgPYadQeF2zZ8kyu6i64QKIdkVaUt/ko+uvQQ91nzNR5R2/bggVGzPs0kcgnH/lKCoFF+BVWvhOqO+SufE32VZFYR+QuoCXXrvEFFciI7/+vfdixKyDmFTVNnU9Oh13TSbS9/nREPo3yhkLbkmeeFPha/x7zvUNWdcZyXfnzbQvCNcZpDg7IT3OVvg4KLJBoe+QB4NXpyqnqBXUtPd+dBpOmjVhH/ZvUyqcRXjeFauLA3xT+LuiTqvzTiDxt0Z2o7hQN7HQDw3sSRat2AlQMxquHq9yaCjcwKy5aJWoOEXHnAnNROQzhWBILAC+Z4ldMxU7Qb6vYr5VCFqqCCaSvveEtFk170k+EPwU3db9mpgytejvqTxWV6/HxYpjAIMImVf2DER4GpzM4QHcu35Zj2X7PwmB4z2DEYk4W0ZMVORloIHdzoigq1w9VDF1fDEvGqSiYQGIdkT+q8qo0xeIi5pWhps5Hp8WoPNLfMf+IuDrXCLwLf//uLvAgygbF/ipc4f5Flm4dzLOZnuy+u/EAM8wZqPsGMG/0tSyfnn8LfDH4bO235MJNwzmoL6cURCC+ew/kmlBzl5/l36JFWxsro1XuZSJyFem9kw',
  'eBu0Xkp3bY3l13Vs8L02Bi1sTWN56Aa8+xcI7AUVOsrhO1FxfiWK0XBRFItCPye5TXpCm2fWCPOTYfE7rpItbR+ApV9+Y0x4UV+LWK/NCNV95ViA23qaKKRNvqTxXDOSCr8J/DcDLCTxiuuCR81mM7cmdh9ky7QPraFrzSivFxVlwvDDf3fCf/FuUe3bg4EI0+d5nAZxkbwmYswm5Ubhdrv1XM4f8zRVsbK/tq7DkKl6Mcn2U1OwUuCTV335JT47Jg2gUSbY/8GHiHdynpCYUPOb4YneTSsbutcZ6IvU3glcmuC2xT1etCJniTNG3qn277pgtVpG99/ZtU5fIpLGG32kpzUSHjJ0+rQPraFhxujdmWzqFO1bTUtXS2T5dduWIk7dsPSZ5+bIuofD5Yd8hPSlH4UyHW0fgK1H4+o+AbIyj8U8WsmtXU+ed82JaOaXVjsMZ8yIe36aZSE4cq0tseuUJU1jJZHP8CvTAUPvS4UEvXj/Y3cQCEmjofDTV3N4H5D9CMViQFjjJqf9/bHrlCdfpHPNPWoK7GxE6KbCPdBlQeffvzwYutL59VUV3VCrxpwqUBhC+GqL1+Jg+lMkVbcWLV9e8H+SwZTuYF+UHw2ZoLpnM5eNoEEmuLvFGFX3iXkp7QQ12NpbIp2L9h3pGuW7Fh4mRUYYM17sWzlz/x90LZVuwkomVWfAm4iMyew3uHAsNvna7NxWkTSLSt/keIeEaiEOG9pZK3I7ouchyWDYzvEV9A5aJwS9dPC2VXqZFwueH7Y8KM+uExR9yl0+EpPC1zkBc2zKtDxPswi/Bs8Jna26bDnqkSba8/HctvGS+Oe43ahWVxZEaoqecXrlOxEOTHGdy20FXngdja+myXkX0zLQKpGq5sBmo9C1n5TjG6Gkwk2rZgCcgG9k3GB0X0slBT95tzeQ5hf2L28sd3hpu73oXKKsDvRulL1Mj9sbYFk3Id5pJpEYhiW9IUiTsmXvTeutG2yOtFTAf7xN',
  '4lYl4Vauq5vhjShZU64Zaun1rhFEajKKbnABW5N59BrfMuEN0wrwqRN3sX0rXTMZ6cCr0dja9FWK8QBEDlnqHA8KkzwZGymJjV1P3XgT3m1aj+zN8dcoioc9+uu447Jh/25F0g/fHKM4CwVxlx+F6+7ZgKfevqF4nafWnflBtDdYcsL2Y37VLmkFWdsVBzzyoR8ZsS+gjjuL8c6Gh8aa5tybtArGhTmiLPBYNzf5lvO7Jl5131R1sr60iI3Irq5eGW7ov2xw2/6UQEDTV1XQV8mITrv3d5OCpu7b271h2f0+PK+Z+DWP7Du4DeXqwP2651x88JOLIemAsMqnB2qKXnukLbtT8Rbu6+QYW3kYjP5Y0w33Hd27QVJ1ft51UgfXdEDkOY71XGIkW5tKutjZWOjf8UaEAYVjWr6pq61xbarv2RuqbuuwyyAtiTtrDom/uqGz6fq7bzKhCt0MVe1wX+UdfU/ad82pAtsWr7v8B/AC6W95Saf9hMI9jc9XNRsxIfIlH0imhH5O25aDe/AsEs9r6uHcW4PBprq38v8AHAish7wy3dPym0TWUg1NJ5tyLnkAhW4YWI8v1cbCTmdw4ieqr3ZefneW0/C2JtCxaqyA2AKlwQauq6tdA2ldlHXXNXmwoXgfeLVSGoxrRONV1bWoH0tTe8ZXdH5NhMK9aNR1ejRFJdF+gL1vXdn2m9+eTF1pfPUjE/A2pRvlDX3P39QttUZjJ1Td3fA/47fUmt74vWrJ5KW54C6WtvPNmiG0TJxE8GgP5o8Hg8Yskq3CdLtqWfdE0jFdXVNwDHAr8IDXavLrA5ZTwIN3d/QSDtC0zh8r72xpOzbcdTIBb33QACGQcjtrjeKdREfpVpnfkkMe/QdwJ/jbtVb5dV6dfeyxSWYHjgIwLpTho6FnuTtjYmjw2QhpQC0Y2LAyMRKhCRjoxrFjnBs2GxRZMZalf7gpepyDeAKNasKMXIIvsjsmTbHiPuWQjpMhsfF6uyPo',
  'Zkk0kpkL7dzy0mceLLtcP27oxrVs/9j1htcG7R+DA5mDVAWIX3hFd0dhXanjL+qW164l+oORfSHLIzXJGNU2PqHsRIIuK68odsApgJeDmP/aGYds8FnhTVj5c3AkuTcHPnr0Cv8SykVBh1vpJp3UkFoqsxYFcAqEkfAXHS/WsWVSh4nBCTP2ZaZz4JNXd/oOxCUtqEwnM/AzzgVUZheSLVn3+SCqT3pMgpIIcAiMi6TCoE6J275yi8VrBEH860zjJlvJAl98fV0fNIt9Ou9rpMoqMkfYiNsBQFhGfDy7s3Z2IogGP0KPXYxrFxZ8ZEEoTR/IF7zkVkCXAgyouKPqhGfjarqfuv+W5/R2tjqLbGbbbIm0Q5EnBFeARjbgst63wk3+0XC3XLep7o7ai/WtTDTV44IbousgK67/JTZ1IlRdsjm4CTUP1ZuKXHT/an8fe3Rc5BSOWEGA01dc8qRheTTNFWnL7qyH8pXE3yMy9WlR9JxfDl4aVbn895+4rE1jVciNqrR3v8JNxp1F68vxwHHgn7+kCqyJaJQjwSau4+yc8zOGmIFVvfOBc4EUCM95guJcIBHtcenwni2H134wGx6sgGhetJfSDMiPAe4hWbprJZlYzt9ywMxjoaforqtz3EAXCWFfNo4iz9zEeW3B93hAvxWtUSToi2N6Y7pwQkEYiN61JGehZX5Q/ZWakHp7qkkPchR76J3rnwEDNs72dysLhUvMRi79u9tiFdPhRfaOuRNcHBoXbQs33echBi7u7tiOQ8TXIxEmzqfkgFz8DXIvoJP3VNEoiILhv562BdYDi7ybRyYErDrGzLqs4iQTsW1eIM35NF5PI6Y/Tnu9bNn1LSGVUkVhW+FSSj1RigUuD2aEf94qm0XyoY61wBeByJ1tP89OrjBJLYPU9E4lb0sWwzGolIMOU17D+yqbNYiGn/9xBSeQkMAX8Etqe4Psexzs+m4mEaXRe5HCF5TyAMe7avVKDc3r/2uHzlHy',
  'waQi2bnwP1XLp31X4kXT3jBNLft/0VQB0AIlnvKFvr5aQoJSuQWEf9x0iRukHgz4515oWbu08NBYaP9ojKcWK2HqbRdZHjBL6Q4vJzqJwYbu4+NfRs7UsRPpe8mBwSN/E7puoGXgoMBeJfB1Jucovw9uiGeSmnAzBBINZl3/kNpTtbw0QkpUBcx30623oLye61Da9SlS8nuyawza00b6ldsfkpAFm6dTBkgu8lxXxL4bLY+kZPX7VJ96zGYPluquj4BnlfuLmrE0Au3DQcbur+f8DXk9srJ8d6a76aSfulyIFLt/Yqcr1HkSrcSs9cNeOHWCL7JpFqshYIoilTHAy7kvPlznyjrTjG6LdJsW9k0YsnJnmRpk39qHwqRZUBde3/ZGJD34n17yZFZlxF7w42d006fBaS2k8p/DNphcJFuV5ZK0b27JFvAKnTTau+1+v+cQKRMb8ANTZ7gWiKqBLC8EHNW0ou52CspuHDjCx9J6GtrrknqbdB6OGuO0BSRXh/fW97va+EMrphXpUVuTrVdSMmaW+QSLsgl6S6zcXekHArmrmM5Lhc41HkJK/wpXv/cXo75h/EPgdDWxc6dCpzhVST+3+X2h5I3x2Rw0A/m+q6MZpirA+yGqtoyjyLopLy3rHE3IoPeGSR/WtweVfKszV1zV1tqZLWCLwydmLkQj82lDKizjdJ/UyiYlalujbm7WFOZd/O+o4petv2JbeEguWayxa3ki+hzEp+VX4fXN6zyet+VXNHyovCCb0dja9Na4Tyn6mr4M50Lx1V8w0PG76Q62BrxUaoZfNzCq0eRVakurBXIGLllL2fasplSp9o0oxKmuLzYmVX+4KXiZI6p4mSdh4xq6VzK5DSn01UL/K6P9pefzrQkOq6ayWtT1G4YuhW4LkUl+c4Gv9wujpKHYUbPC43poq7sK8HMbp3HKbClPx2BJNUCCJS9OkNxuKo+QQpvZJ1R2h7jb/zI4pHOT07eufC1K4iIh9KfY',
  '1n6x7pSptSW5ZuHRQldWIi5ZLt9yxMuXc1E5jV3P1HSL0y61htTvb5PoGoRMZ8mO4IoycWTX5kVdPGMyoaYm3HHYrgtcJxu998JsZRLyFVacXQ+ckuRDfMOxhNsSmYYK3vdHXGeIUvOii0Z/gDvuopYVT5QaprVpKnqjYwkqIAXjbm89TLYj4wpOiBxEd81WLBuB8DalJdthlkRKpd1vMQ8GTKplQuTLaapG7g/UCVx32+YwWMpGlIOdRT4bJsAxuUCk6cW0gRCNsgr0v2/Q1AzFYcy5ihhGqKSbZPFJM814eWRnLOFzbMq1Ml9dxA+FsmIVNFUEFTRrBXOLrvlfVvHPeZIqLyfo9qh2ql5td+bQBQUa9e5CV9Va7nplmpEzy7+1kgabAQhWC0hlMmfp54a7k6LsCCiAxMxRDVeKpkOCXh3lAxHHg7HjlNBO7IeLla8XyY1cq44Vx0fcNrgXmp75AHM00vrdbxTEqjeApyRiBK6hTjqq+f+JEBEJGXji+nU+pB9gwGkgpESkQgIsZzd9ViMg6D5BhnY5oiK3fedcJo3kOM9d7hBb0/UxtGVtS2pCwgvK63bcGCTOstJUzF8B2kCFsqqpMOWRkAix4xruAUl2NHdi8nuRprCQhkd1vjPNDXeBT5d3igM+ODZDVNnU8KbPMoUh0IDJ0DCZd6Bc+TnCL6m0xtABDFM0q9YM7Npt5SoXbp1qdBH0t6UVg08aNED4I5fELBXEymk0VjTDnpLRYM9r145I9XZUO2URdV8I7movoegD4GVjLqVZ2i5NDAUFZpI6zjPbFX4d0z3f1E1aSaD750onevGbljwvmA1N64/o0gWWCGot6x1dUYFd7tVUZE1mdbv6Dp9ixevbutcZ6OCMWDJw5Y9ffd2dgQ7u/6A0LKewWOip3UuDibuksFRVOHvR12jhv7Y0IgwkFjP7Qw5eU+I5JMIAfqmkUpPX0LTezEBad7+DwBWJV41jGFrZh0AhGD/QR4p6',
  '3T9PFoUzewCleU33rWrzbN/Ke0GcL+hlQ5RkTG7aiPCmT2+DIy9YdYbLJxnvQf1H9Qks+LAzFJd1PH8HBd0xNZ7xH1VwQeIl1CSuGD4J1jT5C0u+febajngoEIy3OZ56/YOLhlSxSSjnDQRHT/vYwMsSY54019w8g4SSdCWmm8InAUmmVeFwW5dyqVz33zY33AE1OpA0DVfWgq9wtyf5oiB0SrFiQ9ezKDSOpkKhNyapqRc+jjJs+Kpty99UtoWed2YPLhKMthU607H4wEU/BOFCTcN+WGJPVutk/UtTWdU6mgdlP3I8BOrzLGOJ4vi5JHJLlAVOaO/dns7nshzIRVG6OSOq5VRkbopOVQNdYrqHXBCLhOujhJg8GB3uzCII3FJu/aM+CpqaZnGPHf8lwmVtUZLRCjyQWijI/pZpyhyfMNRXMyTxCS7B6r1+5w4VBhqXcJ+YusenpKHgYAaqbWgwgkX8PPvJ770xRZOLA+4rVgUdL0a7wnxaXxAhHHJlvz94z04JdkY12RNMOYAjDi6n26VxnBe+XHN0anNDxCyE3+EjHpdvaJW3lLTtoqQkYm6snOyMweu0BhCGgSgUhOepBkY121xSeQ2qGhM/DwmgUQ0ZwIJNznbCPdSpYHqppxOrxkBDd1Po5HSBwA0XS9aomjSRdMTG9d495FKyN7kvQgmjp0aCbIaiwTHyzhmGI7nCOaNkqhHYpXTX3+AciqziGBp7KvwdmaEztWY9E08xD09Jm83CsiSV82OiB7N7SNVpvJjlvCUblyNxCViV25U7tnMKOYUPlGIc25cH08l3kLVcj6IY+7bqooKZkjk343E6nrCzZmGmK1lEh7tNwMxOPJPHer9pwcyUl4ymFX1zLBe1KMM8kprFA837YgLKQMJTqCycrvKSWWbCPGuLOHe6bQ+4zHqJvW6VLjbvqgEiWLJj2nr8T3DoHNQQ9v6SOJ+2/cNTlZjp2zsmcbMC4ItlotGoHUGDkVj2xYAK',
  'r2Lzlt1GQZFEPYkcv01LWDgccgjWOqyH4nkKqq+F43FCOrsQJJ3NvdXC7HjjukIkJO0gDkArXyunRlHExuBZI6wog3Nsv7UiCrOocA71U1YdIhohmDOkk3S3WgYl8PAqAwKdrhRJ+UqdmhP53w0YK+tgWHJy083QjpBDJUOyhTW5qdhMnyQZecCmQEz7heKIdNNWVD8WKT9sa2ZnhfDzLy56TlPkFekSsz6pb1PAHj1+8tpuBvJm3F8UzVBSj66MibNnftSvKuPe19RqcYrywJKVwuxhLQwKnpypQiik3q0RsfDO2NVjPqzZssCslJObbmJxM+yTQBTM7pq1rQqOC55Gw8IoFkjdisBGKyHZp5oJJmiAVYLZ45Y24xyXqQodkrHtl7XiYxxNKkcbAOHOhofGmSz7PCMe5NjN0gE5Zlko43H1gcz94DwMrUvW8nEu8byvJBz67n8UKGAqnPqI+WUe9etliIrY28IZOhu6pO7kFUd4wNyGFG/pc0jtUwNmdvjtqmJ/4lyIYxHx3evy6SKmL6tCCS/s0oNvcCGTkNmIVfV7Zzl9SEz3psB+C9xyOcWOwbhrvWHT9HDa1WHN+nVjVZDyKyY+yPiR5EJHmgN5vbJT4r9rvjfkZ9ZRrNF5LkkP4kHMm5QEbI/OCV2CkF9EtZrXhEOkkQilU31Oej7VwRcONXArNd4/gPm+vIpO0NmdBZjPQgmnxnV2zaJdBMCA/0bEB1X4Yp77CaeUXXLKpQJd1ChA0FB3Li2pEEz/MYyXBTLEtOFZvcJ2kcfnrbQrHzrhNmI/wnyD9mL3/c97+RY+2kqP3KeC8HAzAsblLXX0FO3NHaGMrU4FQkNrnM98d88opoe0NjrurPhNihe44lfRiif8mSbXvyZELGD7sYN2fuLuPq1ZRJfvZikZRJZgpNwOw5N7HYohl5PCgye9KHkkQgs/qf+CeQ7EEI1Fal81PKEDdwA+M2JrUw4S6NHpe2jEzFqTAtmT/s1u',
  'Ylv4qKpv+eqilTMBSckQj4ouPmuGlR7CSBiOhkgcgqXCRFwkljz8yk0XSEz3psByI37TUI3lmgOEzpey7Nn0AEybgHCT8TzirUxhZsnwAAFNNJREFUTzqM0bSJVcXPv1cB6F1b/xoStrnWtZPyNHohhkkrXkbtuGX9sQ9m8vXwPJwJcFxz7UhObxRe1reoPmno+TyT9heu+HizZolFMxVI1G+6hUzRuOPne77kxdaXp8i0VTjEcM7IXx+oO6vH83zLJKwcMf4D3VHb9MS4sLljMkxpKn+jYxPhOHNH7YrNT+mYjUNVKUSevLQCETH560E04zlIXuYfAHsk7ud7ilNZXVQrWYmRh5yd+EFvz/h+YZxAFCZFi9krEGuclBMcx7jp4kVljFhzDfu8iJun0zdLNy4OIOrDWznF8ndurMhMIJI/gYwcP02bfViMjaQrM51EFzW+BjgcYZiKeMYCkQn+hsJkt5u9AglT/RCpos2pvD3TxtMRXtHZhTD6pQJqnI/kuo1U7O575qUo6YPjWbsjbZksEWMyE0ieE6BKshBNE8uIFJXToqi7KvGn3B1eujWt/WMZSZYzNmkUYiYfq943xGra1I8m9ztSOHnXXcflPFxPPK5XMpKeV1U/lMslZS8CccffL1pNXjbmAFybIk1dKkTzMkHfVz/pXwYqvp4BXY2JtS1YuLstsqy3vaFld0fjKSNZzHJGwk1JzkqY5ZkYKCmxWjuf8eeA4gOuTjpWPXH1KGn2HUAcxz0nxbWsmbOyZ5uq/O/oj7VVdloSuKjx+Yu2bj7cywEQRyalh/C0RTMrnzGavgcB9Xyx9HbMP6i3o+GrsZMiz6iYR42wTtC1Ru2fYvGK53vbIjfn6kUba1+wGDgCYXdoIOqZ0iEprkwcLj44MtQcxziBqCFlmjCU9+TDudA6zuchMXxQ4RPaemTeUySIeP+iR3DDj/XkZecawIhM+mV4IUJG5TNH/QxRUj7cve31ywVnq6ieL8',
  'LNauT1Ru0R4pjDDOYUhGsFPcNx3K5oW8Qz9bUvazHnAWB1XTbxysRMSJaTImrmOIG48aqNpApJI8wfUW1Omb388Z2CfGnkx8NjNXUfzHUbE1GVI30U2+07g2wWxN14RkMmIb8CsWL8zHEO1o1HT/I+iLXXv0OQNlTus5VmXqip+4q65V2/DbZseSa0rHN7sLnzwXBT9+dDg7H5wA0IN/S2N1yVra07WhtDIiSGV0Y808qlQpVxsYeNaFuycuMEMmflI7sUTR0Y2ZgLsjEmHcHA0DdhZKNS9UrtWFSbj3b2oYemLZHnB9KIZlR/vu0Rm34VC2Bgd2hcSKhY24KFitykwi2hpq63zTqzM6XQZNXTA+Hm7o8Cnxb0c7G2xqw2oatr9G1ACIiGB6L3ZHr/iGfy2PNO/xrJRDyJSTvY4pVaWFk5MQNPLpClWwfBfIjEsu/cqA5cmus2xjdI2gjz+X5jhx2bYf35nYOI+BOIyvCh4382XwHZFnaGL/Sb2DTU1P1FVO5Vsddn40YvquclGtf2bIZXI6GMxi4ItaWyPYlA0iS9j1ckTXo/VcLNnb8S5GYAQa/M676Ipu9ByPcbe+nWQUZW8PyQ7x5EfQoEsy99xUjCzzcBn+lzK98abW9Y83zbgjBArL3h8t72yPUA0faGC6PtDWui7Q1retsin+Dn8yoNXAlE+mrqPZMFTWTEufV1IzZPPKXqC2vthJTbNmXO+0kCCa3oeTxFSEYABC5NNg7NBW6lXAa6Awi5xnwxH22M7L4e6KNonifFQJJEp6kQtfntQXx+XxfZ+3IxyHKgP7Snt0Otvhb03CqR03Xj4oCilwkkUsmpfYOqVovyWxHOicUDVwRbuh4GtqiyPDNL7cUJc3ku/Gww4+EVgKjuHdoJbAs3b0kZNTOpk6AYvHqRudHeas88ftky68zOF0XNZQCivHvEES2nxBYuPIg0GZxGi+a67cn4X8nK9yqWVZ/fV3VvD6KJQORb9g5zRP',
  '4EckY0tv1kRLrH3mbQx0ItXT8CNih705w9oohvN6add50wW5BzARS9JRvftETYWzlt39eRm72Ghim8aE3KLgdAkMvz5YEbaun6EaLtgBEj3831BhOVw74yXGnel1UBVd+9gs37ooFfgci+fz+RWTA2Iaj+TpQlqFkC+rtxt8Gl0Y7IY8B5Cv878uFOgclnMlLgOIMXjgbZEMwP/N43ltrB+BvYF6jcuuLe7FU+6UMeaup8FK+AYsL82KLGs7Ix0BdO/AMjQ62G2HDllbmsWlT8zD+QDN7uWSOZDLFMXodYrt/vK/t6EFSfZ0yqDLG6G1ER7CqxTAxa/kOFW0D/XfdQ92gS0kPBxw4+oB2LagX9WKIq/hxu7soqVpnAqjHfZd3s5i2eYWBT9gKqabbv1a7O10H+8NKtzyPyXwCIXpnLU4eq+Jl/IH6HHFNAMpiDBBzNqz3G9ft99yVXGkn1vaD3zvoDAayhAmQjyByLfXLsXaK8GH6m9utgwn0n1idysQunkjxd+CT6bP8HIdF7qcj/+fxa4y3vWFQLutfxVqx8K909KQXioDePntlIQWO0piFdPu+sCTd13w7cBlSCXp+rehXra49F08WszYkt/odNgxrIaw9ixd/3FWSvp4MJOB2AGEfOVUd+7CC/ULU3ipoLrVO5Q4SRN758B/ReuXDTsBV5hzW82H9Sw5uBucbxXDUFQDceXa3CxxN10RePV2a1ehXTgeXsXd6VnmBzd9qckymDNgdbtjwT7YisBd6Wqoyofk5bj/xJLlKTJcM1gQ87Nn4ykLONQ8FUJYnVnYTkYSlzi/bi03tnVvDA/E7SrRs3TnpbZEzC19Cyzu2x9sgPVPQqGao4PpgIIQTsjZLyQ4BQS/fe7MCzmjr/rK1H1sRqwn8C7gsu70kb2THaW/NfMnJ2Q0X+L+tUFGLfxUi+KMV+2c++jWdUc6z9NmJSCgQ4oq8q9J/AdZnY6ZfZyx/fqRsXR3j+fl8bUL4Qqv',
  'zoQzHJXf9zitOq2LSpEAziypL782pPVVU87sbTnwDQiYEuHPNpXPsWKobvemHDvLccuHSrZ0+naxZVxGr6b0J5GdakdYDdte74OWLjV4z8aK2V/0lrZBIGOhpfGlc7mpj0yfAex3MhahRPgYSat9wf64hsBlIGOFCRT0XvXHhLeN/bI6fk+sFQ1Rpf7+xkUfdyTLi581fAr/Ldjh+sVsXx43qm4wUSWta5va+9cYVVe09lvOKB3o76D9Q19STNO9LbMb8+pv03orxKRVfVrehMm2/RqPspRhNrKm2zWjqzCsPkql7E6PK+co3feMueAhFBYx18RRWvpbA5WjF8LXCeT1sLiohWkywt4wSMyb9AigmVQRcfZ8iQyaGSgs2dD+7uiLzKKD8Rld9H2yO/UWE98FdQV9QcLeibVDkT4Z9WWTKrqeeP6ZrqXV8/X1zde5BOVb+a4ddK3NfaWBnDPT8xnJW/hyqGbkp70whp9zKCz9TepvBPrzKivCfaUb/Yb6OFJDEH8YGVaZiDFA9jI5qnIelxhFlN3X8NPdR9Mso7RYiJcrUod4lKO+j1CmFELg4NmIZZzd1pxQEgLt9mZM9C0QfrVvRklScyWm3fPboChsgVI24+vvA12oi1Ry5V+FqaYptDz9aelK/IG7mif8O8I9UG0u6F2KHKp/I1bCxGtBWnv7Y+be5IFccNLet8xE+d0Q3zDjYDFYHax7ufy/ToQKyj/p2qsnerQZEVdc1dSV3SPe1txYlVR7pJnD9/INTUfZpfp0rwKZAdrY2hmmq7DdLuIXw63Nz9Bb+NlymTjN6O+QeJOptJbCQiwp+Cy7tPzeTBHiXWHnm3JlbT1Ip59aymzj+nvWkMvtxFDlnVGRNRP86Dq/vaFpREqPwyxYuo8y1GxAGgaq7KRhzaiqPIyMEsuS1TcYBPgQAEQ3tuAJ5MUyxgxdycL2/fMjOf3vaGFmBsFJ17R1b7MiZW3fAB0Hpgl1H349nU4Vsgsm',
  'TbHhVZ7aNoQ1+s5upsjCmzf9N3R+QwwX5nzEdqxXw6m7pe2DCvDnQ1gKpcFmzZklWMs4w8csMDXT9kQq7BZKhyWWxtpBDhRMuUKLoaYyu4Ze9qU4K7shkWAVS4lf8PmAv6q3BzV1a+W5ChQGQVrlgu8VHUUcPtA+sjR2VpV5n9jNhJkSuBsacLB1yT3bAo2t7QKKoXA9GAI+dnM38ZJeMzHaEV3fcBrT6KHhC3/FjXLPKx+1Rmf2ZkD231hI+/MHv5E2nzlkxEW3FE9PtAJfDJmmXdnnt46cjq0JMTGL4MPyfulNfEDuu/Nps2yuwfDKyPHIVKK+O9OraEAsNZ7ZpHayIfU+VVwL2hpu5vT9W+rARSu3Tr04L4nYhf2tvWkJdAD2VKG+1YVBt3uYsxh64AxPKRTHa7R9ndETlWlKuBfxu1501laDVK1sdmg+FDvq7og37KitEbo+2NBc+LXqZ40FacmPbfAkzMdHz7yDA+Y4yVbwLVKnp+tqtWk+rM9kZZcn9crHMeyVO3jUepAPuz6Lrjiip8fpnCEauK/A8w8dj286KOn0Wg5Bj9u6p+qq6pJ3VstwyZcqzdWFv9ZSric7wofxdHTgst69w+1XbLlC697Q1XCTrJJUnVtNS1dGYeiDqPTDkySfDhnq8Bv0tbEAB9ubr23tEzzGX2P3rbGs4X9POTr+iaYhMH5EAgshrrWOedgN9cGseJI/ftWnf8nKm2Xaa0iLZFzhHR7zBx5CL8bY9qVnse+SYnsa1qV2x+StH34e+wNwgnONbtSATxKrM/EGtrOBfhR0wM2icMGzXvSJaboxjIWfC3uuaedSJ8xf8delpwcPjuYsycWia3xNoazlXRH5AkoqWgnww2d/paDS0EOY2OGAwd+imUSXnePHhtRXXVL3s75h+UvmiZUiTaFrkolTgUfhRq6slZSKd8kFOByJL74+rqSiCTg/WLRAO/7d8wz09SmzIlgioSbWtYjXADyWMhPxyW2k',
  'Kk/86InKdUg9EQ9foHoC4DQ7apNcvCPiJdlCludOPiQF/suTWqpPKgeM6xzsm1KzbnLQ99rsiLQABibY1nqtgO0sXeGm/NbkFXhZp6fpEvu8rkl13rjp/juO5tiL45RZEhNfKGuuVdmQzFC0ZeIrQDhFo670bk4oxuUmapyvpcJHksM/1E2xsaHRv/s4c4LMp7S0UckMceZJRUu6Y+uCEUGP5YNk5rZaaf3o7IClF+CIRTFhK5KNzUdeP0WTV18i4QgFhb5NrR4MOZoPAX19W3zVnZsy0PZpXJAdraWNlXYz+nysfxeJ4U+VRdc1desoblk2kRiCoS62i4ETSbLLk7Vc15xeiGsL8z4l7+YwHPSDYqcl1dU9fl02VXLsnbHGQsImjooa6LQNdkcfscEbs22tHwzfynhy7jl962hvON8lA6caDcGF7eVZRuJH6Ylh5kFFUk2hG5TuCj2dwv8A9Vzgu3dP8m17aV8UffHZHDbID/RViZvrR+I9TUc2kuDi4VimkVyCixjoYvqmq2qdVcEa4LhgY+I0u2pT+LUiYn6GpMbFHkIpQvkn5/S1X0qrqmnmumw7Z8UhCBAMQ66j+mKl8h22Ge8DcD/xVs6t6QW8vKTCTWtmChinMD6GnpS2MR+XCprValomACAYi2158NcgspIob7ZG3A4dKpRq8oM5nonQsPIRD/HOj78Zc6e1DQ94Wae27Lt23TRUEFAtDbUX+qqLQx4eB+hvSjXD9UMfyVdBmOyqRHN8yrig5XXCLCp/DvLvScWj0r2xQFxUrBBQKJlAQ2XnGHwilTrOpFhWvDe6LfyFfexJmMrllU0Xd4/ztU5TOgL8/gzkcDjrTMxF68KAQCiUym0VjNjaK8NwfVPQn6hVAgfnN5Jz492tpYGavW94FeBbw0w9tvD0nt+dK0qT8fthWaohHIKNH2hgtBv8bU5iWjbFf4enzP4I0HrPr77hzUN6PovbP+QCr4oFg+jEimxw0GQD4abu',
  '7KZm+rZCg6gQBE1zY24NifoByfoyp7RVkTt86a2Ss3/y1HdZYs0bWNDRi9BPRcskuxvRnknHBzV2eubSs2ilIgkBhyxWI116BcTO52/C3wS8R8J/RMdVuxp4vLJS9smFdX4VasEpXzfC7XJkNRbggNRj++v8zxilYgo/R2NL5WrP0+wvwcV/2cwo8dzG3FfCZ6KmhrY2VfrbvEWjlXEkHapuKqs0WNfLCUXNVzQdELBEBbj6zpqwpfrYZLE1Eac85W4HYrpr1uU+dfMk04WUzsaG0M1dRwJqorQZcCs6dUoTCMcm0oPPD5/dFzoSQEMkp03XER1P0GSj6T8zwnsE6Fe8U694daNj+Xx7amjG5cHOiNPvdKQc4Q9AzgNJicyzxLfilWPxpa0fN4juorOUpKIKNEOxrfCvYalGOmoblulF+rkT8b624K1h3WJUvuj09Du0npuyNymFuhi0TNK8XoK1V5HRmc/ffJFhX9eC5j3JYqJSkQSGxqxQ7vvwDlvxmTEXUa6JfEKk6XRXsQtoiwNT5c9fSclY/sykUDumZRxe7DYkcGRI5V5FhU5ytyrMBC4IhctJGC5xH5fOiZmm/vTwsYXpSsQEbZ0doYqq5yLxbh0gn57QpBDHgS5QWM7lRkpyg7BQYBLOwEEKRW0CpVQohUgIaBw4BDRv6b7jhh20X1q7Hqyhvnvvmxvmluu6gpeYGMoh2LamMMfADVy4GXFNqeEuEpkK+E9vR+b39Zts2UGSOQUXTNoorY3IEVGL0gz5P5UmaTCN8IPlN7W3ko5c2ME8hY+tobT1bcixR5K17RNvYPYii3CfZboZYtjxXamFJhRgtkFN14dHWst7ZJRN+jcCaZBLMrbVyEjQK39A+YOw9Z1Zk+8WqZcewXAhlLf8f8I1xrVmKkBTg9TxuPhWQI4TfAWpzh1vDSrc8X2qBSZr8TyFh23nXC7IrAnqWqshxYAswttE3ZoTtU5OeCWTfkDP6ifGgsd+zXAp',
  'lIYqfeno7qYlRPy8IFfFoQ+IeF36H6W3ECvwsv39xdaJtmKmWBeBDdMO9gsYGTrHKiICehRIBjyM1ZFT8MIjyhyqMIjxn0UWzg0WJ3f5lJlAWSBYl5jHOMir5cRI5E9WAwByEclvi71KK2EpEgiQWBMIl02WP2GmQn6G7geeAFlBcxbBcr2yzuPxz4R23zlmdLOaZUmTJlypQpU6ZMmTJJ+P/E4awK1MXIpwAAAABJRU5ErkJggg==',
].join('');

const LOGO_SIZE = 28;
const LOGO_START_Y = 15;
const LOGO_MARGIN_RIGHT = 10;
const FONT_SIZE_HEADER = 20;
const FONT_SIZE_FOOTER = 10;
const PADDING_PAGE = 40;

const tableCommonProps: UserOptions = {
  tableWidth: 'wrap',
  theme: 'grid',
  headStyles: {
    fillColor: '#bf172b',
    textColor: 'white',
    valign: 'middle',
    halign: 'center',
  },
  bodyStyles: {
    textColor: 'black',
  },
  footStyles: {
    textColor: 'black',
    fillColor: undefined,
    valign: 'middle',
    lineWidth: { top: 1.5, bottom: 0.5, left: 0.5, right: 0.5 },
  },
  margin: {
    top: LOGO_SIZE + LOGO_START_Y + 10,
  },
};

interface TablesExportPdfConfig {
  pageHeight?: number;
}

export class TablesExportPdf extends TablesExportBase<'PDF'> {
  #doc: JsPDF;

  #config: Required<TablesExportPdfConfig>;

  constructor(config?: TablesExportPdfConfig) {
    super('PDF');
    this.#doc = new JsPDF({ unit: 'pt', orientation: 'landscape' });
    this.#doc.deletePage(1);
    this.#config = { pageHeight: 595.4, ...config };
  }

  private didDrawPage =
    (title: string): PageHook =>
    data => {
      this.#doc.setFontSize(FONT_SIZE_HEADER);
      this.#doc.setTextColor(40);

      this.#doc.addImage(
        logoBase64,
        'PNG',
        data.settings.margin.left,
        LOGO_START_Y,
        LOGO_SIZE,
        LOGO_SIZE
      );

      const { h: textHeight } = this.#doc.getTextDimensions('M');

      this.#doc.text(
        title,
        data.settings.margin.left + LOGO_SIZE + LOGO_MARGIN_RIGHT,
        LOGO_START_Y + textHeight
      );

      this.#doc.setFontSize(FONT_SIZE_FOOTER);

      const { pageSize } = this.#doc.internal;

      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      this.#doc.text(
        `Página ${this.#doc.internal.pages.length - 1} de {total_pages_count_string}`,
        data.settings.margin.left,
        pageHeight - 10
      );
    };

  private static willDrawCell =
    (columnStyles: Record<string, Styles>): CellHook =>
    ({ section, cell, column }) => {
      if (section === 'foot') cell.styles.halign = columnStyles[column.index].halign;
    };

  export(filename: string): void {
    const columnPaddingWidth = 35;
    this.tables.forEach(({ title, columnConfig, headers, rows, totals }) => {
      const width = columnConfig.reduce((a, b) => a + b.width + columnPaddingWidth, 0);
      const columnStyles = columnConfig.reduce(
        (prev, curr, index) => ({
          ...prev,
          [index]: { cellWidth: curr.width + columnPaddingWidth, halign: curr.align },
        }),
        {} as Record<string, Styles>
      );
      this.#doc.addPage([
        Math.max(width + PADDING_PAGE * 2, this.#config.pageHeight),
        this.#config.pageHeight,
      ]);

      autoTable(this.#doc, {
        head: [headers],
        body: rows,
        foot: totals?.length ? [totals] : undefined,
        showFoot: 'lastPage',
        didDrawPage: d => this.didDrawPage(title)(d),
        willDrawCell: d => TablesExportPdf.willDrawCell(columnStyles)(d),
        ...tableCommonProps,
        columnStyles,
      });
    });

    this.#doc.putTotalPages('{total_pages_count_string}');

    this.#doc.save(`${filename}.pdf`);
  }
}
