import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { startOfMonth, subMonths } from 'date-fns';

import {
  reportFilterStartBusinessDateSelector,
  reportFilterEndBusinessDateSelector,
  changeFilterStartBusinessDate,
  changeFilterEndBusinessDate,
  filterParametersDataSelector,
} from '../../../state';
import { constants, isEqualNullableDate } from '../../../helpers';
import DateRangeFlexSales, {
  DateRangeFlexSalesProps,
  DateRangeFlexSalesValue,
} from '../../DateRangeFlexSales';

type BusinessDateRangeFilterProps = Pick<DateRangeFlexSalesProps<Date>, 'spacing'>;

const BusinessDateRangeFilter = (props: BusinessDateRangeFilterProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const startDate = useSelector(reportFilterStartBusinessDateSelector);
  const endDate = useSelector(reportFilterEndBusinessDateSelector);
  const filterParameters = useSelector(filterParametersDataSelector);
  const minDate = startOfMonth(subMonths(new Date(), filterParameters?.filtersLimitMonths || 3));

  const dateValue: DateRangeFlexSalesValue<Date> = useMemo(
    () => [startDate.raw, endDate.raw] as DateRangeFlexSalesValue<Date>,
    [startDate.raw, endDate.raw]
  );

  const handleChange = useCallback(
    ([newStartDate, newEndDate]: DateRangeFlexSalesValue<Date>) => {
      if (!isEqualNullableDate(newStartDate, startDate.raw as Date)) {
        dispatch(changeFilterStartBusinessDate(newStartDate));
      } else if (!isEqualNullableDate(newEndDate, endDate.raw as Date))
        dispatch(changeFilterEndBusinessDate(newEndDate));
    },
    [dispatch, endDate, startDate]
  );

  return (
    <DateRangeFlexSales
      {...props}
      maxDate={constants.reportFilterRangeMaxDate}
      minDate={minDate}
      startText={t('toolbar.labels.fromDateBusiness')}
      endText={t('toolbar.labels.toDateBusiness')}
      value={dateValue}
      onChange={handleChange}
    />
  );
};

export default BusinessDateRangeFilter;
