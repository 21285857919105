import { Link, Typography } from '@mui/material';

const year = new Date().getFullYear();

interface CopyrightProps {
  yearSecondLine?: boolean;
}

const Copyright = ({ yearSecondLine }: CopyrightProps) => (
  <Typography variant="body2" color="textSecondary" align="center">
    <span>Copyright © </span>
    <Link color="inherit" href="https://www.arcosdorados.com/">
      Arcos Dorados
    </Link>
    {yearSecondLine && <br />}
    <span> {year}.</span>
  </Typography>
);

export default Copyright;
