import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { numberFormatter } from '../../../helpers';
import { useMuiPickersLocale } from '../../../hooks';
import {
  reportSalesJournalFcsSelector,
  reportSalesJournalGcsSelector,
  reportSalesJournalNcsSelector,
  reportSalesJournalThirdPartySelector,
} from '../../../state';
import {
  SalesJournalFcs,
  SalesJournalGcs,
  SalesJournalNcs,
  SalesJournalThirdParty,
} from '../../../types';

const TotalsContainer = styled.div`
  height: 40%;
  background-color: ${props => props.theme.palette.background.paper};
  overflow: auto;
  border-top: 1px solid rgba(81, 81, 81, 1);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & p {
    font-weight: bold;
  }
`;

const calculateTotals = (
  data: SalesJournalGcs[] | SalesJournalNcs[] | SalesJournalFcs[] | SalesJournalThirdParty[]
) => {
  const record: Record<string, number> = { grossSale: 0, iva: 0, netSale: 0 };

  data.forEach(i => {
    record.grossSale += i.grossSale;
    record.iva += i.iva;
    record.netSale += i.netSale;
  });

  return record;
};

const formatAmount = (value: number, locale: Locale) =>
  numberFormatter({
    value,
    defaultValue: '',
    maximumFractionDigits: 2,
    factor: 1,
    locale,
  });

interface TotalsProps {
  company: string;
  gcs: SalesJournalGcs[];
  ncs: SalesJournalNcs[];
  fcs: SalesJournalFcs[];
  thirdparty: SalesJournalThirdParty[];
}

const Totals = ({ company, gcs, ncs, fcs, thirdparty }: TotalsProps) => {
  const locale = useMuiPickersLocale();
  const { t } = useTranslation();

  const gcsTotals = calculateTotals(gcs);
  const ncsTotals = calculateTotals(ncs);
  const fcsTotals = calculateTotals(fcs);
  const thirdPartyTotals = calculateTotals(thirdparty);

  const iva = formatAmount(
    gcsTotals.iva + fcsTotals.iva - ncsTotals.iva - thirdPartyTotals.iva,
    locale
  );

  const netSale = formatAmount(
    gcsTotals.netSale + fcsTotals.netSale - ncsTotals.netSale - thirdPartyTotals.netSale,
    locale
  );

  const grossSale = formatAmount(
    gcsTotals.grossSale + fcsTotals.grossSale - ncsTotals.grossSale - thirdPartyTotals.grossSale,
    locale
  );

  return (
    <>
      <div>{t('sales-journal.totals')}</div>
      <div>{company}</div>
      <p>{iva}</p>
      <p>{netSale}</p>
      <p>{grossSale}</p>
    </>
  );
};

const SalesJournalTotals = () => {
  const gcsResource = useSelector(reportSalesJournalGcsSelector);
  const ncsResource = useSelector(reportSalesJournalNcsSelector);
  const fcsResource = useSelector(reportSalesJournalFcsSelector);
  const thirdpartyResource = useSelector(reportSalesJournalThirdPartySelector);

  const loading =
    gcsResource.loading || ncsResource.loading || fcsResource.loading || thirdpartyResource.loading;

  return (
    <TotalsContainer>
      {loading && <CircularProgress size={15} />}
      {!loading && (
        <Totals
          company="A.DORADOS S.A."
          gcs={gcsResource.data}
          ncs={ncsResource.data}
          fcs={fcsResource.data}
          thirdparty={thirdpartyResource.data}
        />
      )}
    </TotalsContainer>
  );
};

export default SalesJournalTotals;
