import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import { reportFilterCountryCodeSelector, oakBrookOakBrookSelector } from '../../state';
import handlerFactory, { OakBrookDataGridContext } from './handlerFactory';
import { OakBrook } from '../../types';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<OakBrook, OakBrookDataGridContext>();

  const { t } = useTranslation();
  const title = t('oak-brook.title');
  const country = useSelector(reportFilterCountryCodeSelector);
  const oakBrookResource = useSelector(oakBrookOakBrookSelector);

  const { data, loading } = oakBrookResource;

  const gridContext = useMemo(
    () => ({
      t,
      country,
    }),
    [t, country]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);

  return null;
};

const OakBrookDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default OakBrookDataGrid;
