import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getRoutePathDefault } from '../helpers';
import { isAuthenticatedSelector, userPermissionsDataSelector } from '../state';

const NavigateToDefaultRoute = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userPermissions = useSelector(userPermissionsDataSelector).map(i => i.permission);
  const path = getRoutePathDefault(userPermissions);
  return isAuthenticated && path ? <Navigate to={path} /> : null;
};

export default NavigateToDefaultRoute;
