import { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { useFlexSalesForm } from './FlexSalesForm';

interface ConditionProps<TValue> {
  when: string;
  is: TValue | TValue[];
  children: ReactElement | ReactElement[];
}

const isValue = <TValue,>(value: TValue, is: ConditionProps<TValue>['is']) => {
  if (Array.isArray(is)) return is.includes(value);
  return value === is;
};

const Condition = <TValue,>({ when, is, children }: ConditionProps<TValue>): ReactElement => {
  const { control } = useFlexSalesForm();
  const value = useWatch({ control, name: when });
  return (isValue(value, is) ? children : null) as ReactElement;
};

export default Condition;
