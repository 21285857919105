import styled from '@emotion/styled';
import { GcsDataGrid, GcsSearch, GcsSwitch } from '../components';
import FiltersLayoutHandler from '../components/FiltersLayoutHandler';
import { reportGcsLoadingSelector } from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .MuiToolbar-root {
    margin-top: ${props => props.theme.spacing(3)};
  }
`;

const Gcs = () => (
  <Container>
    <GcsSwitch checked={false} />
    <FiltersLayoutHandler selector={reportGcsLoadingSelector}>
      <GcsSearch />
    </FiltersLayoutHandler>
    <GcsDataGrid />
  </Container>
);

export default Gcs;
