import { all } from 'redux-saga/effects';
import {
  preferencesSagas,
  userSagas,
  parametersSagas,
  reportSagas,
  errorSagas,
  dashboardSagas,
  reprocessSagas,
  companyCodesSagas,
  accountingParametersSagas,
  accountingEntriesSagas,
  monthClosingSagas,
  oakBrookSagas,
  exportsSagas,
} from '../../state';

export default function* rootSagas() {
  yield all([
    ...preferencesSagas,
    ...userSagas,
    ...parametersSagas,
    ...reportSagas,
    ...errorSagas,
    ...dashboardSagas,
    ...reprocessSagas,
    ...companyCodesSagas,
    ...accountingParametersSagas,
    ...accountingEntriesSagas,
    ...monthClosingSagas,
    ...oakBrookSagas,
    ...exportsSagas,
  ]);
}
