import * as yup from 'yup';
import { AnnulMonthClosingForm } from '../../types';

export const minDate = new Date(2015, 0, 1);
export const maxDate = new Date();

export const justificationMaxLength = 500;

export const getSchema = (): yup.ObjectSchema<AnnulMonthClosingForm> =>
  yup.object().shape({
    country: yup.string().required(),
    accountingMonth: yup.date().required(),
    updatedBy: yup.string().required(),
    justification: yup.string().required().max(justificationMaxLength),
  });
