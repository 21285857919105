import { combineReducers, ReducersMapObject } from 'redux';
import {
  AppState,
  preferencesReducer,
  userReducer,
  layoutReducer,
  parametersReducer,
  reportReducer,
  errorReducer,
  dashboardReducer,
  reprocessReducer,
  companyCodesReducer,
  accountingParametersReducer,
  accountingEntriesReducer,
  monthClosingReducer,
  oakBrookReducer,
  notificationsReducer,
  exportsReducer,
} from '../../state';

const rootReducers: ReducersMapObject<AppState, never> = {
  preferences: preferencesReducer,
  user: userReducer,
  layout: layoutReducer,
  parameters: parametersReducer,
  report: reportReducer,
  error: errorReducer,
  dashboard: dashboardReducer,
  reprocess: reprocessReducer,
  companyCodes: companyCodesReducer,
  accountingParameters: accountingParametersReducer,
  accountingEntries: accountingEntriesReducer,
  monthClosing: monthClosingReducer,
  oakBrook: oakBrookReducer,
  notifications: notificationsReducer,
  exports: exportsReducer,
};

export default combineReducers(rootReducers);
