import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import { ActionType, ReprocessCreateRequestedAction } from './types';
import {
  SalesReprocessApi,
  FindReprocessParametersResponse,
  AppStorage,
  FindReprocessResponse,
} from '../../services';
import {
  reprocessParametersSuccess,
  reprocessParametersFailure,
  reprocessFindFailure,
  reprocessFindSuccess,
  reprocessCreateFailure,
  reprocessCreateSuccess,
} from './actionCreators';
import { HttpRequestError, ReprocessFilter } from '../../types';
import {
  createReprocessRequestMap,
  findReprocessParametersResponseMap,
  findReprocessRequestMap,
  findReprocessResponseMap,
} from '../../helpers';
import { reprocessFilterSelector } from '../selectors';

function* getParameters() {
  let reprocessParameters = AppStorage.getReprocessParameters();

  try {
    if (!reprocessParameters) {
      const { data }: AxiosResponse<FindReprocessParametersResponse> = yield call(
        SalesReprocessApi.findReprocessParameters
      );
      reprocessParameters = findReprocessParametersResponseMap(data);
      AppStorage.setReprocessParameters(reprocessParameters);
    }
    yield put(reprocessParametersSuccess(reprocessParameters));
  } catch (error) {
    yield put(reprocessParametersFailure(error as HttpRequestError));
  }
}

function* findReprocess() {
  const filters: ReprocessFilter = yield select(reprocessFilterSelector);

  try {
    const { data }: AxiosResponse<FindReprocessResponse> = yield call(
      SalesReprocessApi.findReprocess,
      findReprocessRequestMap(filters)
    );
    yield put(reprocessFindSuccess(findReprocessResponseMap(data)));
  } catch (error) {
    yield put(reprocessFindFailure(error as HttpRequestError));
  }
}

function* createReprocess({ payload }: ReprocessCreateRequestedAction) {
  try {
    yield call(SalesReprocessApi.createReprocess, createReprocessRequestMap(payload));
    yield put(reprocessCreateSuccess());
  } catch (error) {
    yield put(reprocessCreateFailure(error as HttpRequestError));
  }
}

export default [
  takeLatest(ActionType.parametersRequested, getParameters),
  takeLatest(ActionType.reprocessFindRequested, findReprocess),
  takeLatest(ActionType.reprocessCreateRequested, createReprocess),
];
