import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { DataGridFlexSales, useDataGridFlexSales } from '../../DataGridFlexSales';

import {
  reportCausesFilterCauseSelector,
  reportMissingClosingCollectionsCausesSelector,
} from '../../../state';
import handlerFactory, { CollectionsMissingsDataGridContext } from './handlerFactory';
import {
  ValidationErrorCause,
  CollectionsDifferencesCause,
  MissingCauses,
  Translation,
  RemoteResource,
  MissingClosingCollectionsCauses,
} from '../../../types';
import { ValidationErrorsList } from '../ValidationErrorsList';

const Container = styled(Grid)`
  height: 180px;
`;

interface DataGridConfigProps {
  t: Translation;
  resource: RemoteResource<MissingClosingCollectionsCauses | undefined>;
}

const DataGridConfig = ({ t, resource }: DataGridConfigProps) => {
  const { handler } = useDataGridFlexSales<
    CollectionsDifferencesCause | ValidationErrorCause,
    CollectionsMissingsDataGridContext
  >();

  const { loading } = resource;
  const data = resource.data?.details || [];

  const gridContext = useMemo(
    () => ({
      t,
    }),
    [t]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setLoading(loading);

  return null;
};

const CollectionErrors = () => {
  const { t } = useTranslation();
  const collectionsResource = useSelector(reportMissingClosingCollectionsCausesSelector);
  const cause = useSelector(reportCausesFilterCauseSelector);
  const isDifferenceError = cause === MissingCauses.DIFFERENCE_BETWEEN_CLOSING_AND_DETAIL;

  return (
    <Container>
      {isDifferenceError ? (
        <DataGridFlexSales handlerFactory={handlerFactory} hideToolbar>
          <DataGridConfig t={t} resource={collectionsResource} />
        </DataGridFlexSales>
      ) : (
        <ValidationErrorsList
          header={t('missing-closing-causes.columns.collections-details')}
          resource={collectionsResource}
        />
      )}
    </Container>
  );
};

export default CollectionErrors;
