import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  changeUpdate,
  serviceWorkerRegistrationSelector,
  serviceWorkerUpdatedSelector,
} from '../state';
import DialogFlexSales from './DialogFlexSales';

const UpdateControl = () => {
  const updateAvailable = useSelector(serviceWorkerUpdatedSelector);
  const registration = useSelector(serviceWorkerRegistrationSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const handleClose = useCallback(() => {
    const serviceWorker = registration?.waiting || registration?.active;
    if (serviceWorker) {
      serviceWorker.postMessage({ type: 'SKIP_WAITING' });
      serviceWorker.addEventListener('statechange', (e: any) => {
        if (e.target?.state === 'activated') {
          dispatch(changeUpdate(registration));
          window.sessionStorage.clear();
          navigate(0);
        }
      });
      if (serviceWorker.state === 'activated') {
        dispatch(changeUpdate(registration));
        window.sessionStorage.clear();
        navigate(0);
      }
    }
  }, [registration, registration?.waiting, registration?.active, dispatch]);

  useEffect(() => {
    navigator.serviceWorker.getRegistration().then(registration => {
      registration?.update();
    });
  }, [location]);

  return (
    <DialogFlexSales
      open={updateAvailable}
      onClose={handleClose}
      aria-describedby="alert-dialog-update-description"
    >
      <DialogTitle id="draggable-dialog-title">{t('alerts.newVersion.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-update-description">
          {t('alerts.newVersion.body')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('alerts.newVersion.refreshAction')}</Button>
      </DialogActions>
    </DialogFlexSales>
  );
};

export default UpdateControl;
