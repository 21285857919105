import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCollectionsViewMode,
  dashboardCollectionsClosingSelector,
  dashboardCollectionsViewModeSelector,
} from '../../../state';
import { IncorporationActionsProps } from './IncorporationActions';
import IncorporationCard from './IncorporationCard';

const itemModeInitial: IncorporationActionsProps['initValueItemMode'] = 'error';

const CollectionsIncorporation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const collections = useSelector(dashboardCollectionsClosingSelector);
  const intervalModeInitial = useSelector(dashboardCollectionsViewModeSelector);

  const handleChangeIntervalMode: NonNullable<IncorporationActionsProps['onChangeIntervalMode']> =
    useCallback(
      value => {
        dispatch(changeCollectionsViewMode(value));
      },
      [dispatch]
    );

  return (
    <IncorporationCard
      resource={collections}
      title={t('dashboard.collections-incorporation')}
      onChangeIntervalMode={handleChangeIntervalMode}
      initValueIntervalMode={intervalModeInitial}
      initValueItemMode={itemModeInitial}
      name="collections"
    />
  );
};

export default CollectionsIncorporation;
