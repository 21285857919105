import { Action } from 'redux';
import {
  HttpErrorBaseAction,
  RemoteResource,
  OakBrookFilter,
  Country,
  OakBrook,
} from '../../types';

export interface State {
  filters: OakBrookFilter;
  oakBrook: RemoteResource<OakBrook[]>;
  downloading: boolean;
}

export enum ActionType {
  oakBrookChangeFilterCountry = 'oakBrook/changeFilterCountry',
  oakBrookChangeFilterMonth = 'oakBrook/changeFilterMonth',
  oakBrookFindRequested = 'oakBrook/findRequested',
  oakBrookFindSuccess = 'oakBrook/findSuccess',
  oakBrookFindFailure = 'oakBrook/findFailure',
  oakBrookDownloadRequested = 'oakBrook/downloadRequested',
  oakBrookDownloadFinished = 'oakBrook/downloadFinished',
}

export interface OakBrookChangeFilterCountryAction
  extends Action<ActionType.oakBrookChangeFilterCountry> {
  country: Country | null;
}

export interface OakBrookChangeFilterMonthAction
  extends Action<ActionType.oakBrookChangeFilterMonth> {
  month: Date | null;
}

export type OakBrookFindRequestedAction = Action<ActionType.oakBrookFindRequested>;
export type OakBrookFindSuccessAction = Action<ActionType.oakBrookFindSuccess> & {
  oakBrook: OakBrook[];
};
export type OakBrookFindFailureAction = HttpErrorBaseAction<ActionType.oakBrookFindFailure>;

export type OakBrookDownloadRequestedAction = Action<ActionType.oakBrookDownloadRequested>;
export type OakBrookDownloadFinishedAction = Action<ActionType.oakBrookDownloadFinished>;

export type Actions =
  | OakBrookChangeFilterCountryAction
  | OakBrookFindRequestedAction
  | OakBrookFindSuccessAction
  | OakBrookFindFailureAction
  | OakBrookChangeFilterMonthAction
  | OakBrookDownloadRequestedAction
  | OakBrookDownloadFinishedAction;
