import { createTheme, PaletteMode } from '@mui/material';
import { enUS, esES, frFR, ptBR } from '@mui/material/locale';
import { SupportedLanguages } from './i18n/translations';

const muiLocal: Record<SupportedLanguages, object[]> = {
  fr: [frFR],
  es: [esES],
  en: [enUS],
  pt: [ptBR],
};

const themeFactory = (mode: PaletteMode, locale: string) => {
  const otherProps =
    mode === 'dark'
      ? {
          background: {
            paper: '#424242',
            default: '#303030',
          },
        }
      : undefined;
  const overrideComponents =
    mode === 'dark'
      ? {
          components: {
            MuiAppBar: {
              styleOverrides: {
                root: {
                  backgroundImage: 'none',
                },
              },
            },
            MuiList: {
              styleOverrides: {
                root: {
                  backgroundImage: 'none',
                },
              },
            },
            MuiDialog: {
              styleOverrides: {
                paper: {
                  backgroundImage: 'none',
                },
              },
            },
            MuiPaper: {
              styleOverrides: {
                root: {
                  backgroundImage: 'none',
                },
              },
            },
          },
        }
      : {};

  return createTheme(
    {
      palette: {
        primary: { main: '#bf172b', light: 'rgba(191, 23, 43, 0.08)' },
        secondary: { main: '#DA291C' },
        ...otherProps,
        mode,
      },
      ...overrideComponents,
    },
    ...muiLocal[locale as SupportedLanguages]
  );
};
export default themeFactory;
