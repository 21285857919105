import { useSelector } from 'react-redux';
import { usePermissions } from '../components/Permissions';
import { getCountriesForPermissions } from '../helpers';
import { activeCountriesSelector, userPermissionsDataSelector } from '../state';

const useCountriesSelect = () => {
  const { resourcePermissions } = usePermissions();
  const activeCountries = useSelector(activeCountriesSelector);
  const permissions = useSelector(userPermissionsDataSelector);
  const countries = activeCountries.loaded && activeCountries.data ? activeCountries.data : [];

  const countriesForPermissions = getCountriesForPermissions(
    resourcePermissions,
    permissions,
    countries
  );

  const disabled = !activeCountries.loaded || countriesForPermissions.length < 2;

  return { options: countriesForPermissions, disabled, loading: activeCountries.loading };
};

export default useCountriesSelect;
