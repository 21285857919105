import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataGridFlexSales, useDataGridFlexSales } from '../../../../DataGridFlexSales';

import {
  activeCountriesSelector,
  dashboardDailySalesDailySalesSelector,
  dashboardDailySalesDelayedStoresSelector,
  masterDataSelector,
} from '../../../../../state';
import handlerFactory, { DailySalesDataGridContext } from './handlerFactory';
import { DailySale } from '../../../../../types';
import { useMuiPickersLocale } from '../../../../../hooks';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<DailySale, DailySalesDataGridContext>();

  const { t } = useTranslation();
  const locale = useMuiPickersLocale();
  const dailySalesResource = useSelector(dashboardDailySalesDailySalesSelector);
  const masterDataResource = useSelector(masterDataSelector);
  const activeCountriesResource = useSelector(activeCountriesSelector);
  const delayedStores = useSelector(dashboardDailySalesDelayedStoresSelector);

  const { data, loading: resourceLoading } = dailySalesResource;
  const loading = resourceLoading || masterDataResource.loading || activeCountriesResource.loading;

  const gridContext = useMemo(() => ({ t, locale, delayedStores }), [delayedStores, locale, t]);

  handler.setContext(gridContext);
  handler.setData(data);
  handler.setLoading(loading);

  return null;
};

const DailySalesDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory} hideToolbar>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default DailySalesDataGrid;
