import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import { isModeModalSelector, toggleSidebar } from '../../state';
import MenuHeader from './MenuHeader';
import Favicon from '../../img/favicon.png';

const AppBarStyled = styled(AppBar)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;

const TypographyStyled = styled(Typography)`
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

const IconButtonRight = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const BackButtons = () => {
  const isModeModal = useSelector(isModeModalSelector);
  const navigate = useNavigate();
  const handleClick = () => navigate(-1);

  return isModeModal ? (
    <IconButtonRight color="inherit" aria-label="back-button" edge="start" onClick={handleClick}>
      <ArrowBackIcon />
    </IconButtonRight>
  ) : null;
};

const MenuButton = () => {
  const isModeModal = useSelector(isModeModalSelector);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(toggleSidebar());
  return !isModeModal ? (
    <IconButtonRight color="inherit" aria-label="back-button" edge="start" onClick={handleClick}>
      <MenuIcon />
    </IconButtonRight>
  ) : null;
};

const Logo = () => {
  const isModeModal = useSelector(isModeModalSelector);
  const { t } = useTranslation();
  return (
    <Box
      component="img"
      sx={!isModeModal ? { height: 35 } : { height: 50 }}
      alt={t('shared.title')}
      src={Favicon}
    />
  );
};

const Header = () => {
  const { t } = useTranslation();

  return (
    <AppBarStyled position="fixed" enableColorOnDark>
      <Toolbar>
        <MenuButton />
        <BackButtons />
        <Logo />
        <TypographyStyled variant="h6" noWrap>
          {t('shared.title')}
        </TypographyStyled>

        <IconButton
          aria-label="help"
          aria-controls="header-help"
          aria-haspopup="false"
          component={Link}
          to={routes.protectedRoutes.help.path}
          color="inherit"
        >
          <HelpIcon />
        </IconButton>
        <MenuHeader />
      </Toolbar>
    </AppBarStyled>
  );
};

export default Header;
