import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeMastersImportFilterState,
  reportMastersImportFilterStateSelector,
} from '../../../state';
import { MultiSelect } from '../../selects';

const StateFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const options = ['OK', 'ERROR', 'PENDING'];
  const state = useSelector(reportMastersImportFilterStateSelector);

  const handleChange = useCallback(
    (value: string[]) => dispatch(changeMastersImportFilterState(value)),
    [dispatch]
  );

  return (
    <MultiSelect
      label={t('masters-detail.labels.state')}
      value={state}
      options={options}
      onChange={handleChange}
      translationKey="masters-imports.states"
      fullWidth
    />
  );
};

export default StateFilter;
