import { PendingInterfaces } from '../../types';
import { DataGridHandler, commonColProps } from '../DataGridFlexSales';

export interface PendingInterfacesDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
}

const initialContext: PendingInterfacesDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<PendingInterfaces, PendingInterfacesDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'countryId',
    type: 'string',
    title: ({ t }) => t('table.columns.country_id'),
    defaultHidden: true,
    ...commonColProps.countryId,
  });

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'storeId',
    type: 'number',
    title: ({ t }) => t('table.columns.store_id'),
    defaultHidden: true,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
  });

  handler.addColumn({
    accessor: 'dataEntry',
    type: 'string',
    title: ({ t }) => t('table.columns.data_entry'),
  });

  handler.addColumn({
    accessor: 'workingDay',
    type: 'boolean',
    title: ({ t }) => t('table.columns.working_day'),
  });

  handler.addColumn({
    accessor: 'salesClose',
    type: 'string',
    title: ({ t }) => t('table.columns.sales_close'),
  });

  handler.addColumn({
    accessor: 'salesCause',
    type: 'string',
    title: ({ t }) => t('table.columns.sales_cause'),
  });

  handler.addColumn({
    accessor: 'salesDetails',
    type: 'string',
    title: ({ t }) => t('table.columns.sales_details'),
  });

  handler.addColumn({
    accessor: 'collClose',
    type: 'string',
    title: ({ t }) => t('table.columns.coll_close'),
  });

  handler.addColumn({
    accessor: 'collCause',
    type: 'string',
    title: ({ t }) => t('table.columns.coll_cause'),
  });

  handler.addColumn({
    accessor: 'collDetails',
    type: 'string',
    title: ({ t }) => t('table.columns.coll_details'),
  });

  handler.addColumn({
    accessor: 'whData',
    type: 'string',
    title: ({ t }) => t('table.columns.wh_data'),
  });

  handler.addColumn({
    accessor: 'grSalesProccesed',
    type: 'string',
    title: ({ t }) => t('table.columns.gr_sales_processed'),
  });

  handler.addColumn({
    accessor: 'grSalesSent',
    type: 'string',
    title: ({ t }) => t('table.columns.gr_sales_sent'),
  });

  handler.addColumn({
    accessor: 'grStatus',
    type: 'string',
    title: ({ t }) => t('table.columns.gr_status'),
  });

  handler.addColumn({
    accessor: 'groupedTotalSales',
    type: 'string',
    title: ({ t }) => t('table.columns.gr_total_sales'),
  });

  handler.addColumn({
    accessor: 'groupedSalesByPayment',
    type: 'string',
    title: ({ t }) => t('table.columns.gr_sales_by_payments'),
  });

  handler.addColumn({
    accessor: 'groupedSalesByProduct',
    type: 'string',
    title: ({ t }) => t('table.columns.gr_sales_by_product'),
  });

  handler.addColumn({
    accessor: 'lastUpdate',
    type: 'string',
    title: ({ t }) => t('table.columns.last_update'),
  });

  return handler;
};
