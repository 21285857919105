import { Actions, State, ActionType } from './types';
import { Permission } from '../../types';
import {
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoading,
  remoteResourceLoadSuccess,
} from '../../helpers';

const initialState: State = {
  isAuthenticated: false,
  info: null,
  permissions: remoteResourceInitialState<Permission[]>([]),
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.msalSignInSuccess:
      return {
        ...state,
        info: { username: action.username, name: action.name },
      };
    case ActionType.permissionsRequested:
      return {
        ...state,
        isAuthenticated: false,
        permissions: remoteResourceLoading([]),
      };
    case ActionType.permissionsSuccess:
      return {
        ...state,
        isAuthenticated: true,
        permissions: remoteResourceLoadSuccess(action.permissions),
      };
    case ActionType.permissionsFailure:
      return {
        ...state,
        permissions: remoteResourceLoadFailure([], action.error),
      };
    default:
      return state;
  }
};

export default reducer;
