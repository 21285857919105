import styled from '@emotion/styled';
import { GCsByProductDataGrid, GCsByProductSearch } from '../components';
import FiltersLayoutHandler from '../components/FiltersLayoutHandler';
import { reportGCsByProductLoadingSelector } from '../state/selectors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const GCsByProduct = () => (
  <Container>
    <FiltersLayoutHandler selector={reportGCsByProductLoadingSelector}>
      <GCsByProductSearch />
    </FiltersLayoutHandler>
    <GCsByProductDataGrid />
  </Container>
);

export default GCsByProduct;
