import { SelectFlexSales, SelectFlexSalesProps } from './SelectFlexSales';
import { RemoteResource, HourRange } from '../../types';

type HourRangeSelectProps = Omit<
  SelectFlexSalesProps<HourRange, true, undefined, false>,
  'options' | 'resource'
> & {
  resource: RemoteResource<HourRange[]>;
  disabled: boolean;
};

const HourRangeSelect = ({ resource, disabled, ...others }: HourRangeSelectProps) => {
  const { data, loading } = resource;
  return (
    <SelectFlexSales {...others} options={data} multiple disabled={disabled} loading={loading} />
  );
};

export default HourRangeSelect;
