import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearValidationErrors, erroValidationErrorsSelector } from '../state';
import DialogFlexSales from './DialogFlexSales';

const ValidationErrorAlert = () => {
  const errors = useSelector(erroValidationErrorsSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClose = useCallback(() => dispatch(clearValidationErrors()), [dispatch]);
  if (!errors?.length) return null;

  return (
    <DialogFlexSales
      open
      onClose={handleClose}
      aria-describedby="alert-dialog-validation-error-description"
    >
      <DialogTitle id="draggable-dialog-title">{t('validations.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-validation-error-description">
          {errors.map(err => (
            <Alert key={`${err.code}-${err.field}`} severity="error">
              {t(`validations.${err.code}`, {
                field: t(`toolbar.labels.${err.field}`, { defaultValue: err.field }),
              })}
            </Alert>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </DialogFlexSales>
  );
};

export default ValidationErrorAlert;
