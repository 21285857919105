import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { ReprocessDataGridContext } from './handlerFactory';
import { Reprocess } from '../../types';
import { reprocessFilterCountryCodeSelector, reprocessReprocessSelector } from '../../state';

const DataGridConfig = () => {
  const { t } = useTranslation();
  const { handler } = useDataGridFlexSales<Reprocess, ReprocessDataGridContext>();
  const countryCode = useSelector(reprocessFilterCountryCodeSelector);
  const { loading, data } = useSelector(reprocessReprocessSelector);

  const gridContext = useMemo(() => ({ t, countryCode }), [t, countryCode]);

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(t('reprocess.title'));
  handler.setLoading(loading);

  return null;
};

const ReprocessDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default ReprocessDataGrid;
