import { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import CountrySelect from './CountryFilter';
import { AppPermissions } from '../../../types';
import { PermissionsProvider } from '../../Permissions';

export interface MonthClosingFiltersProps {
  actions: ReactNode;
}

const MonthClosingFilters = ({ actions }: MonthClosingFiltersProps) => (
  <PermissionsProvider resourcePermissions={[AppPermissions.MONTH_CLOSING]}>
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12} md={3} lg={2} xl={2}>
        <CountrySelect fullWidth />
      </Grid>
      <Grid item xs={12} md lg xl>
        {actions}
      </Grid>
    </Grid>
  </PermissionsProvider>
);

export default MonthClosingFilters;
