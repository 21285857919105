import {
  ThirdPartySalesByItems,
  ReportThirdPartySalesByItemsGroupBy,
  Translation,
} from '../../types';
import { commonColProps, DataGridHandler } from '../DataGridFlexSales';

export interface ThirdPartySalesByItemsDataGridContext {
  t: Translation;
  country: string;
  groupBy: ReportThirdPartySalesByItemsGroupBy[];
}

const initialContext: ThirdPartySalesByItemsDataGridContext = {
  t: () => '',
  country: '',
  groupBy: [],
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<
    ThirdPartySalesByItems,
    ThirdPartySalesByItemsDataGridContext
  >(initialContext, locale);

  handler.addColumn({
    accessor: 'store',
    ...commonColProps.storeWithGroupBy,
  });

  handler.addColumn({
    accessor: 'dateFrom',
    type: 'date',
    title: ({ t }) => t('table.columns.fromDate'),
  });

  handler.addColumn({
    accessor: 'dateTo',
    type: 'date',
    title: ({ t }) => t('table.columns.untilDate'),
  });

  handler.addColumn({
    accessor: 'accountingDate',
    ...commonColProps.accountingDateWithGroupBy,
  });

  handler.addColumn({
    accessor: 'plu',
    ...commonColProps.pluWithGroupBy,
  });

  handler.addColumn({
    accessor: 'shortName',
    type: 'string',
    title: ({ t }) => t('table.columns.short_name'),
    available: ({ groupBy }: { groupBy: string[] }) => groupBy.includes('plu'),
  });

  handler.addColumn({
    accessor: 'quantitySold',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_sold'),
    available: ({ groupBy }) => groupBy.includes('plu'),
  });

  handler.addColumn({
    accessor: 'quantityPromo',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_promo'),
  });

  handler.addColumn({
    accessor: 'quantityMeal',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_meal'),
  });

  handler.addColumn({
    accessor: 'quantityWaste',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_waste'),
  });

  handler.addColumn({
    accessor: 'grossSaleAd',
    type: 'number',
    title: ({ t }) => t('table.columns.venta_bruta'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'calculatedNetSale',
    type: 'number',
    title: ({ t }) => t('table.columns.item_total_amt'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'saleMixPercentage',
    type: 'percent',
    title: ({ t }) => t('table.columns.saleMixPercentage'),
  });

  return handler;
};
