import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCountriesForPermissions } from '../../helpers';
import { activeCountriesSelector, userPermissionsDataSelector } from '../../state';
import { Country, Translation } from '../../types';
import { usePermissions } from '../Permissions';
import { SelectFlexSales } from './SelectFlexSales';

interface DivisionCountrySelectProps {
  onChange: (value: Country | null) => void;
  value: Country | null;
  disableClearable?: boolean;
  groupByDivision?: boolean;
}

const getCountryLabel = (t: Translation, country: Country, groupByDivision?: boolean) =>
  groupByDivision
    ? `${country.divisionId}-${t(`operativeStructure.countries.${country.countryCode}`)}`
    : t(`operativeStructure.countries.${country.countryCode}`);

const DivisionCountrySelect = (props: DivisionCountrySelectProps) => {
  const { onChange, groupByDivision } = props;
  const { t } = useTranslation();
  const { resourcePermissions } = usePermissions();
  const activeCountries = useSelector(activeCountriesSelector);
  const disabled =
    activeCountries.failure || !activeCountries.data || activeCountries.data.length < 1;
  const permissions = useSelector(userPermissionsDataSelector);

  const getOptionLabel = useCallback(
    (country: Country) => t(`operativeStructure.countries.${country.countryCode}`),
    [t]
  );

  const options = useMemo(() => {
    const countries = activeCountries.loaded && activeCountries.data ? activeCountries.data : [];
    const countriesForPermissions = getCountriesForPermissions(
      resourcePermissions,
      permissions,
      countries
    );
    return countriesForPermissions.sort((a, b) =>
      getCountryLabel(t, a, groupByDivision) > getCountryLabel(t, b, groupByDivision) ? 1 : -1
    );
  }, [activeCountries, groupByDivision, permissions, resourcePermissions, t]);

  if (options.length === 1) onChange(options[0]);

  const groupBy = (country: Country) => t(`operativeStructure.division.${country.divisionId}`);
  return (
    <SelectFlexSales
      {...props}
      label={t('toolbar.labels.country')}
      loading={activeCountries.loading}
      options={options}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      groupBy={groupByDivision ? groupBy : undefined}
    />
  );
};

export default DivisionCountrySelect;
