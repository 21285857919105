import { getStatusColor } from '../../helpers';
import { MastersImport } from '../../types';
import { DataGridHandler } from '../DataGridFlexSales';

export interface MastersStatusDataGridContext {
  t: (key: string | string[]) => string;
}

const initialContext: MastersStatusDataGridContext = {
  t: () => '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<MastersImport, MastersStatusDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'importDate',
    type: 'date',
    title: ({ t }) => t('masters-imports.columns.importDate'),
  });

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('masters-imports.columns.country'),
  });

  handler.addColumn({
    accessor: 'type',
    type: 'string',
    title: ({ t }) => t('masters-imports.columns.type'),
    width: { px: 220, pt: 215 },
  });

  handler.addColumn({
    accessor: 'quantity',
    type: 'number',
    title: ({ t }) => t('masters-imports.columns.quantity'),
  });

  handler.addColumn({
    name: 'state',
    type: 'string',
    title: ({ t }) => t('masters-imports.columns.state'),
    valueGetter: row => row.state,
    renderCell: (_value, row, { t }) => (
      <div style={{ color: getStatusColor(row.state), fontWeight: 'bold' }}>
        {t(`masters-imports.states.${row.state}`)}
      </div>
    ),
    width: { px: 140, pt: 95 },
  });

  handler.addColumn({
    accessor: 'description',
    type: 'string',
    title: ({ t }) => t('masters-imports.columns.description'),
    width: { px: 300, pt: 295 },
  });

  return handler;
};
