import styled from '@emotion/styled';
import { Box } from '@mui/material';
import ToolbarMui from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import ColumnChooser from './ColumnChooser';
import { useDataGridFlexSales } from './DataGridFlexSalesProvider';
import Export from './Export';
import { CurrentDateWarning } from './alerts';

const ToolbarStyled = styled(ToolbarMui)`
  background-color: ${props => props.theme.palette.primary.main};
  justify-content: space-between;
  color: white;

  button {
    color: inherit;
  }

  &.Toolbar-toolbar {
    border: none;
  }

  @media (min-width: 600px) {
    padding-left: ${props => props.theme.spacing(2)};
    padding-right: ${props => props.theme.spacing(2)};
  }
`;

const DataGridAlerts = () => {
  const { handler } = useDataGridFlexSales();
  const [filters, setFilters] = useState(handler.filters);

  useEffect(() => {
    const listenerFilters = handler.addEventListener('filters', ({ filters }) =>
      setFilters(filters)
    );

    return () => {
      listenerFilters.dispose();
    };
  }, [handler]);

  return <CurrentDateWarning filters={filters} />;
};

const DataGridTitle = () => {
  const { handler } = useDataGridFlexSales();
  const [title, setTitle] = useState(handler.titles.tableTitle);

  useEffect(() => {
    const listenerTitle = handler.addEventListener('title', ({ titles }) =>
      setTitle(titles.tableTitle)
    );

    return () => {
      listenerTitle.dispose();
    };
  }, [handler]);

  return <Typography variant="h6">{title}</Typography>;
};

export const Toolbar = () => (
  <ToolbarStyled variant="dense">
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
      <DataGridTitle />
      <DataGridAlerts />
    </Box>
    <div>
      <ColumnChooser />
      <Export />
    </div>
  </ToolbarStyled>
);
