import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilterCountry, reportFilterCountryCodeSelector } from '../../../state';
import { CountrySelect, SimpleSelectProps } from '../../selects';

type CountryFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const CountryFilter = (props: CountryFilterProps) => {
  const dispatch = useDispatch();
  const countryCode = useSelector(reportFilterCountryCodeSelector);

  const handleChange = useCallback(
    (value: string) => dispatch(changeFilterCountry(value)),
    [dispatch]
  );

  return <CountrySelect {...props} onChange={handleChange} value={countryCode} simple />;
};

export default CountryFilter;
