import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFilterTimeBand,
  reportFilterHourRangeSelector,
  reportFilterTimeBandSelector,
  reportTimeBandSelector,
} from '../../../state';
import { MultiSelect } from '../../selects';

const TimeBandFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timeBandResource = useSelector(reportTimeBandSelector);
  const timeBand = useSelector(reportFilterTimeBandSelector);
  const hourRange = useSelector(reportFilterHourRangeSelector);

  const disabled = !timeBandResource.loaded || hourRange.length > 0;
  const timeBandOptions = timeBandResource.data.map(i => i.description);

  const handleChange = useCallback(
    (value: string[]) => dispatch(changeFilterTimeBand(value)),
    [dispatch]
  );

  return (
    <MultiSelect
      label={t('toolbar.labels.time-band')}
      options={timeBandOptions}
      disabled={disabled}
      value={timeBand}
      onChange={handleChange}
      loading={timeBandResource.loading}
    />
  );
};

export default TimeBandFilter;
