import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { oakBrookChangeFilterCountry, oakBrookCountryFilterSelector } from '../../../state';
import { Country } from '../../../types';
import { DivisionCountrySelect, SimpleSelectProps } from '../../selects';

type CountryFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const CountryFilter = (props: CountryFilterProps) => {
  const dispatch = useDispatch();
  const country = useSelector(oakBrookCountryFilterSelector);

  const handleChange = useCallback(
    (value: Country | null) => dispatch(oakBrookChangeFilterCountry(value)),
    [dispatch]
  );

  return <DivisionCountrySelect {...props} onChange={handleChange} value={country} />;
};

export default CountryFilter;
