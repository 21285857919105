import { Stack } from '@mui/material';
import AgreementFilter from './AgreementFilter';
import CompanyFilter from './CompanyFilter';
import ManagementFilter from './ManagementFilter';
import RegionalManagementFilter from './RegionalManagementFilter';
import RegionFilter from './RegionFilter';
import SupervisionFilter from './SupervisionFilter';

const OpStructuresFilters = () => (
  <Stack spacing={3}>
    <RegionalManagementFilter />
    <RegionFilter />
    <ManagementFilter />
    <SupervisionFilter />
    <AgreementFilter />
    <CompanyFilter />
  </Stack>
);
export default OpStructuresFilters;
