import { useTranslation } from 'react-i18next';
import { PaletteMode, ThemeProvider, useMediaQuery } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { themeSelector } from '../state';
import { ThemePreference } from '../types';

import themeFactory from '../config/theme.config';

interface Props {
  children: ReactNode;
}

const getPaletteMode = (currentThemeMode: ThemePreference, systemThemeMode: PaletteMode) => {
  if (['light', 'dark'].includes(currentThemeMode)) return currentThemeMode as PaletteMode;

  return systemThemeMode;
};

const ThemeSwitcherProvider = ({ children }: Props) => {
  const theme = useSelector(themeSelector);
  const { i18n } = useTranslation();
  const systemThemeMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
  const prefersThemeMode = getPaletteMode(theme, systemThemeMode);
  const themeSelected = useMemo(
    () => themeFactory(prefersThemeMode, i18n.language),
    [prefersThemeMode, i18n.language]
  );

  return <ThemeProvider theme={themeSelected}>{children}</ThemeProvider>;
};

export default ThemeSwitcherProvider;
