import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useSupervision from '../../../hooks/useSupervision';
import {
  changeOpStrucuresFilterSupervision,
  reportFilterCountryCodeSelector,
  reportOpStructuresFilterManagementSelector,
  reportOpStructuresFilterRegionalManagementSelector,
  reportOpStructuresFilterRegionSelector,
  reportOpStructuresFilterSupervisionSelector,
} from '../../../state';
import { Supervision } from '../../../types';
import { MultiSelect } from '../../selects';

const SupervisionFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const countryCode = useSelector(reportFilterCountryCodeSelector);
  const supervisions = useSelector(reportOpStructuresFilterSupervisionSelector);
  const regionalManagement = useSelector(reportOpStructuresFilterRegionalManagementSelector);
  const regions = useSelector(reportOpStructuresFilterRegionSelector);
  const managements = useSelector(reportOpStructuresFilterManagementSelector);
  const { options, disabled, loading } = useSupervision(
    countryCode,
    regionalManagement,
    regions,
    managements
  );

  const handleChange = useCallback(
    (value: Supervision[]) => dispatch(changeOpStrucuresFilterSupervision(value)),
    [dispatch]
  );

  return (
    <MultiSelect
      label={t('toolbar.labels.supervision')}
      options={options}
      propDesc="label"
      disabled={disabled}
      value={supervisions}
      onChange={handleChange}
      loading={loading}
    />
  );
};

export default SupervisionFilter;
