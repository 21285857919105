import Grid from '@mui/material/Grid';
import { OptionsObject, useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountingEntriesActionType,
  accountingEntriesChangeDelete,
  accountingEntriesFindRequested,
} from '../../state';
import { AccountingEntry, DeleteAccountingEntriesForm } from '../../types';
import DialogForm from '../DialogForm';
import { FlexSalesForm, FlexSalesFormActions, TextFieldFormInput } from '../FlexSalesForm';
import { useListener } from '../../hooks';
import { commentsMaxLength, getSchema } from './schema';

const inputProps = { maxLength: commentsMaxLength };

const messageOptions: OptionsObject = {
  variant: 'success',
  autoHideDuration: 6000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
};

interface CompanyCodeEditFormProps {
  accountingEntry: AccountingEntry;
}

const AccountingEntryDeleteForm = ({ accountingEntry }: CompanyCodeEditFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues: DeleteAccountingEntriesForm = useMemo(
    () => ({ ...accountingEntry, comments: '' }),
    [accountingEntry]
  );
  const resolver = useMemo(() => yupResolver(getSchema()), []);

  const formListener = useListener(
    AccountingEntriesActionType.accountingEntriesDeleteRequested,
    AccountingEntriesActionType.accountingEntriesDeleteSuccess,
    AccountingEntriesActionType.accountingEntriesDeleteFailure
  );
  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    dispatch(accountingEntriesChangeDelete(undefined));
  }, [dispatch]);

  const onSubmit = async (data: DeleteAccountingEntriesForm) => {
    await formListener(data);
    handleCancel();
    enqueueSnackbar(t('accountingEntries.messages.deleteSuccess'), messageOptions);
    dispatch(accountingEntriesFindRequested());
  };

  return (
    <DialogForm title={t('accountingEntries.titles.delete')} maxWidth="md">
      <FlexSalesForm onValidSubmit={onSubmit} defaultValues={defaultValues} resolver={resolver}>
        <Grid container flexDirection="column">
          <Grid item xs>
            <TextFieldFormInput
              name="comments"
              type="text"
              variant="standard"
              label={t('accountingEntries.labels.justification')}
              required
              inputProps={inputProps}
              rows={3}
              multiline
            />
          </Grid>
        </Grid>
        <FlexSalesFormActions
          onCancel={handleCancel}
          cancelLabel={t('companyCodes.labels.cancel')}
          submitLabel={t('accountingEntries.labels.save')}
        />
      </FlexSalesForm>
    </DialogForm>
  );
};

export default AccountingEntryDeleteForm;
