import { useCallback } from 'react';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import { useFlexSalesForm } from './FlexSalesForm';

type MuiFormControlLabelProps = Omit<FormControlLabelProps, 'control'>;

export type SwitchFormInputProps<TFieldValues extends FieldValues = FieldValues> =
  MuiFormControlLabelProps & {
    name: Path<TFieldValues>;
  };

const SwitchFormInput = <TFieldValues extends FieldValues = FieldValues>({
  name,
  required,
  ...rest
}: SwitchFormInputProps<TFieldValues>): JSX.Element => {
  const { control } = useFlexSalesForm<TFieldValues>();

  const render: ControllerProps<TFieldValues>['render'] = useCallback(
    ({ field }) => (
      <FormControlLabel
        required={required}
        name={name}
        control={<Switch {...field} checked={Boolean(field.value)} />}
        {...rest}
      />
    ),
    [name, rest, required]
  );

  return <Controller name={name} control={control} render={render} />;
};

export default SwitchFormInput;
