import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { masterDataSelector } from '../state';
import { Management, Region, RegionalManagement, Supervision } from '../types';

const supervisionsSort = (a: Supervision, b: Supervision) => (a.label > b.label ? 1 : -1);

const useManagement = (
  countryCode: string,
  regionalManagements: RegionalManagement[],
  regions: Region[],
  management: Management[]
) => {
  const masterData = useSelector(masterDataSelector);

  const supervisionsForDivisonCountry = useMemo(() => {
    const supervisions = masterData.loaded && masterData.data ? masterData.data.supervisions : [];
    return countryCode
      ? supervisions.filter(i => i.countryCode === countryCode).sort(supervisionsSort)
      : supervisions;
  }, [masterData, countryCode]);

  const supervisionsForRegionalManagement = useMemo(() => {
    const supervisions = supervisionsForDivisonCountry;
    return regionalManagements.length > 0
      ? supervisions
          .filter(r => regionalManagements.map(i => i.regionalMgmtId).includes(r.regionalMgmtId))
          .sort(supervisionsSort)
      : supervisions;
  }, [regionalManagements, supervisionsForDivisonCountry]);

  const supervisionsForRegion = useMemo(() => {
    const supervisions = supervisionsForRegionalManagement;
    return regions.length > 0
      ? supervisions
          .filter(r => regions.map(i => i.regionId).includes(r.regionId))
          .sort(supervisionsSort)
      : supervisions;
  }, [supervisionsForRegionalManagement, regions]);

  const supervisionsForManagement = useMemo(() => {
    const supervisions = supervisionsForRegion;
    return management.length > 0
      ? supervisions
          .filter(r => management.map(i => i.mgmtId).includes(r.mgmtId))
          .sort(supervisionsSort)
      : supervisions;
  }, [supervisionsForRegion, management]);

  const disabled = !masterData.data;

  const options = supervisionsForManagement || [];

  return { options, disabled, loading: masterData.loading };
};

export default useManagement;
