import { VirtualTableProps } from '@devexpress/dx-react-grid-material-ui';
import { Row, Column, RowTotal, Sorting, ColumnAction } from './DataGridHandler';
import { ActionColumn } from './devExpressCustom';

export const buildRows = <TData>(rows: Row<TData>[]) =>
  rows.map(({ cells, item }) =>
    cells.reduce((result, column) => ({ ...result, [column.id]: column.getValue() }), {
      ORIGINAL_ITEM: item,
    })
  );

export const buildColumns = <TData, TContext>(
  columns: Column<TData>[],
  actions: Record<string, ColumnAction<TData, TContext>>
) =>
  columns
    .filter(i => i.isAvailable())
    .map(({ id, getTitle }) => ({
      name: id,
      title: getTitle(),
    }))
    .concat(Object.keys(actions).map(i => ({ name: i, title: '' })));

export const buildSorting = <TData>({ column, ascending }: Sorting<TData>) =>
  column
    ? [
        {
          columnName: column.id,
          direction: ascending ? ('asc' as const) : ('desc' as const),
        },
      ]
    : [];

const columnWidthPaddings = 55;
export const buildColumnExtensions = <TData>(
  columns: Column<TData>[]
): VirtualTableProps['columnExtensions'] =>
  columns.map(i => ({
    columnName: i.id,
    width: i.getWidth().px + columnWidthPaddings,
    align: i.align,
  }));

export const buidTotalSummaryItems = <TData>(rowTotal: RowTotal<TData>[]) =>
  rowTotal.map(({ columnId, type }) => ({ columnName: columnId, type }));

export const buidTotalSummaryValues = <TData>(rowTotal: RowTotal<TData>[]) =>
  rowTotal.map(i => i.value);

export const buildActions = <TData, TContext>(
  actions: Record<string, ColumnAction<TData, TContext>>
): ActionColumn<TData, TContext>[] =>
  Object.entries(actions).map(([columnId, props]) => ({
    columnId,
    ...props,
  }));
