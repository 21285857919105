import { ReactNode } from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { useFlexSalesFormState } from './FlexSalesForm';
import { LoadingButton } from '../buttons';

const ActionsContainer = styled.div`
  display: flex;
  margin-top: ${props => props.theme.spacing(2)};
  justify-content: space-around;
`;

interface ActionsProps {
  onCancel: () => void;
  submitLabel: ReactNode;
  cancelLabel: ReactNode;
}

const FlexSalesFormActions = ({ onCancel, cancelLabel, submitLabel }: ActionsProps) => {
  const { isSubmitting, isValid } = useFlexSalesFormState();

  return (
    <ActionsContainer>
      <Button disabled={isSubmitting} variant="contained" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <LoadingButton type="submit" loading={isSubmitting} disabled={!isValid} variant="contained">
        {submitLabel}
      </LoadingButton>
    </ActionsContainer>
  );
};

export default FlexSalesFormActions;
