import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { DashboardData } from '../components';

const Dashboard = () => (
  <>
    <Grid container>
      <Grid item xs={12}>
        <DashboardData />
      </Grid>
    </Grid>
    <Outlet />
  </>
);

export default Dashboard;
