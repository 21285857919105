import { HttpRequestError, Reprocess, ReprocessParameters } from '../../types';
import {
  ActionType,
  ReprocessChangeFilterBusinessDateAction,
  ReprocessChangeFilterCountryAction,
  ReprocessChangeFilterReprocessTypeAction,
  ReprocessChangeFilterStatusAction,
  ReprocessChangeFilterStoreAction,
  ReprocessChangeFilterFederativeEntityAction,
  ReprocessCreateFailureAction,
  ReprocessCreateSuccessAction,
  ReprocessFindFailureAction,
  ReprocessFindRequestedAction,
  ReprocessFindSuccessAction,
  ReprocessParametersFailureAction,
  ReprocessParametersRequestedAction,
  ReprocessParametersSuccessAction,
} from './types';

export const reprocessParametersRequested = (): ReprocessParametersRequestedAction => ({
  type: ActionType.parametersRequested,
});

export const reprocessParametersSuccess = (
  parameters: ReprocessParameters
): ReprocessParametersSuccessAction => ({
  type: ActionType.parametersSuccess,
  parameters,
});

export const reprocessParametersFailure = (
  error: HttpRequestError
): ReprocessParametersFailureAction => ({
  type: ActionType.parametersFailure,
  error,
});

export const reprocessChangeFilterCountry = (
  countryCode: string
): ReprocessChangeFilterCountryAction => ({
  type: ActionType.reprocessChangeFilterCountry,
  countryCode,
});

export const reprocessChangeFilterStore = (
  storeAcronym: string
): ReprocessChangeFilterStoreAction => ({
  type: ActionType.reprocessChangeFilterStore,
  storeAcronym,
});

export const reprocessChangeFilterFederativeEntity = (
  federativeEntity: string
): ReprocessChangeFilterFederativeEntityAction => ({
  type: ActionType.reprocessChangeFilterFederativeEntity,
  federativeEntity,
});

export const reprocessChangeFilterBusinessDate = (
  businessDate: Date | null
): ReprocessChangeFilterBusinessDateAction => ({
  type: ActionType.reprocessChangeFilterBusinessDate,
  businessDate,
});

export const reprocessChangeFilterStatus = (status: string): ReprocessChangeFilterStatusAction => ({
  type: ActionType.reprocessChangeFilterStatus,
  status,
});

export const reprocessChangeFilterReprocessType = (
  reprocessType: string
): ReprocessChangeFilterReprocessTypeAction => ({
  type: ActionType.reprocessChangeFilterReprocessType,
  reprocessType,
});

export const reprocessFindRequested = (): ReprocessFindRequestedAction => ({
  type: ActionType.reprocessFindRequested,
});

export const reprocessFindSuccess = (reprocess: Reprocess[]): ReprocessFindSuccessAction => ({
  type: ActionType.reprocessFindSuccess,
  reprocess,
});

export const reprocessFindFailure = (error: HttpRequestError): ReprocessFindFailureAction => ({
  type: ActionType.reprocessFindFailure,
  error,
});

export const reprocessCreateSuccess = (): ReprocessCreateSuccessAction => ({
  type: ActionType.reprocessCreateSuccess,
});

export const reprocessCreateFailure = (error: HttpRequestError): ReprocessCreateFailureAction => ({
  type: ActionType.reprocessCreateFailure,
  error,
});
