import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  companyCodesFilterCountryIdSelector,
  companyCodesCompanyCodesLoadingSelector,
  companyCodesFindRequested,
} from '../../state';
import { LoadingButton } from '../buttons';
import { CompanyCodesFilters } from './CompanyCodesFilters';

const ActionContainer = styled.div`
  display: flex;
  justify-content: right;
  > button:first-of-type {
    margin-right: ${props => props.theme.spacing(2)};
  }
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: center;
  }
`;

const ButtonApply = () => {
  const dispatch = useDispatch();
  const handleClickApply = useCallback(() => dispatch(companyCodesFindRequested()), [dispatch]);
  const { t } = useTranslation();
  const loading = useSelector(companyCodesCompanyCodesLoadingSelector);
  const hasCountry = Boolean(useSelector(companyCodesFilterCountryIdSelector));
  const disabled = !hasCountry;
  return (
    <LoadingButton
      color="primary"
      variant="contained"
      onClick={handleClickApply}
      loading={loading}
      disabled={disabled}
    >
      {t('navigation.buttons.apply')}
    </LoadingButton>
  );
};

const CompanyCodesSearch = () => {
  const actions = (
    <ActionContainer>
      <ButtonApply />
    </ActionContainer>
  );
  return <CompanyCodesFilters actions={actions} />;
};

export default CompanyCodesSearch;
