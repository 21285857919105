import { Relief, ReportReliefsGroupBy } from '../../types';
import { commonColProps, DataGridHandler } from '../DataGridFlexSales';

export interface ReliefsDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
  groupBy: ReportReliefsGroupBy[];
}

const initialContext: ReliefsDataGridContext = {
  t: () => '',
  country: '',
  groupBy: [],
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<Relief, ReliefsDataGridContext>(initialContext, locale);

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    visibilityTogglingDisabled: true,
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
    available: ({ groupBy }) => groupBy.includes('storeAcronym'),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
    available: ({ groupBy }) => groupBy.includes('businessDate'),
  });

  handler.addColumn({
    accessor: 'quantity',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('reliefs.columns.quantity'),
  });

  handler.addColumn({
    accessor: 'amount',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('reliefs.columns.amount'),
  });

  handler.addColumn({
    accessor: 'grossSale',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('reliefs.columns.gross_sale'),
  });

  handler.addColumn({
    name: 'percent',
    type: 'percent',
    valueGetter: row => row.amount / row.grossSale,
    total: { type: 'avg', amount: 'amount', quantity: 'grossSale' },
    width: { px: 50, pt: 40 },
    title: () => '%',
  });

  return handler;
};
