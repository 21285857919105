import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  reportExecutePendingInterfacesFilterBusinessDateSelector,
  changeExecutePendingInterfacesFilterBusinessDate,
} from '../../../state';
import DatePickerFlexSales from '../../DatePickerFlexSales';

const BusinessDateFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const value = useSelector(reportExecutePendingInterfacesFilterBusinessDateSelector);

  const handleChange = useCallback(
    (newValue: Date | null) => {
      dispatch(changeExecutePendingInterfacesFilterBusinessDate(newValue));
    },
    [dispatch]
  );

  return (
    <DatePickerFlexSales
      value={value.accept}
      onChange={handleChange}
      label={t('reprocess.labels.business_date')}
    />
  );
};

export default BusinessDateFilter;
