import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useReprocessTypeSelect } from '../../../hooks';
import {
  reprocessChangeFilterReprocessType,
  reprocessFilterReprocessTypeSelector,
} from '../../../state';
import { SimpleSelect, SimpleSelectProps } from '../../selects';

type ReprocessTypeFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const ReprocessTypeFilter = (props: ReprocessTypeFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { options, loading } = useReprocessTypeSelect();
  const value = useSelector(reprocessFilterReprocessTypeSelector);
  const handleChange = useCallback(
    (value: string) => dispatch(reprocessChangeFilterReprocessType(value)),
    [dispatch]
  );

  return (
    <SimpleSelect
      {...props}
      label={t('reprocess.labels.type')}
      onChange={handleChange}
      value={value}
      options={options}
      loading={loading}
    />
  );
};

export default ReprocessTypeFilter;
