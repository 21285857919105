import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

const Container = styled.div`
  background-color: ${props => props.theme.palette.primary.main};
  color: white;
  display: flex;
  justify-content: space-between;
  padding-top: ${props => props.theme.spacing(0.5)};
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
`;

interface TableToolbarProps {
  title: string;
  actions?: ReactNode;
}

const TableToolbar = ({ title, actions }: TableToolbarProps) => (
  <Container>
    <Typography variant="h6" color="inherit">
      {title}
    </Typography>
    {actions && <div>{actions}</div>}
  </Container>
);

export default TableToolbar;
