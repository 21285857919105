import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFilterSalesTypes,
  reportFilterSalesTypesSelector,
  reportSalesTypesSelector,
} from '../../../state';
import { SaleType } from '../../../types';
import { MultiSelect } from '../../selects';

const SalesTypesFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const salesTypesOptionsResource = useSelector(reportSalesTypesSelector);
  const salesTypes = useSelector(reportFilterSalesTypesSelector);
  const disabled = !salesTypesOptionsResource.loaded;
  const salesTypesOptions = salesTypesOptionsResource.data || [];

  const handleChange = useCallback(
    (value: SaleType[]) => dispatch(changeFilterSalesTypes(value)),
    [dispatch]
  );

  return (
    <MultiSelect
      label={t('toolbar.labels.sale-type')}
      options={salesTypesOptions}
      propDesc="saleTypeDescription"
      disabled={disabled}
      value={salesTypes}
      onChange={handleChange}
      loading={salesTypesOptionsResource.loading}
    />
  );
};

export default SalesTypesFilter;
