import { format } from 'date-fns';
import {
  Agreement,
  Company,
  Management,
  Region,
  RegionalManagement,
  ReportFilterGroupBy,
  Sale,
  SaleType,
  StoreExtraInfoParameters,
  Supervision,
  Translation,
} from '../../types';
import { DataGridHandler, commonColProps } from '../DataGridFlexSales';

export interface SalesTotalsDataGridContext {
  saleTypeOptions: SaleType[];
  companies: Company[];
  storeExtraInfo: StoreExtraInfoParameters[];
  regionalManagements: RegionalManagement[];
  regions: Region[];
  managements: Management[];
  supervisions: Supervision[];
  agreements: Agreement[];
  t: Translation;
  country: string;
  groupBy: ReportFilterGroupBy[];
  accountingEntriesFields: boolean;
}

const initialContext: SalesTotalsDataGridContext = {
  saleTypeOptions: [],
  companies: [],
  regionalManagements: [],
  regions: [],
  managements: [],
  supervisions: [],
  agreements: [],
  storeExtraInfo: [],
  t: () => '',
  country: '',
  groupBy: [],
  accountingEntriesFields: false,
};

const ORDER_DIFF = 100;
const COUNTRY_ORDER = 0;
const STORE_ORDER = COUNTRY_ORDER + ORDER_DIFF;
const BUSINESS_DATE_ORDER = STORE_ORDER + ORDER_DIFF;
const BUSINESS_WEEK_DAY_ORDER = BUSINESS_DATE_ORDER + ORDER_DIFF;
const ACCOUNTING_DATE_ORDER = BUSINESS_WEEK_DAY_ORDER + ORDER_DIFF;
const ACCOUNTING_WEEK_DAY_ORDER = ACCOUNTING_DATE_ORDER + ORDER_DIFF;
const OPERATION_DATE_ORDER = ACCOUNTING_WEEK_DAY_ORDER + ORDER_DIFF;
const OPERATION_WEEK_DAY_ORDER = OPERATION_DATE_ORDER + ORDER_DIFF;
const SALE_TYPE_WH_ORDER = OPERATION_WEEK_DAY_ORDER + ORDER_DIFF;
const POS_TYPE_ORDER = SALE_TYPE_WH_ORDER + ORDER_DIFF;
const POS_NUM_ORDER = POS_TYPE_ORDER + ORDER_DIFF;
const TIME_BAND_ORDER = POS_NUM_ORDER + ORDER_DIFF;
const DAY_PART_ORDER = TIME_BAND_ORDER + ORDER_DIFF;
const REGIONAL_MGMT_ID_ORDER = DAY_PART_ORDER + ORDER_DIFF;
const REGION_ID_ORDER = REGIONAL_MGMT_ID_ORDER + ORDER_DIFF;
const MGMT_ID_ORDER = REGION_ID_ORDER + ORDER_DIFF;
const SUPERVISION_ID_ORDER = MGMT_ID_ORDER + ORDER_DIFF;
const AGREEMENT_ID_ORDER = SUPERVISION_ID_ORDER + ORDER_DIFF;
const COMPANY_ID_ORDER = AGREEMENT_ID_ORDER + ORDER_DIFF;
const GCS_ORDER = COMPANY_ID_ORDER + ORDER_DIFF;
const GROSS_SALE_PNP_ORDER = GCS_ORDER + ORDER_DIFF;
const TAX1_PNP_ORDER = GROSS_SALE_PNP_ORDER + ORDER_DIFF;
const TAX2_PNP_ORDER = TAX1_PNP_ORDER + ORDER_DIFF;
const TAX3_PNP_ORDER = TAX2_PNP_ORDER + ORDER_DIFF;
const NET_SALE_PNP_ORDER = TAX3_PNP_ORDER + ORDER_DIFF;
const NET_SALE_FINAL_PNP_ORDER = NET_SALE_PNP_ORDER + ORDER_DIFF;
const GCS_AVG_ORDER = NET_SALE_FINAL_PNP_ORDER + ORDER_DIFF;
const GROSS_SALE_P_ORDER = GCS_AVG_ORDER + ORDER_DIFF;
const TAX1_P_ORDER = GROSS_SALE_P_ORDER + ORDER_DIFF;
const TAX2_P_ORDER = TAX1_P_ORDER + ORDER_DIFF;
const TAX3_P_ORDER = TAX2_P_ORDER + ORDER_DIFF;
const NET_SALE_P_ORDER = TAX3_P_ORDER + ORDER_DIFF;
const NET_SALE_FINAL_P_ORDER = NET_SALE_P_ORDER + ORDER_DIFF;
const GROSS_SALE_NP_ORDER = NET_SALE_FINAL_P_ORDER + ORDER_DIFF;
const TAX1_NP_ORDER = GROSS_SALE_NP_ORDER + ORDER_DIFF;
const TAX2_NP_ORDER = TAX1_NP_ORDER + ORDER_DIFF;
const TAX3_NP_ORDER = TAX2_NP_ORDER + ORDER_DIFF;
const NET_SALE_NP_ORDER = TAX3_NP_ORDER + ORDER_DIFF;
const NET_SALE_FINAL_NP_ORDER = NET_SALE_NP_ORDER + ORDER_DIFF;
const GROSS_SALE_THIRD_PARTY_ORDER = NET_SALE_FINAL_NP_ORDER + ORDER_DIFF;
const TAX1_THIRD_PARTY_ORDER = GROSS_SALE_THIRD_PARTY_ORDER + ORDER_DIFF;
const TAX2_THIRD_PARTY_ORDER = TAX1_THIRD_PARTY_ORDER + ORDER_DIFF;
const TAX3_THIRD_PARTY_ORDER = TAX2_THIRD_PARTY_ORDER + ORDER_DIFF;
const NET_SALE_THIRD_PARTY_ORDER = TAX3_THIRD_PARTY_ORDER + ORDER_DIFF;
const GROSS_SALE_POS_ORDER = NET_SALE_THIRD_PARTY_ORDER + ORDER_DIFF;
const TAX1_POS_ORDER = GROSS_SALE_POS_ORDER + ORDER_DIFF;
const NET_SALE_POS_ORDER = TAX1_POS_ORDER + ORDER_DIFF;
const TAX2_POS_ORDER = NET_SALE_POS_ORDER + ORDER_DIFF;
const TAX3_POS_ORDER = TAX2_POS_ORDER + ORDER_DIFF;
const NET_SALE_FINAL_POS_ORDER = TAX3_POS_ORDER + ORDER_DIFF;
const DISCOUNT_SALE_POS = NET_SALE_FINAL_POS_ORDER + ORDER_DIFF;

export default (locale: Locale) => {
  const handler = new DataGridHandler<Sale, SalesTotalsDataGridContext>(initialContext, locale);
  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    visibilityTogglingDisabled: true,
    ...commonColProps.country,
    order: COUNTRY_ORDER,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
    available: ({ groupBy }) => groupBy.includes('storeAcronym'),
    order: STORE_ORDER,
  });

  handler.addColumn({
    name: 'accountingStoreId',
    type: 'string',
    title: ({ t }) => t('table.columns.accounting_store_id'),
    valueGetter: (row, { storeExtraInfo }) =>
      storeExtraInfo.find(i => Number(i.storeId) === Number(row.accountingStoreId))?.name,
    width: { px: 350, pt: 335 },
    available: ({ country, groupBy }) => ['BR'].includes(country) && groupBy.includes('podPod'),
    order: STORE_ORDER + 1,
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
    available: ({ groupBy }) => groupBy.includes('businessDate'),
    order: BUSINESS_DATE_ORDER,
  });

  handler.addColumn({
    name: 'businessWeekDay',
    type: 'string',
    title: ({ t }) => t('table.columns.week_day'),
    valueGetter: row =>
      row.businessWeekDay ? format(row.businessWeekDay, 'EEEE' || 'P', { locale }) : undefined,
    available: ({ groupBy }) =>
      groupBy.includes('businessDate') &&
      !groupBy.includes('accountingDate') &&
      !groupBy.includes('operationDate'),
    order: BUSINESS_WEEK_DAY_ORDER,
  });

  handler.addColumn({
    accessor: 'accountingDate',
    type: 'date',
    title: ({ t }) => t('table.columns.accounting_date'),
    available: ({ groupBy }) => groupBy.includes('accountingDate'),
    order: ACCOUNTING_DATE_ORDER,
  });

  handler.addColumn({
    name: 'accountingWeekDay',
    type: 'string',
    title: ({ t }) => t('table.columns.week_day'),
    valueGetter: row =>
      row.accountingDate ? format(row.accountingDate, 'EEEE' || 'P', { locale }) : undefined,
    available: ({ groupBy }) =>
      groupBy.includes('accountingDate') &&
      !groupBy.includes('businessDate') &&
      !groupBy.includes('operationDate'),
    order: ACCOUNTING_WEEK_DAY_ORDER,
  });

  handler.addColumn({
    accessor: 'operationDate',
    type: 'date',
    title: ({ t }) => t('table.columns.operation_date'),
    available: ({ groupBy }) => groupBy.includes('operationDate'),
    order: OPERATION_DATE_ORDER,
  });

  handler.addColumn({
    title: ({ t }) => t('table.columns.week_day'),
    type: 'string',
    name: 'operationWeekDay',
    valueGetter: row =>
      row.operationWeekDay ? format(row.operationWeekDay, 'EEEE' || 'P', { locale }) : undefined,
    available: ({ groupBy }) =>
      groupBy.includes('operationDate') &&
      !groupBy.includes('businessDate') &&
      !groupBy.includes('accountingDate'),
    order: OPERATION_WEEK_DAY_ORDER,
  });

  handler.addColumn({
    name: 'saleTypeWh',
    type: 'string',
    title: ({ t }) => t('table.columns.sale_type_wh'),
    valueGetter: (row, context) => {
      if (!row.saleTypeWh) return '-';
      return context.saleTypeOptions.find(i => Number(i.saleTypeId) === Number(row.saleTypeWh))
        ?.saleTypeDescription;
    },
    available: ({ groupBy }) => groupBy.includes('saleType'),
    order: SALE_TYPE_WH_ORDER,
  });

  handler.addColumn({
    accessor: 'posType',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_type'),
    available: ({ groupBy }) => groupBy.includes('posType'),
    order: POS_TYPE_ORDER,
  });

  handler.addColumn({
    accessor: 'posNum',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_num'),
    available: ({ groupBy }) => groupBy.includes('posNum'),
    order: POS_NUM_ORDER,
  });

  handler.addColumn({
    accessor: 'timeBand',
    type: 'string',
    title: ({ t }) => t('table.columns.time_band'),
    available: ({ groupBy }) => groupBy.includes('timeBand'),
    order: TIME_BAND_ORDER,
  });

  handler.addColumn({
    accessor: 'dayPart',
    type: 'string',
    title: ({ t }) => t('table.columns.day_part'),
    available: ({ groupBy }) => groupBy.includes('timeRange'),
    order: DAY_PART_ORDER,
  });

  handler.addColumn({
    name: 'regionalMgmtId',
    title: ({ t }) => t('table.columns.regional_mgmt_id'),
    type: 'string',
    valueGetter: (row, { regionalManagements }) =>
      regionalManagements.find(i => i.id === row.regionalMgmtId)?.label,
    available: ({ groupBy }) => groupBy.includes('regionalMgmtId'),
    order: REGIONAL_MGMT_ID_ORDER,
  });

  handler.addColumn({
    name: 'regionId',
    type: 'string',
    title: ({ t }) => t('table.columns.region_id'),
    valueGetter: (row, { regions }) => regions.find(i => i.id === row.regionId)?.label,
    available: ({ groupBy }) => groupBy.includes('regionId'),
    order: REGION_ID_ORDER,
  });

  handler.addColumn({
    name: 'mgmtId',
    type: 'string',
    title: ({ t }) => t('table.columns.mgmt_id'),
    valueGetter: (row, { managements }) => managements.find(i => i.id === row.mgmtId)?.label,
    available: ({ groupBy }) => groupBy.includes('mgmtId'),
    order: MGMT_ID_ORDER,
  });

  handler.addColumn({
    name: 'supervisionId',
    type: 'string',
    title: ({ t }) => t('table.columns.supervision_id'),
    valueGetter: (row, { supervisions }) =>
      supervisions.find(i => i.id === row.supervisionId)?.label,
    available: ({ groupBy }) => groupBy.includes('supervisionId'),
    order: SUPERVISION_ID_ORDER,
  });

  handler.addColumn({
    name: 'agreementId',
    type: 'string',
    title: ({ t }) => t('table.columns.agreement_id'),
    valueGetter: (row, { agreements }) => agreements.find(i => i.id === row.agreementId)?.label,
    available: ({ groupBy }) => groupBy.includes('agreementId'),
    order: AGREEMENT_ID_ORDER,
  });

  handler.addColumn({
    name: 'companyId',
    type: 'string',
    title: ({ t }) => t('table.columns.company_id'),
    valueGetter: (row, { companies }) =>
      companies.find(i => Number(i.companyId) === Number(row.companyId))?.companyName,
    available: ({ groupBy }) => groupBy.includes('companyId'),
    order: COMPANY_ID_ORDER,
  });

  handler.addColumn({
    accessor: 'gcs',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.gcs'),
    order: GCS_ORDER,
  });

  // Venta P+NP Bruta (ARG)
  handler.addColumn({
    accessor: 'grossSalePnp',
    total: { type: 'sum' },
    type: 'number',
    title: ({ t }) => t('sale-totals.columns.grossSalePnp'),
    order: GROSS_SALE_PNP_ORDER,
  });

  // RESTO: t('sale-totals.columns.tax1Pnp')
  handler.addColumn({
    accessor: 'tax1Pnp',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) =>
      t(`sale-totals.columns.${country}.tax1Pnp`, t('sale-totals.columns.tax1Pnp')),
    order: TAX1_PNP_ORDER,
  });

  // IIBB P+NP (ARG)
  handler.addColumn({
    accessor: 'tax2Pnp',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax2Pnp`),
    available: ({ country }) => ['AR', 'PR', 'BR', 'PE', 'GP'].includes(country),
    order: TAX2_PNP_ORDER,
  });

  handler.addColumn({
    accessor: 'tax3Pnp',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax3Pnp`),
    available: ({ country }) => ['PR', 'BR'].includes(country),
    order: TAX3_PNP_ORDER,
  });

  // Venta P+NP Neta (ARG)
  handler.addColumn({
    accessor: 'netSaleFinalPnp',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.netSaleFinalPnp'),
    available: ({ country }) => !['PR', 'BR'].includes(country),
    order: NET_SALE_FINAL_PNP_ORDER,
  });

  // Venta P+NP sin IVA (ARG)
  handler.addColumn({
    accessor: 'netSalePnp',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.netSalePnp`),
    available: ({ country }) => ['AR', 'PR', 'BR'].includes(country),
    order: NET_SALE_PNP_ORDER,
  });

  handler.addColumn({
    accessor: 'gcsAvg',
    type: 'number',
    total: { type: 'avg', amount: 'netSaleFinalPnp', quantity: 'gcs' },
    title: ({ t }) => t('sale-totals.columns.gcsAvg'),
    order: GCS_AVG_ORDER,
  });

  handler.addColumn({
    accessor: 'grossSaleP',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.grossSaleP'),
    order: GROSS_SALE_P_ORDER,
  });

  handler.addColumn({
    accessor: 'tax1P',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) =>
      t(`sale-totals.columns.${country}.tax1P`, t('sale-totals.columns.tax1P')),
    order: TAX1_P_ORDER,
  });

  handler.addColumn({
    accessor: 'tax2P',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax2P`),
    available: ({ country }) => ['AR', 'PR', 'BR', 'PE', 'GP'].includes(country),
    order: TAX2_P_ORDER,
  });

  handler.addColumn({
    accessor: 'tax3P',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax3P`),
    available: ({ country }) => ['PR', 'BR'].includes(country),
    order: TAX3_P_ORDER,
  });

  handler.addColumn({
    accessor: 'netSaleP',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.netSaleP`),
    available: ({ country }) => ['AR', 'PR', 'BR'].includes(country),
    order: NET_SALE_P_ORDER,
  });

  handler.addColumn({
    accessor: 'netSaleFinalP',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.netSaleFinalP'),
    available: ({ country }) => !['PR', 'BR'].includes(country),
    order: NET_SALE_FINAL_P_ORDER,
  });

  handler.addColumn({
    accessor: 'grossSaleNp',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.grossSaleNp'),
    order: GROSS_SALE_NP_ORDER,
  });

  handler.addColumn({
    accessor: 'tax1Np',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) =>
      t(`sale-totals.columns.${country}.tax1Np`, t('sale-totals.columns.tax1Np')),
    order: TAX1_NP_ORDER,
  });

  handler.addColumn({
    accessor: 'tax2Np',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax2Np`),
    available: ({ country }) => ['AR', 'PR', 'BR', 'PE', 'GP'].includes(country),
    order: TAX2_NP_ORDER,
  });

  handler.addColumn({
    accessor: 'tax3Np',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax3Np`),
    available: ({ country }) => ['PR', 'BR'].includes(country),
    order: TAX3_NP_ORDER,
  });

  handler.addColumn({
    accessor: 'netSaleNp',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.netSaleNp`),
    available: ({ country }) => ['AR', 'PR', 'BR'].includes(country),
    order: NET_SALE_NP_ORDER,
  });

  handler.addColumn({
    accessor: 'netSaleFinalNp',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.netSaleFinalNp'),
    available: ({ country }) => !['PR', 'BR'].includes(country),
    order: NET_SALE_FINAL_NP_ORDER,
  });

  handler.addColumn({
    accessor: 'grossSaleThirdParty',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.grossSaleThirdParty'),
    available: ({ country }) => !['BR'].includes(country),
    order: GROSS_SALE_THIRD_PARTY_ORDER,
  });

  handler.addColumn({
    accessor: 'tax1ThirdParty',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) =>
      t(`sale-totals.columns.${country}.tax1ThirdParty`, t('sale-totals.columns.tax1ThirdParty')),
    available: ({ country }) => !['BR'].includes(country),
    order: TAX1_THIRD_PARTY_ORDER,
  });

  handler.addColumn({
    accessor: 'tax2ThirdParty',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax2ThirdParty`),
    available: ({ country }) => ['PR'].includes(country),
    order: TAX2_THIRD_PARTY_ORDER,
  });

  handler.addColumn({
    accessor: 'tax3ThirdParty',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax3ThirdParty`),
    available: ({ country }) => ['PR'].includes(country),
    order: TAX3_THIRD_PARTY_ORDER,
  });

  handler.addColumn({
    accessor: 'netSaleThirdParty',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.netSaleThirdParty'),
    available: ({ country }) => !['BR'].includes(country),
    order: NET_SALE_THIRD_PARTY_ORDER,
  });

  handler.addColumn({
    accessor: 'grossSalePos',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.grossSalePos'),
    available: ({ country }) => !['BR'].includes(country),
    order: GROSS_SALE_POS_ORDER,
  });

  handler.addColumn({
    accessor: 'tax1Pos',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) =>
      t(`sale-totals.columns.${country}.tax1Pos`, t('sale-totals.columns.tax1Pos')),
    available: ({ country }) => !['BR'].includes(country),
    order: TAX1_POS_ORDER,
  });

  handler.addColumn({
    accessor: 'netSalePos',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) =>
      t(`sale-totals.columns.${country}.netSalePos`, t('sale-totals.columns.netSalePos')),
    available: ({ country }) => ['AR'].includes(country),
    order: NET_SALE_POS_ORDER,
  });

  handler.addColumn({
    accessor: 'tax2Pos',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax2Pos`),
    available: ({ country }) => ['AR', 'PR', 'PE', 'GP'].includes(country),
    order: TAX2_POS_ORDER,
  });

  handler.addColumn({
    accessor: 'tax3Pos',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.tax3Pos`),
    available: ({ country }) => ['PR'].includes(country),
    order: TAX3_POS_ORDER,
  });

  handler.addColumn({
    accessor: 'netSaleFinalPos',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.netSaleFinalPos'),
    available: ({ country }) => !['BR'].includes(country),
    order: NET_SALE_FINAL_POS_ORDER,
  });

  handler.addColumn({
    accessor: 'discountSalePos',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t, country }) => t(`sale-totals.columns.${country}.discountSalePos`),
    available: ({ country }) => ['BR'].includes(country),
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'caixaMapSales',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.caixaMapSales'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'aliquot0Sales',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.aliquot0Sales'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'loyalty',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.loyalty'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'icms',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.icms'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'pis',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.pis'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'cofins',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.cofins'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'brindesSalesHmb',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.brindesSalesHmb'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'icmsBrindesHmb',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.icmsBrindesHmb'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'pisBrindesHmb',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.pisBrindesHmb'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'cofinsBrindesHmb',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.cofinsBrindesHmb'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'deliveryRate',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.deliveryRate'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'icmsBrindesT',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.icmsBrindesT'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'pisBrindesT',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.pisBrindesT'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'cofinsBrindesT',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.cofinsBrindesT'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'brindesSalesV',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.brindesSalesV'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'icmsBrindesV',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.icmsBrindesV'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'pisBrindesV',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.pisBrindesV'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  handler.addColumn({
    accessor: 'cofinsBrindesV',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sale-totals.columns.cofinsBrindesV'),
    available: ({ country, accountingEntriesFields }) =>
      ['BR'].includes(country) && accountingEntriesFields,
    order: DISCOUNT_SALE_POS,
  });

  return handler;
};
