import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFilterAccountingEntriesFields,
  reportFilterAccountingEntriesFieldsSelector,
  reportFilterCountryCodeSelector,
} from '../state';

const SalesTotalsSwitch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const country = useSelector(reportFilterCountryCodeSelector);
  const checked = useSelector(reportFilterAccountingEntriesFieldsSelector);
  const handleClick = () => dispatch(changeFilterAccountingEntriesFields());

  return country === 'BR' ? (
    <FormGroup>
      <FormControlLabel
        onClick={handleClick}
        control={<Switch checked={checked} />}
        label={t('sale-totals.labels.accountingInformation')}
      />
    </FormGroup>
  ) : null;
};

export default SalesTotalsSwitch;
