import { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import { AppPermissions } from '../../../types';
import { PermissionsProvider } from '../../Permissions';
import { DivisionCountryFilter } from '../../filters';
import {
  accountingParametersChangeCountry,
  accountingParametersCountrySelector,
} from '../../../state';

export interface AccountingEntriesFiltersProps {
  actions: ReactNode;
}

const resourcePermissions = [AppPermissions.ACCOUNTING_PARAMETERS_QUERY];

const AccountingEntriesFilters = ({ actions }: AccountingEntriesFiltersProps) => (
  <PermissionsProvider resourcePermissions={resourcePermissions}>
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12} md={4} lg={2} xl={2}>
        <DivisionCountryFilter
          valueSelector={accountingParametersCountrySelector}
          actionChange={accountingParametersChangeCountry}
          fullWidth
          disableClearable
        />
      </Grid>
      <Grid item xs={12} md lg xl>
        {actions}
      </Grid>
    </Grid>
  </PermissionsProvider>
);

export default AccountingEntriesFilters;
