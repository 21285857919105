import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { msalSigninSuccess } from '../state';
import { loginRequest } from '../config/auth.config';

const MsalReduxIntegration = () => {
  const { accounts, instance } = useMsal();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const account = isAuthenticated ? accounts[0] : undefined;
  const username = account?.username;
  const name = account?.name;

  useEffect(() => {
    instance.initialize().then(() => {
      if (isAuthenticated) {
        dispatch(msalSigninSuccess(name as string, username as string));
      } else {
        instance.ssoSilent(loginRequest).then(response => {
          instance.setActiveAccount(response.account);
        });
      }
    });
  }, [username, name, dispatch, instance, isAuthenticated]);

  return null;
};

export default MsalReduxIntegration;
