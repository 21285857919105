import StoreSimpleSelect, { StoreSimpleSelectProps } from './StoreSimpleSelect';
import StoreMultiSelect, { StoreMultiSelectProps } from './StoreMultiSelect';

type StoreSelectProps =
  | (StoreMultiSelectProps & { simple?: false })
  | (StoreSimpleSelectProps & { simple: true });

const StoreSelect = (props: StoreSelectProps) => {
  const { simple, ...others } = props;
  if (simple) {
    return <StoreSimpleSelect {...(others as StoreSimpleSelectProps)} />;
  }
  return <StoreMultiSelect {...(others as StoreMultiSelectProps)} />;
};

export default StoreSelect;
