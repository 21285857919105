import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverOrigin } from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import routes from '../../config/routes';
import { useIsCurrentPath } from '../../hooks';

const idButton = 'header-options-button';
const idMenu = 'header-options-menu';
const menuListProps = {
  'aria-labelledby': idButton,
};

const popoverOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

type ButtonSettingProps = { handleClose: () => void };

const ButtonSetting = ({ handleClose }: ButtonSettingProps) => {
  const { t } = useTranslation();
  const showSettings = !useIsCurrentPath(routes.protectedRoutes.profile.path);
  return showSettings ? (
    <MenuItem component={Link} to={routes.protectedRoutes.profile.path} onClick={handleClose}>
      {t('layout.header.menu.settings')}
    </MenuItem>
  ) : null;
};

const MenuHeader = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick: IconButtonProps['onClick'] = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleClickLogout = () => {
    handleClose();
    instance.logoutRedirect();
  };

  return (
    <>
      <IconButton
        id={idButton}
        aria-label="more"
        aria-controls={open ? idMenu : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={idMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={menuListProps}
        anchorOrigin={popoverOrigin}
        transformOrigin={popoverOrigin}
      >
        <ButtonSetting handleClose={handleClose} />
        <MenuItem onClick={handleClickLogout}>{t('layout.header.menu.logout')}</MenuItem>
      </Menu>
    </>
  );
};

export default MenuHeader;
