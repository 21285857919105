import styled from '@emotion/styled';
import { GcsByPaymentTypeDataGrid, GcsPaymentTypesSearch, GcsSwitch } from '../components';
import FiltersLayoutHandler from '../components/FiltersLayoutHandler';
import { reportGcsPaymentTypesLoadingSelector } from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const GcsPaymentTypesSearchContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const GcsPaymentTypes = () => (
  <Container>
    <GcsSwitch checked />
    <GcsPaymentTypesSearchContainer>
      <FiltersLayoutHandler selector={reportGcsPaymentTypesLoadingSelector}>
        <GcsPaymentTypesSearch />
      </FiltersLayoutHandler>
    </GcsPaymentTypesSearchContainer>
    <GcsByPaymentTypeDataGrid />
  </Container>
);

export default GcsPaymentTypes;
