import { useEffect } from 'react';
import { useFederativeEntitySelect } from '../../../hooks';
import SimpleSelect, { SimpleSelectProps } from '../SimpleSelect';

export interface FederativeEntitySimpleSelectProps extends Omit<SimpleSelectProps, 'options'> {
  countryCode: string;
  emptySelection?: string;
}

const FederativeEntitySimpleSelect = ({
  disabled,
  countryCode,
  emptySelection,
  onChange,
  ...others
}: FederativeEntitySimpleSelectProps) => {
  const {
    loading,
    disabled: disabledSelect,
    options,
  } = useFederativeEntitySelect(countryCode, emptySelection);
  const isDisabled = disabled || disabledSelect;

  useEffect(() => {
    if (options.length === 1) onChange(options[0].id);
  }, [onChange, options]);
  return (
    <SimpleSelect
      {...others}
      options={options}
      disabled={isDisabled}
      hidden={isDisabled}
      loading={loading}
      onChange={onChange}
    />
  );
};

export default FederativeEntitySimpleSelect;
