import { DailySale, DelayedStores, RemoteResource } from '../../../../../types';
import { commonColProps, DataGridHandler } from '../../../../DataGridFlexSales';
import DelayedStoresCell from './DelayedStoresCell';

export interface DailySalesDataGridContext {
  t: (key: string | string[]) => string;
  delayedStores: RemoteResource<DelayedStores[]>;
}

const initialContext: DailySalesDataGridContext = {
  t: () => '',
  delayedStores: { loading: true, loaded: false, error: null, data: [], failure: false },
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<DailySale, DailySalesDataGridContext>(initialContext, locale);

  handler.addColumn({
    name: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    visibilityTogglingDisabled: true,
    valueGetter: (row, { t }) => t(`operativeStructure.countries.${row.country}`),
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'gcs',
    type: 'number',
    title: ({ t }) => t('table.columns.gcs'),
  });

  handler.addColumn({
    accessor: 'posNetSale',
    type: 'number',
    title: ({ t }) => t('dashboard.daily-sale.columns.pos-net-sale'),
  });

  handler.addColumn({
    name: 'delayedStores',
    type: 'number',
    title: ({ t }) => t('dashboard.daily-sale.columns.delayed-stores'),
    valueGetter: row => row.delayedStores,
    renderCell: (_value, row, { delayedStores }) => (
      <DelayedStoresCell country={row.country} delayedStores={delayedStores} locale={locale} />
    ),
  });

  return handler;
};
