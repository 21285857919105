import { HttpRequestError, Country, OakBrook } from '../../types';
import {
  ActionType,
  OakBrookChangeFilterCountryAction,
  OakBrookChangeFilterMonthAction,
  OakBrookDownloadRequestedAction,
  OakBrookDownloadFinishedAction,
  OakBrookFindFailureAction,
  OakBrookFindRequestedAction,
  OakBrookFindSuccessAction,
} from './types';

export const oakBrookChangeFilterCountry = (
  country: Country | null
): OakBrookChangeFilterCountryAction => ({
  type: ActionType.oakBrookChangeFilterCountry,
  country,
});

export const oakBrookChangeFilterMonth = (month: Date | null): OakBrookChangeFilterMonthAction => ({
  type: ActionType.oakBrookChangeFilterMonth,
  month,
});

export const oakBrookFindRequested = (): OakBrookFindRequestedAction => ({
  type: ActionType.oakBrookFindRequested,
});

export const oakBrookFindSuccess = (oakBrook: OakBrook[]): OakBrookFindSuccessAction => ({
  type: ActionType.oakBrookFindSuccess,
  oakBrook,
});

export const oakBrookFindFailure = (error: HttpRequestError): OakBrookFindFailureAction => ({
  type: ActionType.oakBrookFindFailure,
  error,
});

export const oakBrookDownloadRequested = (): OakBrookDownloadRequestedAction => ({
  type: ActionType.oakBrookDownloadRequested,
});

export const oakBrookDownloadFinished = (): OakBrookDownloadFinishedAction => ({
  type: ActionType.oakBrookDownloadFinished,
});
