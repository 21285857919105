import { HttpRequestError, ValidationError } from '../../types';
import {
  ActionType,
  HttpErrorAction,
  ClearHttpErrorAction,
  ClearValidationErrorsAction,
  ValidationErrorsAction,
} from './types';

export const httpError = (httpError: HttpRequestError): HttpErrorAction => ({
  type: ActionType.httpError,
  httpError,
});

export const clearHttpError = (): ClearHttpErrorAction => ({
  type: ActionType.clearHttpError,
});

export const validationErrors = (errors: ValidationError[]): ValidationErrorsAction => ({
  type: ActionType.validationErrors,
  errors,
});

export const clearValidationErrors = (): ClearValidationErrorsAction => ({
  type: ActionType.clearValidationErrors,
});
