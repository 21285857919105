import { format } from 'date-fns';
import { t } from 'i18next';
import { DelayedStores } from '../../../../../types';

interface TooltipTitleProps {
  delayedStores: DelayedStores[];
  locale: Locale;
}

const TooltipTitle = ({ delayedStores, locale }: TooltipTitleProps) => (
  <span style={{ whiteSpace: 'pre-line' }}>
    {`${t('dashboard.daily-sales.delayed-stores-toolbar')}\n`}
    {delayedStores.map(i => `${i.store} ${format(i.lastSale, 'P HH:mm', { locale })}\n`)}
  </span>
);

export default TooltipTitle;
