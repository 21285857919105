import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PopupRequest,
  PublicClientApplication,
} from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    authority: process.env.AUTHORITY as string,
    clientId: process.env.CLIENT_ID as string,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const scopes = (process.env.SCOPE || '').split(',');

export const loginRequest: PopupRequest = {
  scopes,
};

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});
