import styled from '@emotion/styled';
import { SalesByProductDataGrid, SalesByProductSearch } from '../components';
import FiltersLayoutHandler from '../components/FiltersLayoutHandler';
import { reportSalesByProductLoadingSelector } from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const SalesByProducto = () => (
  <Container>
    <FiltersLayoutHandler selector={reportSalesByProductLoadingSelector}>
      <SalesByProductSearch />
    </FiltersLayoutHandler>
    <SalesByProductDataGrid />
  </Container>
);

export default SalesByProducto;
