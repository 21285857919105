import styled from '@emotion/styled';
import { SalesTotalsDataGrid, SalesTotalsSearch, SalesTotalsSwitch } from '../components';
import FiltersLayoutHandler from '../components/FiltersLayoutHandler';
import { reportSalesLoadingSelector } from '../state/selectors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .MuiToolbar-root {
    margin-top: ${props => props.theme.spacing(3)};
  }
`;

const SaleTotals = () => (
  <Container>
    <SalesTotalsSwitch />
    <FiltersLayoutHandler selector={reportSalesLoadingSelector}>
      <SalesTotalsSearch />
    </FiltersLayoutHandler>
    <SalesTotalsDataGrid />
  </Container>
);

export default SaleTotals;
