import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  Grid,
  TableColumnVisibility,
  TableHeaderRow,
  TableSummaryRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  CustomSummary,
  IntegratedSorting,
  SortingState,
  SortingStateProps,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  buidTotalSummaryItems,
  buildActions,
  buildColumnExtensions,
  buildColumns,
  buildRows,
  buildSorting,
} from './adapter';
import { useDataGridFlexSales } from './DataGridFlexSalesProvider';
import { Row } from './DataGridHandler';
import {
  ESTIMATED_ROW_HEIGHT,
  GridRoot,
  VirtualTabledRow,
  TableHeaderRowRow,
  VirtualTableNoDataCell,
  TableSummaryRowTotalCell,
  VirtualTableCell,
  TableHeaderRowCell,
  ActionColumns,
} from './devExpressCustom';

const DataGridContainer = styled.div`
  height: 100%;
  background-color: ${props => props.theme.palette.background.paper};
  overflow: auto;
`;

const totalValues: number[] = []; // No se utiliza, pero el componente CustomSummary requiere que se pase dicho valor

const DataGrid = () => {
  const { handler } = useDataGridFlexSales();
  const [columnsHidden, setColumnsHidden] = useState([...handler.columnsHidden]);
  const [rows, setRows] = useState(buildRows(handler.getRows()));
  const [columns, setColumns] = useState(buildColumns(handler.columns, handler.actions));
  const [totalSummaryItems, setTotalSummaryItems] = useState(buidTotalSummaryItems(handler.totals));
  const [sorting, setSorting] = useState(buildSorting(handler.sorting));
  const [tableColumnExtensions, setTableColumnExtensions] = useState(
    buildColumnExtensions(handler.columns)
  );

  useEffect(() => {
    const refreshRows = (rows: Row<unknown>[]) => {
      setRows(buildRows(rows));
      setTotalSummaryItems(buidTotalSummaryItems(handler.totals));
    };

    const listenerColumnsHidden = handler.addEventListener('columnsHidden', ({ columnsHidden }) =>
      setColumnsHidden(columnsHidden)
    );

    const listenerContext = handler.addEventListener('context', ({ rows, columns }) => {
      setColumns(buildColumns(columns, handler.actions));
      setTableColumnExtensions(buildColumnExtensions(columns));
      refreshRows(rows);
    });

    const listenerColumnConfig = handler.addEventListener('columnsConfig', ({ rows, columns }) => {
      setColumns(buildColumns(columns, handler.actions));
      setTableColumnExtensions(buildColumnExtensions(columns));
      refreshRows(rows);
    });

    const listenerRows = handler.addEventListener('rows', ({ rows }) => refreshRows(rows));

    return () => {
      listenerColumnsHidden.dispose();
      listenerContext.dispose();
      listenerRows.dispose();
      listenerColumnConfig.dispose();
    };
  }, [handler]);

  const sortingChangeHandle = useCallback<NonNullable<SortingStateProps['onSortingChange']>>(
    ([item]) => {
      handler.setSorting(item.columnName, item.direction === 'asc');
      setSorting([item]);
    },
    [handler]
  );

  const actions = useMemo(() => buildActions(handler.actions), [handler]);

  return (
    <DataGridContainer>
      <Grid rows={rows} columns={columns} rootComponent={GridRoot}>
        <SummaryState totalItems={totalSummaryItems} />
        <SortingState sorting={sorting} onSortingChange={sortingChangeHandle} />
        <IntegratedSorting />
        <CustomSummary totalValues={totalValues} />
        <VirtualTable
          height="100%"
          estimatedRowHeight={ESTIMATED_ROW_HEIGHT}
          cellComponent={VirtualTableCell}
          rowComponent={VirtualTabledRow}
          columnExtensions={tableColumnExtensions}
          noDataCellComponent={VirtualTableNoDataCell}
        />
        <TableHeaderRow
          cellComponent={TableHeaderRowCell}
          rowComponent={TableHeaderRowRow}
          showSortingControls
        />
        <TableColumnVisibility hiddenColumnNames={columnsHidden} />
        <TableSummaryRow totalCellComponent={TableSummaryRowTotalCell} />
        <ActionColumns actions={actions} />
      </Grid>
    </DataGridContainer>
  );
};

export default DataGrid;
