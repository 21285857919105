interface EventType {
  type: string;
}

export class EventHandler<TEvent extends EventType> {
  #listeners: Partial<Record<string, Set<(e: TEvent) => void>>> = {};

  addListener(eventType: string, listener: (event: EventType) => void) {
    if (!this.#listeners[eventType]) this.#listeners[eventType] = new Set();
    this.#listeners[eventType]?.add(listener);
    return {
      dispose: () => this.#listeners[eventType]?.delete(listener),
    };
  }

  notify(event: TEvent) {
    setTimeout(() => this.#listeners[event.type]?.forEach(listener => listener(event)));
  }
}
