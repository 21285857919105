import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { executePendingInterfacesParametersRequested } from '../state';
import { ExecutePendingInterfacesDataGrid, ExecutePendingInterfacesSearch } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const ExecutePendingInterfaces = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(executePendingInterfacesParametersRequested());
  }, [dispatch]);
  return (
    <>
      <Container>
        <ExecutePendingInterfacesSearch />
        <ExecutePendingInterfacesDataGrid />
      </Container>
      <Outlet />
    </>
  );
};

export default ExecutePendingInterfaces;
