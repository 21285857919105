import { Actions, State, ActionType } from './types';

const initialState: State = {
  queue: [],
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.enqueueNotification:
      return {
        ...state,
        queue: Array.isArray(action.notification)
          ? [...state.queue, ...action.notification]
          : [...state.queue, action.notification],
      };
    case ActionType.dequeueNotification:
      return {
        ...state,
        queue: Array.isArray(action.notificationId)
          ? state.queue.filter(i => !action.notificationId.includes(i.key))
          : state.queue.filter(i => i.key !== action.notificationId),
      };
    default:
      return state;
  }
};

export default reducer;
