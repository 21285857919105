import { useTranslation } from 'react-i18next';
import SimpleSelectFormInput, { SimpleSelectFormInputProps } from './SimpleSelectFormInput';
import { useStoreSelect } from '../../hooks';

export type StoreSelectFormInputProps = Omit<SimpleSelectFormInputProps, 'options' | 'value'> & {
  countryCode: string;
};

const StoreSelectFormInput = ({ disabled, countryCode, ...rest }: StoreSelectFormInputProps) => {
  const { t } = useTranslation();
  const { options, loading, disabled: disabledSelect } = useStoreSelect(countryCode);
  const isDisabled = disabledSelect || disabled;

  return (
    <SimpleSelectFormInput
      {...rest}
      label={t('toolbar.labels.store')}
      options={options}
      disabled={isDisabled}
      loading={loading}
    />
  );
};

export default StoreSelectFormInput;
