import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { hasPermissions } from '../../helpers';
import { AccountingEntry, AccountingEntryStatus, AppPermissions } from '../../types';
import { commonColProps, DataFlexSalesActionButton, DataGridHandler } from '../DataGridFlexSales';

import {
  accountingEntriesChangeDelete,
  accountingEntriesDownloadingReportIdSelector,
  accountingEntriesReportRequested,
} from '../../state';

export interface AccountingEntryDataGridContext {
  t: (key: string | string[]) => string;
  userPermissions: string[];
}

const initialContext: AccountingEntryDataGridContext = {
  t: () => '',
  userPermissions: [],
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<AccountingEntry, AccountingEntryDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'countryId',
    type: 'string',
    title: ({ t }) => t('accountingEntries.columns.country'),
    ...commonColProps.countryId,
  });
  handler.addColumn({
    accessor: 'accountingMonth',
    type: 'date',
    title: ({ t }) => t('accountingEntries.columns.accountingMonth'),
    format: 'MMM-yy',
    align: 'left',
    width: { px: 150, pt: 120 },
  });
  handler.addColumn({
    accessor: 'journalType',
    type: 'string',
    title: ({ t }) => t('accountingEntries.columns.journalType'),
    width: { px: 200, pt: 170 },
  });
  handler.addColumn({
    accessor: 'createdDate',
    type: 'date',
    title: ({ t }) => t('accountingEntries.columns.createdDate'),
  });
  handler.addColumn({
    accessor: 'status',
    type: 'string',
    title: ({ t }) => t('accountingEntries.columns.status'),
  });

  handler.addAction({
    isVisible: ({ row }) =>
      [AccountingEntryStatus.vigente, AccountingEntryStatus.anulado].includes(row.status),
    Button: ({ row }) => {
      const dispatch = useDispatch();
      const downloadingReportId = useSelector(accountingEntriesDownloadingReportIdSelector);
      const handleClick = useCallback(
        () => dispatch(accountingEntriesReportRequested(row.id)),
        [dispatch, row.id]
      );
      const loading = row.id === downloadingReportId;
      return (
        <DataFlexSalesActionButton onClick={handleClick} icon={DownloadIcon} loading={loading} />
      );
    },
  });

  handler.addAction({
    isVisible: ({ row, context }) =>
      row.status === AccountingEntryStatus.vigente &&
      hasPermissions(context.userPermissions, [AppPermissions.ACCOUNTING_ENTRIES_COMPLETE]),
    Button: ({ row }) => {
      const dispatch = useDispatch();
      const handleClick = useCallback(
        () => dispatch(accountingEntriesChangeDelete(row)),
        [dispatch, row]
      );
      const icon = useCallback(() => <DeleteIcon fontSize="small" />, []);

      return <DataFlexSalesActionButton onClick={handleClick} icon={icon} />;
    },
  });

  return handler;
};
