import { useState, createContext, ReactNode, useContext, useMemo, useEffect } from 'react';
import { AppPermissions } from '../../types';

interface PermissionsContextType {
  resourcePermissions: AppPermissions[];
}

const initialState: PermissionsContextType = {
  resourcePermissions: [],
};

export const PermissionsContext = createContext<PermissionsContextType>(initialState);

export interface Props {
  children: ReactNode;
  resourcePermissions: AppPermissions[];
}

const PermissionsProvider = ({ resourcePermissions, ...others }: Props) => {
  const [permissions, setPermisions] = useState(resourcePermissions);

  const value: PermissionsContextType = useMemo(
    () => ({ resourcePermissions, permissions }),
    [resourcePermissions, permissions]
  );

  useEffect(() => {
    setPermisions(permissions);
  }, [permissions]);

  return <PermissionsContext.Provider value={value} {...others} />;
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);

  if (context === undefined)
    throw new Error('usePermissions debe estar dentro de PermissionsContext.Provider');

  return context;
};

export default PermissionsProvider;
