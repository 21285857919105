import { Actions, State, ActionType } from './types';
import {
  getDateValue,
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoading,
  remoteResourceLoadSuccess,
} from '../../helpers';

const initialState: State = {
  filters: {
    country: null,
    month: getDateValue(null),
  },
  downloading: false,
  oakBrook: remoteResourceInitialState([]),
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionType.oakBrookChangeFilterCountry:
      return {
        ...state,
        oakBrook: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{
            country: action.country,
          },
        },
      };
    case ActionType.oakBrookChangeFilterMonth:
      return {
        ...state,
        oakBrook: remoteResourceInitialState([]),
        filters: {
          ...state.filters,
          ...{
            month: getDateValue(action.month),
          },
        },
      };
    case ActionType.oakBrookFindRequested:
      return {
        ...state,
        oakBrook: remoteResourceLoading([]),
      };
    case ActionType.oakBrookFindSuccess:
      return {
        ...state,
        oakBrook: remoteResourceLoadSuccess(action.oakBrook),
      };
    case ActionType.oakBrookFindFailure:
      return {
        ...state,
        oakBrook: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.oakBrookDownloadRequested:
      return {
        ...state,
        downloading: true,
      };
    case ActionType.oakBrookDownloadFinished:
      return {
        ...state,
        downloading: false,
      };

    default:
      return state;
  }
};

export default reducer;
