import Grid from '@mui/material/Grid';
import {
  reportSalesJournalGcsLoadingSelector,
  findSalesJournalRequested,
  reportSalesJournalNcsLoadingSelector,
  reportSalesJournalFcsLoadingSelector,
  reportSalesJournalThirdPartyLoadingSelector,
} from '../state';

import {
  ReportFilterActions,
  ReportFilters,
  CountryFilter,
  StoreFilter,
  AccountingDateRangeFilter,
} from './ReportFilters';

import { AppPermissions } from '../types';
import { PermissionsProvider } from './Permissions';

const onedriveAction = () => findSalesJournalRequested(true);
const loadingSelector =
  reportSalesJournalGcsLoadingSelector ||
  reportSalesJournalNcsLoadingSelector ||
  reportSalesJournalFcsLoadingSelector ||
  reportSalesJournalThirdPartyLoadingSelector;

const SalesJournalSearch = () => (
  <ReportFilters
    loadingSelector={loadingSelector}
    applyAction={findSalesJournalRequested}
    onedriveAction={onedriveAction}
  >
    <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <AccountingDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </PermissionsProvider>
  </ReportFilters>
);

export default SalesJournalSearch;
