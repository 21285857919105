import { startOfMonth, subMonths } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { constants, isEqualNullableDate } from '../../helpers';
import {
  changeGcsFilterEndAccountingDate,
  changeGcsFilterStartAccountingDate,
  filterParametersDataSelector,
  reportGcsFilterEndAccountingDateSelector,
  reportGcsFilterStartAccountingDateSelector,
} from '../../state';
import DateRangeFlexSales, {
  DateRangeFlexSalesProps,
  DateRangeFlexSalesValue,
} from '../DateRangeFlexSales';

type AccountingDateRangeFilterProps = Pick<DateRangeFlexSalesProps<Date>, 'spacing'>;

const AccountingDateRangeFilter = (props: AccountingDateRangeFilterProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const startDate = useSelector(reportGcsFilterStartAccountingDateSelector);
  const endDate = useSelector(reportGcsFilterEndAccountingDateSelector);
  const filterParameters = useSelector(filterParametersDataSelector);
  const minDate = startOfMonth(subMonths(new Date(), filterParameters?.filtersLimitMonths || 3));

  const dateValue: DateRangeFlexSalesValue<Date> = useMemo(
    () => [startDate.raw, endDate.raw] as DateRangeFlexSalesValue<Date>,
    [startDate.raw, endDate.raw]
  );

  const handleChange = useCallback(
    ([newStartAccountingDate, newEndAccountingDate]: DateRangeFlexSalesValue<Date>) => {
      if (!isEqualNullableDate(newStartAccountingDate, startDate.raw as Date)) {
        dispatch(changeGcsFilterStartAccountingDate(newStartAccountingDate));
      } else if (!isEqualNullableDate(newEndAccountingDate, endDate.raw as Date))
        dispatch(changeGcsFilterEndAccountingDate(newEndAccountingDate));
    },
    [dispatch, endDate, startDate]
  );

  return (
    <DateRangeFlexSales
      {...props}
      maxDate={constants.reportGcsFilterRangeMaxDate}
      minDate={minDate}
      startText={t('toolbar.labels.fromDateAccounting')}
      endText={t('toolbar.labels.toDateAccounting')}
      value={dateValue}
      onChange={handleChange}
    />
  );
};

export default AccountingDateRangeFilter;
