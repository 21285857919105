import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import CountrySelect from './CountryFilter';
import StoreFilter from './StoreFilter';
import BusinessDateFilter from './BusinessDateFilter';
import ReprocessTypeFilter from './ReprocessTypeFilter';
import StatusFilter from './StatusFilter';
import { AppPermissions } from '../../../types';
import { PermissionsProvider } from '../../Permissions';
import FederativeEntityFilter from './FederativeEntityFilter';
import { reprocessFilterCountryCodeSelector } from '../../../state';

export interface ReprocessFiltersProps {
  actions: ReactNode;
}

const GridBr = () => {
  const countryCode = useSelector(reprocessFilterCountryCodeSelector);

  if (countryCode !== 'BR') return null;

  return (
    <Grid item xs={12} md={3} lg={2} xl={2}>
      <FederativeEntityFilter fullWidth />
    </Grid>
  );
};

const ReprocessFilters = ({ actions }: ReprocessFiltersProps) => (
  <PermissionsProvider resourcePermissions={[AppPermissions.REPROCESS]}>
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12} md={3} lg={2} xl={2}>
        <CountrySelect fullWidth />
      </Grid>
      <Grid item xs={12} md={3} lg={2} xl={2}>
        <StoreFilter fullWidth />
      </Grid>
      {/* <GridBr /> */}
      <Grid item xs={12} md={3} lg={2} xl={2}>
        <BusinessDateFilter />
      </Grid>
      <Grid item xs={12} md={3} lg={2} xl={2}>
        <ReprocessTypeFilter fullWidth />
      </Grid>
      <Grid item xs={12} md={3} lg={2} xl={2}>
        <StatusFilter fullWidth />
      </Grid>
      <Grid item xs={12} md lg xl>
        {actions}
      </Grid>
    </Grid>
  </PermissionsProvider>
);

export default ReprocessFilters;
