import { HttpRequestError, RemoteRequest, RemoteResource } from '../types';

export const remoteRequestInitialState: RemoteRequest = {
  error: null,
  loaded: false,
  failure: false,
  loading: false,
};

export const remoteRequestLoading: RemoteRequest = {
  error: null,
  loaded: false,
  failure: false,
  loading: true,
};

export const remoteRequestSuccess: RemoteRequest = {
  error: null,
  loaded: true,
  failure: false,
  loading: false,
};

export const remoteRequestFailure = (error: HttpRequestError): RemoteRequest => ({
  error,
  loaded: false,
  failure: true,
  loading: false,
});

export const remoteResourceInitialState = <TData>(initialData: TData): RemoteResource<TData> => ({
  ...remoteRequestInitialState,
  data: initialData,
});

export const remoteResourceLoading = <TData>(clearData: TData): RemoteResource<TData> => ({
  ...remoteRequestLoading,
  data: clearData,
});

export const remoteResourceLoadSuccess = <TData>(data: TData): RemoteResource<TData> => ({
  ...remoteRequestSuccess,
  data,
});

export const remoteResourceLoadFailure = <TData>(
  clearData: TData,
  error: HttpRequestError
): RemoteResource<TData> => ({
  ...remoteRequestFailure(error),
  data: clearData,
});
