import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../../config/routes';

import {
  reportMastersImportLoadingSelector,
  findMastersImportRequested,
  reportMastersImportFilterEndImportDateSelector,
  reportMastersImportFilterStartImportDateSelector,
  activeCountriesSelector,
} from '../../state';

import { ReportFilterActions, ReportFilters } from '../ReportFilters';

import { AppPermissions } from '../../types';
import { PermissionsProvider } from '../Permissions';
import { CountryFilter, ImportDateRangeFilter, StateFilter } from './filters';

const ButtonCreate = () => {
  const navigate = useNavigate();
  const handleClickApply = useCallback(
    () => navigate(routes.protectedNestedRoutes.mastersImport.children.create.path),
    [navigate]
  );
  const { t } = useTranslation();
  const { loaded } = useSelector(activeCountriesSelector);
  const disabled = !loaded;

  return (
    <Button variant="contained" onClick={handleClickApply} disabled={disabled}>
      {t('masters-import.execution.label')}
    </Button>
  );
};

const MastersDetailSearch = () => {
  const startImportDate = !useSelector(reportMastersImportFilterStartImportDateSelector).accept;
  const endImportDate = !useSelector(reportMastersImportFilterEndImportDateSelector).accept;
  const applyDisabled = startImportDate && endImportDate ? false : startImportDate || endImportDate;
  return (
    <ReportFilters
      loadingSelector={reportMastersImportLoadingSelector}
      applyAction={findMastersImportRequested}
    >
      <PermissionsProvider resourcePermissions={[AppPermissions.DASHBOARD_MASTER_IMPORT]}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={3} lg={2} xl={2}>
            <CountryFilter fullWidth />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <ImportDateRangeFilter spacing={1} />
          </Grid>
          <Grid item xs={12} md={3} lg={2} xl={2}>
            <StateFilter />
          </Grid>
          <Grid item xs={12} md lg xl>
            <ReportFilterActions applyDisabled={applyDisabled}>
              <ButtonCreate />
            </ReportFilterActions>
          </Grid>
        </Grid>
      </PermissionsProvider>
    </ReportFilters>
  );
};

export default MastersDetailSearch;
