import { useSelector } from 'react-redux';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useTranslation } from 'react-i18next';
import { accountingParametersAccountingParametersSelector } from '../../state';
import { HeaderRowTableFlexSales, TableFlexSales } from '../TableFlexSales';
import Row from './Row';

const AccountingParametersCollapsibleTable = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useSelector(accountingParametersAccountingParametersSelector);

  return (
    <TableFlexSales
      title={t('accountingParameters.titles.main')}
      error={Boolean(error)}
      loading={loading}
      totalRows={data?.length}
    >
      <TableHead>
        <HeaderRowTableFlexSales>
          <TableCell />
          <TableCell>{t('accountingParameters.columns.country')}</TableCell>
          <TableCell>{t('accountingParameters.columns.journalType')}</TableCell>
          <TableCell>{t('accountingParameters.columns.source')}</TableCell>
          <TableCell>{t('accountingParameters.columns.category')}</TableCell>
          <TableCell>{t('accountingParameters.columns.categoryAlias')}</TableCell>
          <TableCell>{t('accountingParameters.columns.frequency')}</TableCell>
          <TableCell />
        </HeaderRowTableFlexSales>
      </TableHead>
      <TableBody>
        {data.map(row => (
          <Row key={`${row.countryId}${row.journalType}`} values={row} />
        ))}
      </TableBody>
    </TableFlexSales>
  );
};

export default AccountingParametersCollapsibleTable;
