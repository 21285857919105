import Drawer from '@mui/material/Drawer';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import SidebarNav from './SidebarNav';
import Copyright from '../Copyright';
import { isModeModalSelector, isSidebarOpenSelector } from '../../state';

const drawerWidth = 320;

const ListContainer = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(8),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8),
      },
    }),
  },
}));

const Sidebar = () => {
  const isModeModal = useSelector(isModeModalSelector);
  const isSidebarOpen = useSelector(isSidebarOpenSelector);

  return !isModeModal ? (
    <StyDrawer variant="permanent" open={isSidebarOpen}>
      <ListContainer>
        <SidebarNav isSidebarOpen={isSidebarOpen} />
        {isSidebarOpen && <Copyright yearSecondLine />}
      </ListContainer>
    </StyDrawer>
  ) : null;
};

export default Sidebar;
