import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import { TableSummaryRow, TableSummaryRowProps } from '@devexpress/dx-react-grid-material-ui';
import { useDataGridFlexSales } from '../DataGridFlexSalesProvider';
import { Column, DataGridHandler, RowTotal } from '../DataGridHandler';

const TotalCellStyledContaier = styled(TableSummaryRow.TotalCell)`
  font-weight: bold;
  color: inherit;
`;

const TotalItemEmpty = styled.span`
  opacity: 0;
  ::before {
    content: '-';
  }
`;

interface TotalItemProps<TData, TContext> {
  column: Column<TData>;
  total: RowTotal<TData>;
  handler: DataGridHandler<TData, TContext>;
}

const TotalItem = <TData, TContext>({
  column,
  total,
  handler,
}: TotalItemProps<TData, TContext>) => {
  const { t } = useTranslation();
  const title = column.getTitle();
  const tooltip = useMemo(() => {
    const { type } = total;
    switch (type) {
      case 'sum':
        return t(`dataGridFlexSales.totals.sum`, { column: title });
      case 'avg':
        if ('amount' in total) {
          const dividend = handler.getColumnById(total.amount as string).getTitle();
          const divisor = handler.getColumnById(total.quantity as string).getTitle();
          return (
            <>
              {t(`dataGridFlexSales.totals.avgColumns`, { dividend, divisor })}:
              <br />
              SUM({dividend}) / SUM({divisor})
            </>
          );
        }
        return t(`dataGridFlexSales.totals.avg`, { column: title });
      default:
        throw new Error(`El tipo '${type}' no tiene definida una traducción`);
    }
  }, [t, handler, total, title]);
  return (
    <Tooltip title={tooltip} arrow>
      <span>{column.getValueFormatted(total.value)}</span>
    </Tooltip>
  );
};

const TableSummaryRowTotalCell: TableSummaryRowProps['totalCellComponent'] = ({
  column,
  ...rest
}) => {
  const { handler } = useDataGridFlexSales();
  const col = handler.getColumnById(column.name);
  const total = handler.totals.find(i => i.columnId === column.name);
  return (
    <TotalCellStyledContaier column={column} {...rest}>
      {total ? <TotalItem column={col} total={total} handler={handler} /> : <TotalItemEmpty />}
    </TotalCellStyledContaier>
  );
};

export default TableSummaryRowTotalCell;
