import { Middleware } from 'redux';

interface CallbackInfo {
  callback: (payload: unknown) => void;
  toClear: string;
}

const handleListenersMiddleware: Middleware = () => {
  const pendingCallbacks = new Map<string, CallbackInfo[]>();
  return next => action => {
    if (action.meta?.final_form_promise) {
      if (!pendingCallbacks.has(action.meta.final_form_resolve))
        pendingCallbacks.set(action.meta.final_form_resolve, []);

      if (!pendingCallbacks.has(action.meta.final_form_reject))
        pendingCallbacks.set(action.meta.final_form_reject, []);

      pendingCallbacks.get(action.meta.final_form_resolve)?.push({
        callback: action.meta.final_form_promise.resolve,
        toClear: action.meta.final_form_reject,
      });

      pendingCallbacks.get(action.meta.final_form_reject)?.push({
        callback: action.meta.final_form_promise.reject,
        toClear: action.meta.final_form_resolve,
      });
    }

    const cbInfos = pendingCallbacks.get(action.type) || [];

    cbInfos.forEach(cbInfo => {
      pendingCallbacks.delete(cbInfo.toClear);
      cbInfo.callback(action.payload);
    });

    return next(action);
  };
};

export default handleListenersMiddleware;
