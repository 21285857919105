import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useRegion from '../../../hooks/useRegion';
import {
  changeOpStrucuresFilterRegion,
  reportFilterCountryCodeSelector,
  reportOpStructuresFilterManagementSelector,
  reportOpStructuresFilterRegionalManagementSelector,
  reportOpStructuresFilterRegionSelector,
  reportOpStructuresFilterSupervisionSelector,
} from '../../../state';
import { Region } from '../../../types';
import { MultiSelect } from '../../selects';

const RegionFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const countryCode = useSelector(reportFilterCountryCodeSelector);
  const regionalManagement = useSelector(reportOpStructuresFilterRegionalManagementSelector);
  const managements = useSelector(reportOpStructuresFilterManagementSelector);
  const supervisions = useSelector(reportOpStructuresFilterSupervisionSelector);
  const regions = useSelector(reportOpStructuresFilterRegionSelector);
  const { options, disabled, loading } = useRegion(
    countryCode,
    regionalManagement,
    managements,
    supervisions
  );

  const handleChange = useCallback(
    (value: Region[]) => dispatch(changeOpStrucuresFilterRegion(value)),
    [dispatch]
  );

  return (
    <MultiSelect
      label={t('toolbar.labels.region')}
      options={options}
      propDesc="label"
      disabled={disabled}
      value={regions}
      onChange={handleChange}
      loading={loading}
    />
  );
};

export default RegionFilter;
