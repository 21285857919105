import { Country, AppParameters, HttpRequestError, MasterData } from '../../types';
import {
  ActionType,
  MasterDataRequestedAction,
  MasterDataSuccessAction,
  MasterDataFailureAction,
  ActiveCountriesFailureAction,
  ActiveCountriesRequestedAction,
  ActiveCountriesSuccessAction,
  ParametersRequestedAction,
  ParametersSuccessAction,
  ParametersFailureAction,
} from './types';

export const masterDataRequested = (): MasterDataRequestedAction => ({
  type: ActionType.masterDataRequested,
});

export const masterDataSuccess = (masterData: MasterData): MasterDataSuccessAction => ({
  type: ActionType.masterDataSuccess,
  masterData,
});

export const masterDataFailure = (error: HttpRequestError): MasterDataFailureAction => ({
  type: ActionType.masterDataFailure,
  error,
});

export const activeCountriesRequested = (): ActiveCountriesRequestedAction => ({
  type: ActionType.activeCountriesRequested,
});

export const activeCountriesSuccess = (
  activeCountries: Country[]
): ActiveCountriesSuccessAction => ({
  type: ActionType.activeCountriesSuccess,
  activeCountries,
});

export const activeCountriesFailure = (error: HttpRequestError): ActiveCountriesFailureAction => ({
  type: ActionType.activeCountriesFailure,
  error,
});

export const parametersRequested = (): ParametersRequestedAction => ({
  type: ActionType.parametersRequested,
});

export const parametersSuccess = (parameters: AppParameters): ParametersSuccessAction => ({
  type: ActionType.parametersSuccess,
  parameters,
});

export const parametersFailure = (error: HttpRequestError): ParametersFailureAction => ({
  type: ActionType.parametersFailure,
  error,
});
