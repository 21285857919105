import { SalesJournalThirdParty, Translation } from '../../../types';
import { DataGridHandler } from '../../DataGridFlexSales';

export interface ThirdPartyDataGridContext {
  t: Translation;
  country: string;
}

const initialContext: ThirdPartyDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<SalesJournalThirdParty, ThirdPartyDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'ivaPercentage',
    type: 'number',
    title: ({ t }) => t('sales-journal.table.columns.iva_percentage'),
  });

  handler.addColumn({
    accessor: 'iva',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sales-journal.table.columns.iva'),
    width: { px: 100, pt: 95 },
  });

  handler.addColumn({
    accessor: 'netSale',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sales-journal.table.columns.net_sale'),
  });

  handler.addColumn({
    accessor: 'grossSale',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sales-journal.table.columns.gross_sale'),
  });

  return handler;
};
