import { Action } from 'redux';
import {
  HttpErrorBaseAction,
  RemoteResource,
  AccountingParameter,
  EditAccountingParameterForm,
  Country,
} from '../../types';

export interface State {
  accountingParameters: RemoteResource<AccountingParameter[]>;
  accountingParameter: RemoteResource<AccountingParameter | undefined>;
  country: Country | null;
}

export enum ActionType {
  accountingParametersFindRequested = 'accountingParameters/findRequested',
  accountingParametersFindSuccess = 'accountingParameters/findSuccess',
  accountingParametersFindFailure = 'accountingParameters/findFailure',
  accountingParametersGetRequested = 'accountingParameters/getRequested',
  accountingParametersGetSuccess = 'accountingParameters/getSuccess',
  accountingParametersGetFailure = 'accountingParameters/getFailure',
  accountingParametersEditRequested = 'accountingParameters/editRequested',
  accountingParametersEditSuccess = 'accountingParameters/editSuccess',
  accountingParametersEditFailure = 'accountingParameters/editFailure',
  accountingParametersChangeCountry = 'accountingParameters/changeCountry',
}

export type AccountingParametersFindRequestedAction =
  Action<ActionType.accountingParametersFindRequested>;

export type AccountingParametersFindSuccessAction =
  Action<ActionType.accountingParametersFindSuccess> & {
    accountingParameters: AccountingParameter[];
  };

export type AccountingParametersFindFailureAction =
  HttpErrorBaseAction<ActionType.accountingParametersFindFailure>;

export type AccountingParametersGetRequestedAction =
  Action<ActionType.accountingParametersGetRequested> & {
    countryId: string;
    journalType: string;
  };

export type AccountingParametersGetSuccessAction =
  Action<ActionType.accountingParametersGetSuccess> & {
    accountingParameter: AccountingParameter | undefined;
  };

export type AccountingParametersGetFailureAction =
  HttpErrorBaseAction<ActionType.accountingParametersGetFailure>;

export type AccountingParametersEditRequestedAction =
  Action<ActionType.accountingParametersEditRequested> & {
    payload: EditAccountingParameterForm;
  };

export type AccountingParametersEditSuccessAction =
  Action<ActionType.accountingParametersEditSuccess>;

export type AccountingParametersEditFailureAction =
  HttpErrorBaseAction<ActionType.accountingParametersEditFailure>;

export type AccountingParametersChangeCountryAction =
  Action<ActionType.accountingParametersChangeCountry> & {
    country: Country | null;
  };

export type Actions =
  | AccountingParametersFindRequestedAction
  | AccountingParametersFindSuccessAction
  | AccountingParametersFindFailureAction
  | AccountingParametersGetRequestedAction
  | AccountingParametersGetSuccessAction
  | AccountingParametersGetFailureAction
  | AccountingParametersEditRequestedAction
  | AccountingParametersEditSuccessAction
  | AccountingParametersEditFailureAction
  | AccountingParametersChangeCountryAction;
