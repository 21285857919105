import Grid from '@mui/material/Grid';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditAccountingParameterForm } from '../../types';
import { TextFieldFormInput, useFlexSalesForm } from '../FlexSalesForm';

const inputPropsReadOnly = { readOnly: true };
const inputProps = { maxLength: 10 };

const detailsKey: keyof EditAccountingParameterForm = 'details';

const DetailsFormInputArray = () => {
  const { t } = useTranslation();
  const { control } = useFlexSalesForm();
  const { fields } = useFieldArray({
    control,
    name: detailsKey,
  });

  return (
    <>
      {fields.map((item, index) => (
        <Grid item xs key={item.id}>
          <Grid container flexDirection="row" spacing={1}>
            <Grid item xs>
              <TextFieldFormInput
                name={`${detailsKey}.${index}.journalSubtype`}
                type="text"
                variant="standard"
                label={t('accountingParameters.columns.journalSubType')}
                inputProps={inputPropsReadOnly}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextFieldFormInput
                name={`${detailsKey}.${index}.account`}
                type="text"
                variant="standard"
                label={t('accountingParameters.columns.account')}
                required
                inputProps={inputProps}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextFieldFormInput
                name={`${detailsKey}.${index}.subAccount`}
                type="text"
                variant="standard"
                label={t('accountingParameters.columns.subAccount')}
                required
                inputProps={inputProps}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
      ;
    </>
  );
};

export default DetailsFormInputArray;
