import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { masterDataSelector } from '../state';
import { Management, Region, RegionalManagement, Supervision } from '../types';

const managementsSort = (a: Management, b: Management) => (a.label > b.label ? 1 : -1);

const useManagement = (
  countryCode: string,
  regionalManagements: RegionalManagement[],
  regions: Region[],
  supervision: Supervision[]
) => {
  const masterData = useSelector(masterDataSelector);

  const managementsForCountry = useMemo(() => {
    const managements = masterData.loaded && masterData.data ? masterData.data.managements : [];
    return countryCode
      ? managements.filter(i => i.countryCode === countryCode).sort(managementsSort)
      : managements;
  }, [masterData, countryCode]);

  const managementsForRegionalManagement = useMemo(() => {
    const managements = managementsForCountry;
    return regionalManagements.length > 0
      ? managements
          .filter(r => regionalManagements.map(i => i.regionalMgmtId).includes(r.regionalMgmtId))
          .sort(managementsSort)
      : managements;
  }, [managementsForCountry, regionalManagements]);

  const managementsForRegion = useMemo(() => {
    const managements = managementsForRegionalManagement;
    return regions.length > 0
      ? managements
          .filter(r => regions.map(i => i.regionId).includes(r.regionId))
          .sort(managementsSort)
      : managements;
  }, [managementsForRegionalManagement, regions]);

  const managementsForSupervision = useMemo(() => {
    const managements = managementsForRegion;
    return supervision.length > 0
      ? managements
          .filter(r => supervision.map(i => i.mgmtId).includes(r.mgmtId))
          .sort(managementsSort)
      : managements;
  }, [managementsForRegion, supervision]);

  const disabled = !masterData.data;

  const options = managementsForSupervision || [];

  return { options, disabled, loading: masterData.loading };
};

export default useManagement;
