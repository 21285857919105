import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilterDifferenceType, reportFilterDifferenceTypeSelector } from '../../../state';
import {
  SimpleSelect,
  SimpleSelectOptionFormatter,
  SimpleSelectProps,
  useArrayToSimpleSelectOption,
} from '../../selects';

type DifferenceTypeFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const differenceTypes = ['positive', 'negative'];

const differenceTypesFormatter: SimpleSelectOptionFormatter = {
  type: 'translation',
  translationIdBase: 'petty-cash-difference.filters.difference-type.options',
};

const DifferenceTypeFilter = (props: DifferenceTypeFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const DifferenceType = useSelector(reportFilterDifferenceTypeSelector);

  const handleChange = useCallback(
    (value: string) => {
      dispatch(changeFilterDifferenceType(value));
    },
    [dispatch]
  );

  const options = useArrayToSimpleSelectOption(differenceTypes, differenceTypesFormatter);

  return (
    <SimpleSelect
      {...props}
      label={t('toolbar.labels.difference-type')}
      options={options}
      value={DifferenceType}
      onChange={handleChange}
    />
  );
};

export default DifferenceTypeFilter;
