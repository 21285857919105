import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AccountingEntry } from '../../types';

import {
  accountingEntriesAccountingEntriesSelector,
  userPermissionsDataSelector,
} from '../../state';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { AccountingEntryDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { t } = useTranslation();
  const { handler } = useDataGridFlexSales<AccountingEntry, AccountingEntryDataGridContext>();
  const userPermissions = useSelector(userPermissionsDataSelector);
  const { loading, data } = useSelector(accountingEntriesAccountingEntriesSelector);

  const gridContext = useMemo(
    () => ({
      t,
      userPermissions: userPermissions.map(i => i.permission),
    }),
    [t, userPermissions]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(t('accountingEntries.titles.dataGrid'));
  handler.setLoading(loading);

  return null;
};

const AccountingEntriesDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default AccountingEntriesDataGrid;
