import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeOpStrucuresFilterAgreement,
  masterDataSelector,
  reportOpStructuresFilterAgreementSelector,
} from '../../../state';
import { Agreement } from '../../../types';
import { MultiSelect } from '../../selects';

const AgreementFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const masterData = useSelector(masterDataSelector);
  const agreements = useSelector(reportOpStructuresFilterAgreementSelector);
  const disabled = !masterData.loaded;
  const agreementOptions = masterData.data?.agreements || [];

  const handleChange = useCallback(
    (value: Agreement[]) => dispatch(changeOpStrucuresFilterAgreement(value)),
    [dispatch]
  );

  // TODO: Agregar traducción a Agreements
  // const options = agreementOptions.map(i => ({
  //   ...i,
  //   label: t(`agreements.labels.${i.label}`),
  // }));

  return (
    <MultiSelect
      label={t('toolbar.labels.agreement')}
      options={agreementOptions}
      propDesc="label"
      disabled={disabled}
      value={agreements}
      onChange={handleChange}
      loading={masterData.loading}
    />
  );
};

export default AgreementFilter;
