import * as yup from 'yup';
import { CreateAccountingEntriesForm } from '../../types';

export const minDate = new Date(2015, 0, 1);
export const maxDate = new Date();

export const getSchema = (): yup.ObjectSchema<CreateAccountingEntriesForm> =>
  yup.object().shape({
    countryId: yup.string().required(),
    journalGroup: yup.string().required(),
    accountingMonth: yup.date().required().min(minDate).max(maxDate),
  });
