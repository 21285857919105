import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';

const CircularProgressStyled = styled(CircularProgress)`
  position: absolute;
`;

const ContainerChildren = styled.span<{ loading: number }>`
  opacity: ${props => (props.loading ? 0 : 1)};
  transition: opacity 1000ms;
`;

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

const LoadingButton = ({ loading, disabled, children, ...others }: LoadingButtonProps) => (
  <Button {...others} disabled={disabled || loading}>
    {loading && <CircularProgressStyled color="inherit" size={16} />}
    <ContainerChildren loading={+loading}>{children}</ContainerChildren>
  </Button>
);
export default LoadingButton;
