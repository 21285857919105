import styled from '@emotion/styled';
import {
  PettyCashDifferenceDataGrid,
  PettyCashDifferenceSearch,
  PettyCashDifferenceSwitch,
} from '../components';
import FiltersLayoutHandler from '../components/FiltersLayoutHandler';
import { reportPettyCashDifferenceLoadingSelector } from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PettyCashDifferenceSearchContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const PettyCashDifference = () => (
  <Container>
    <PettyCashDifferenceSwitch checked={false} />
    <PettyCashDifferenceSearchContainer>
      <FiltersLayoutHandler selector={reportPettyCashDifferenceLoadingSelector}>
        <PettyCashDifferenceSearch />
      </FiltersLayoutHandler>
    </PettyCashDifferenceSearchContainer>
    <PettyCashDifferenceDataGrid />
  </Container>
);

export default PettyCashDifference;
