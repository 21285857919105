import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { masterDataSelector } from '../state';
import { Country } from '../types';

type UseContryNameProps =
  | {
      countryId: string;
    }
  | { countryCode: string }
  | Country;

const useContryName = (props: UseContryNameProps) => {
  const { t } = useTranslation();
  const masterData = useSelector(masterDataSelector);

  const countryCode = useMemo(() => {
    if ('countryCode' in props) return props.countryCode;

    if ('countryId' in props) {
      const countries = masterData.loaded && masterData.data ? masterData.data.countries : [];
      return countries.find(i => parseInt(i.countryId, 10) === parseInt(props.countryId, 10))
        ?.countryCode;
    }

    throw Error('Invalid Parameter');
  }, [masterData, props]);

  return t(`operativeStructure.countries.${countryCode}`);
};

export default useContryName;
