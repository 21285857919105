import { SalesJournalGcs, Translation } from '../../../types';
import { DataGridHandler } from '../../DataGridFlexSales';

export interface GcsDataGridContext {
  t: Translation;
  country: string;
}

const initialContext: GcsDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<SalesJournalGcs, GcsDataGridContext>(initialContext, locale);

  handler.addColumn({
    accessor: 'fiscalPosCode',
    type: 'string',
    title: ({ t }) => t('sales-journal.table.columns.fiscal_pos_code'),
  });

  handler.addColumn({
    accessor: 'from',
    type: 'string',
    title: ({ t }) => t('sales-journal.table.columns.from'),
    width: { px: 100, pt: 95 },
  });

  handler.addColumn({
    accessor: 'to',
    type: 'string',
    title: ({ t }) => t('sales-journal.table.columns.to'),
    width: { px: 100, pt: 95 },
  });

  handler.addColumn({
    accessor: 'posNum',
    type: 'number',
    title: ({ t }) => t('sales-journal.table.columns.pos_num'),
  });

  handler.addColumn({
    accessor: 'ivaPercentage',
    type: 'number',
    title: ({ t }) => t('sales-journal.table.columns.iva_percentage'),
  });

  handler.addColumn({
    accessor: 'iva',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sales-journal.table.columns.iva'),
    width: { px: 100, pt: 95 },
  });

  handler.addColumn({
    accessor: 'netSale',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sales-journal.table.columns.net_sale'),
  });

  handler.addColumn({
    accessor: 'grossSale',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sales-journal.table.columns.gross_sale'),
  });

  return handler;
};
