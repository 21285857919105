import { Action } from 'redux';
import { TableExportPdf } from '../../exports';
import { OpStructuresFilter, ReportFilter, Translation } from '../../types';

export interface State {
  downloadHandler: (() => void) | undefined;
  downloading: boolean;
}

export enum ActionType {
  downloadRequested = 'exports/downloadRequested',
  downloadSuccess = 'exports/downloadSuccess',
  downloadFailure = 'exports/downloadFailure',
  downloadReportPdf = 'exports/downloadReportPdf',
}

export type DownloadRequestedAction = Action<ActionType.downloadRequested> & {
  downloadHandler: () => Promise<void> | Generator;
  errorMessage: (t: Translation) => string;
};

export type DownloadSuccessAction = Action<ActionType.downloadSuccess>;

export type DownloadFailureAction = Action<ActionType.downloadFailure> & {
  error: unknown;
};

export type DownloadReportPdfAction = Action<ActionType.downloadReportPdf> & {
  title: string;
  locale: Locale;
  t: Translation;
  includeMissingClosingTable?: boolean;
  table: TableExportPdf;
  parameters?: Array<keyof ReportFilter>;
  opStructures?: Array<keyof OpStructuresFilter>;
};

export type Actions =
  | DownloadRequestedAction
  | DownloadSuccessAction
  | DownloadFailureAction
  | DownloadReportPdfAction;
