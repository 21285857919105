import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import {
  masterDataDataSelector,
  reportFilterCountryCodeSelector,
  reportFilterTenderGroupBySelector,
  reportMissingClosingTotalMissingClosingTotalSelector,
  reportSalesTypesSelector,
  reportTenderSelector,
} from '../../state';
import { TenderReport } from '../../types';
import handlerFactory, { ReportTenderDataGridContext } from './handlerFactory';

const DataGridTitle = () => {
  const { t } = useTranslation();
  const { loaded, data: totals } = useSelector(
    reportMissingClosingTotalMissingClosingTotalSelector
  );

  let title = t('report-tender.title');

  if (loaded && totals !== null) {
    title += ` (${totals} ${t('sale-totals.labels.missing')})`;
  }
  return <span>{title}</span>;
};

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<TenderReport, ReportTenderDataGridContext>();
  const { t } = useTranslation();
  const titles = useMemo(
    () => ({ title: t('report-tender.title'), tableTitle: <DataGridTitle /> }),
    [t]
  );

  const country = useSelector(reportFilterCountryCodeSelector);
  const tenderResource = useSelector(reportTenderSelector);
  const groupBy = useSelector(reportFilterTenderGroupBySelector);
  const masterData = useSelector(masterDataDataSelector);

  const { data: saleTypeOptions } = useSelector(reportSalesTypesSelector);

  const { loading, data } = tenderResource;

  const {
    agreements = [],
    companies = [],
    managements = [],
    regionalManagements = [],
    regions = [],
    supervisions = [],
  } = masterData || {};

  const gridContext = useMemo(
    () => ({
      t,
      country,
      groupBy,
      saleTypeOptions,
      agreements,
      companies,
      managements,
      regionalManagements,
      regions,
      supervisions,
    }),
    [
      t,
      country,
      groupBy,
      saleTypeOptions,
      agreements,
      companies,
      managements,
      regionalManagements,
      regions,
      supervisions,
    ]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(titles);
  handler.setLoading(loading);

  return null;
};

const ReportTenderDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default ReportTenderDataGrid;
