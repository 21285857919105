import Button, { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogFlexSales from '../../DialogFlexSales';

type Config<TGroupBy extends string> = Record<TGroupBy, boolean>;

export interface GroupBySelectorProps<TGroupBy extends string> {
  onClose: () => void;
  onApply: (values: TGroupBy[]) => void;
  config: Config<TGroupBy>;
  initialValues: TGroupBy[];
}

const GroupBySelector = <TGroupBy extends string>({
  onClose,
  onApply,
  config,
  initialValues,
}: GroupBySelectorProps<TGroupBy>) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(initialValues);

  const optionsKeys = Object.keys(config) as TGroupBy[];
  const handleClick: ButtonProps['onClick'] = () => {
    onApply(selected);
    onClose();
  };

  const handleToggle = (key: TGroupBy) => () => {
    const currentIndex = selected.indexOf(key);
    const newChecked = [...selected];

    if (currentIndex === -1) {
      newChecked.push(key);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
  };

  useEffect(() => {
    onApply(selected);
  }, [onApply, selected, setSelected]);

  return (
    <DialogFlexSales onClose={onClose} open scroll="paper">
      <DialogTitle>{t('dialogs.groupBy.title')}</DialogTitle>
      <DialogContent>
        <List dense>
          {optionsKeys.map(key => {
            const labelId = `checkbox-groupby-${key}`;
            const checked = selected.includes(key);
            const inputProps = { 'aria-labelledby': labelId };
            return (
              <ListItem key={key} disablePadding dense>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(key)}
                  dense
                  disabled={config[key]}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={inputProps}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={t(`toolbar.groupBy.${key}`)} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>{t('navigation.buttons.close')}</Button>
      </DialogActions>
    </DialogFlexSales>
  );
};

export default GroupBySelector;
