import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  reportPendingInterfacesParametersSelector,
  reportPendingInterfacesFilterQuerySelector,
  changePendingInterfacesInterfacesFilterQuery,
} from '../../../state';
import {
  SimpleSelect,
  SimpleSelectOptionFormatter,
  SimpleSelectProps,
  useArrayToSimpleSelectOption,
} from '../../selects';

type QueryFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const formatter: SimpleSelectOptionFormatter = {
  type: 'translation',
  translationIdBase: 'pending-interfaces.query-filters',
  sort: 'asc',
};

const QueryFilter = (props: QueryFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parameters = useSelector(reportPendingInterfacesParametersSelector);
  const options = useArrayToSimpleSelectOption(parameters.data?.filterQuerys || [], formatter);
  const value = useSelector(reportPendingInterfacesFilterQuerySelector);
  const handleChange = useCallback(
    (value: string) => dispatch(changePendingInterfacesInterfacesFilterQuery(value)),
    [dispatch]
  );

  return (
    <SimpleSelect
      {...props}
      label={t('pending-interfaces.labels.query_filter')}
      onChange={handleChange}
      value={parameters.loaded ? value : ''}
      options={options}
      loading={parameters.loading}
    />
  );
};

export default QueryFilter;
