import { applyMiddleware, createStore, Middleware } from 'redux';
import reduxlogger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducers from './rootReducers';
import rootSagas from './rootSagas';
import handleListenersMiddleware from './handleListenersMiddleware';

const sagaMiddleware = createSagaMiddleware();
const middlewares: Middleware[] = [sagaMiddleware, handleListenersMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(reduxlogger);
}

const store = createStore(rootReducers, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSagas);

export default store;
