import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  companyCodesChangeFilterCountry,
  companyCodesFilterCountryIdSelector,
} from '../../../state';
import { CountrySelect, SimpleSelectProps } from '../../selects';

type CountryFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const CountryFilter = (props: CountryFilterProps) => {
  const dispatch = useDispatch();
  const countryId = useSelector(companyCodesFilterCountryIdSelector);

  const handleChange = useCallback(
    (value: string) => {
      dispatch(companyCodesChangeFilterCountry(value));
    },
    [dispatch]
  );

  return (
    <CountrySelect {...props} onChange={handleChange} value={countryId} propId="countryId" simple />
  );
};

export default CountryFilter;
