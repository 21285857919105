import styled from '@emotion/styled';
import { ThirdPartySalesByItemsDataGrid, ThirdPartySalesByItemsSearch } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const ThirdPartySalesByItems = () => (
  <Container>
    <ThirdPartySalesByItemsSearch />
    <ThirdPartySalesByItemsDataGrid />
  </Container>
);

export default ThirdPartySalesByItems;
