import { Theme, useMediaQuery } from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  DatePickerSlotsComponents,
  DateView,
} from '@mui/x-date-pickers';
import { UncapitalizeObjectKeys } from '@mui/x-date-pickers/internals';
import addYears from 'date-fns/addYears';
import { RefAttributes, useMemo } from 'react';
import DatePickerAutocompleteField from './DatePickerAutocompleteField';

const MIN_DATE_DEFAULT = addYears(new Date(), -3);

export type DatePickerFlexSalesView = DateView;

export interface DatePickerFlexSalesProps<TDate>
  extends Omit<DatePickerProps<TDate>, 'views'>,
    RefAttributes<HTMLDivElement> {
  spacing?: number;
  views?: DatePickerFlexSalesView[];
  required?: boolean;
  error?: string;
  name?: string;
}

const slots: DatePickerProps<Date>['slots'] = {
  field: DatePickerAutocompleteField,
};

const DatePickerFlexSales = <TDate,>({
  minDate: minDateParam,
  maxDate: maxDateparam,
  spacing,
  required,
  inputRef,
  error,
  views,
  name,
  ...others
}: DatePickerFlexSalesProps<TDate>) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const minDate = useMemo(() => minDateParam || MIN_DATE_DEFAULT, [minDateParam]) as TDate;
  const maxDate = useMemo(() => maxDateparam || new Date(), [maxDateparam]) as TDate;

  const slotProps: DatePickerProps<TDate>['slotProps'] = useMemo(
    () => ({
      field: {
        views,
        minDate,
        maxDate,
        required,
        variant: 'standard',
        fullWidth: true,
        helperText: error,
        error: Boolean(error),
        name,
      } as never,
    }),
    [required, error, minDate, maxDate, views, name]
  );

  return (
    <DatePicker
      minDate={minDate}
      maxDate={maxDate}
      slotProps={slotProps}
      selectedSections={{ startIndex: 0, endIndex: 1 }}
      slots={
        !isMobile ? (slots as UncapitalizeObjectKeys<DatePickerSlotsComponents<TDate>>) : undefined
      }
      views={views}
      {...others}
    />
  );
};

export default DatePickerFlexSales;
