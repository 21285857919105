import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeSalesViewMode,
  dashboardSalesClosingSelector,
  dashboardSalesViewModeSelector,
} from '../../../state';
import { IncorporationActionsProps } from './IncorporationActions';
import IncorporationCard from './IncorporationCard';

const itemModeInitial: IncorporationActionsProps['initValueItemMode'] = 'error';

const SalesIncorporation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sales = useSelector(dashboardSalesClosingSelector);
  const intervalModeInitial = useSelector(dashboardSalesViewModeSelector);

  const handleChangeIntervalMode: NonNullable<IncorporationActionsProps['onChangeIntervalMode']> =
    useCallback(
      value => {
        dispatch(changeSalesViewMode(value));
      },
      [dispatch]
    );

  return (
    <IncorporationCard
      resource={sales}
      title={t('dashboard.sales-incorporation')}
      onChangeIntervalMode={handleChangeIntervalMode}
      initValueIntervalMode={intervalModeInitial}
      initValueItemMode={itemModeInitial}
      name="sales"
    />
  );
};

export default SalesIncorporation;
