import { compareAsc } from 'date-fns';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { ComponentType, createElement, ReactNode } from 'react';
import { defaultFormatters, isFunction, dateFormatter } from '../../../helpers';
import { EventHandler } from './EventHandler';

export type ValueType = string | number | Date | undefined | null;

interface IteratorInfo<TIterator> {
  value: TIterator;
  index: number;
  values: TIterator[];
}

type WithIteratorInfo<TIterator, TResult> = (iteratorInfo: IteratorInfo<TIterator>) => TResult;

type ValueGetter<TData, TContext> = (row: TData, context: TContext) => ValueType;

type ValueGetterDynamic<TData, TContext, TIterator> = (
  iteratorInfo: IteratorInfo<TIterator>,
  row: TData,
  context: TContext
) => ValueType;

interface ColumnWidth {
  px: number;
  pt: number;
}

const columnTypes = [
  'string',
  'number',
  'date',
  'dateTime',
  'percent',
  'boolean',
  'array',
] as const;

export type ColumnType = (typeof columnTypes)[number];

type ColumnAlign = 'left' | 'right' | 'center';

type DefaultsFormatters = Record<ColumnType, (value: ValueType, format?: string) => string>;

type NumberTotals<TData> =
  | {
      type: 'sum';
    }
  | { type: 'avg' }
  | { type: 'avg'; amount: keyof TData; quantity: keyof TData };

type PercentTotals<TData> =
  | { type: 'avg' }
  | { type: 'avg'; amount: keyof TData; quantity: keyof TData };

export type TotalConfig<TData> = NumberTotals<TData> | PercentTotals<TData>;

export interface Column<TData> {
  type: string;
  id: string;
  visibilityTogglingDisabled?: boolean;
  align: 'left' | 'right' | 'center';
  totalConfig?: TotalConfig<TData>;
  defaultHidden: boolean;
  getOrder: () => number;
  isHidden: () => boolean;
  isAvailable: () => boolean;
  getWidth: () => ColumnWidth;
  getTitle: () => string;
  getValue: (row: TData) => ValueType;
  getValueFormatted: (value: ValueType) => string;
  getCell: (row: TData) => ReactNode;
}

interface ColumnConfigString {
  type: 'string';
}

interface ColumnConfigDate {
  type: 'date';
  format?: string;
}

interface ColumnConfigDateTime {
  type: 'dateTime';
}

interface ColumnConfigNumber<TData, TIterator, WithIterator> {
  type: 'number';
  total?: WithIterator extends false
    ? NumberTotals<TData>
    : WithIteratorInfo<TIterator, NumberTotals<TData>>;
}

interface ColumnConfigPercent<TData, TIterator, WithIterator> {
  type: 'percent';
  total?: WithIterator extends false
    ? PercentTotals<TData>
    : WithIteratorInfo<TIterator, PercentTotals<TData>>;
}

interface ColumnConfigBoolean {
  type: 'boolean';
}

interface ColumnConfigArray {
  type: 'array';
}

interface ColumnConfigWithKey<TData> {
  accessor: keyof TData;
}

interface ColumnConfigWithValueGetter<TData, TContext, TIterator, WithIterator> {
  accessor?: undefined;
  name: string;
  valueGetter: WithIterator extends true
    ? ValueGetterDynamic<TData, TContext, TIterator>
    : ValueGetter<TData, TContext>;
}

type ColumnConfigId<TData, TContext, TIterator, WithIterator = false> =
  | ColumnConfigWithKey<TData>
  | ColumnConfigWithValueGetter<TData, TContext, TIterator, WithIterator>;

type ColumnConfigTypes<TData, TIterator, WithIterator> =
  | ColumnConfigString
  | ColumnConfigNumber<TData, TIterator, WithIterator>
  | ColumnConfigDate
  | ColumnConfigDateTime
  | ColumnConfigPercent<TData, TIterator, WithIterator>
  | ColumnConfigBoolean
  | ColumnConfigArray;

export type StaticColumnConfig<TData, TContext> = ColumnConfigId<TData, TContext, unknown> &
  ColumnConfigTypes<TData, unknown, false> & {
    isDynamic?: false;
    title: (context: TContext) => string;
    defaultHidden?: boolean;
    available?: (context: TContext) => boolean;
    visibilityTogglingDisabled?: boolean;
    width?: ColumnWidth;
    renderCell?: (value: ValueType, row: TData, context: TContext) => ReactNode;
    align?: ColumnAlign;
    formatter?: (value: ValueType) => string;
    order?: number | ((context: TContext) => number);
  };

type DynamicColumnConfig<TData, TContext, TIterator> = ColumnConfigId<
  TData,
  TContext,
  TIterator,
  true
> &
  ColumnConfigTypes<TData, TIterator, true> & {
    isDynamic: true;
    iterator?: TIterator[];
    defaultHidden?: boolean | WithIteratorInfo<TIterator, boolean>;
    visibilityTogglingDisabled?: boolean | WithIteratorInfo<TIterator, boolean>;
    width?: ColumnWidth | WithIteratorInfo<TIterator, ColumnWidth>;
    align?: ColumnAlign | WithIteratorInfo<TIterator, ColumnAlign>;
    title: (iteratorInfo: IteratorInfo<TIterator>, context: TContext) => string;
    available?: (iteratorInfo: IteratorInfo<TIterator>, context: TContext) => boolean;
    renderCell?: (props: {
      iteratorInfo: IteratorInfo<TIterator>;
      value: ValueType;
      row: TData;
      context: TContext;
    }) => ReactNode;
    formatter?: (value: ValueType, iteratorInfo: IteratorInfo<TIterator>) => string;
  };

export type ColumnConfig<TData, TContext, TIterator = unknown> =
  | StaticColumnConfig<TData, TContext>
  | DynamicColumnConfig<TData, TContext, TIterator>;

export type ActionPayload<TData, TContext> = { row: TData; context: TContext };

export type ActionComponentType<TData, TContext> = ComponentType<ActionPayload<TData, TContext>>;

export interface ColumnAction<TData, TContext> {
  Button: ActionComponentType<TData, TContext>;
  isVisible: (payload: ActionPayload<TData, TContext>) => boolean;
}

export interface ColumnsHiddenChangesEvent {
  type: 'columnsHidden';
  columnsHidden: string[];
}

export type ColumnsEvents = ColumnsHiddenChangesEvent;

const getTextWidth = (text: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
};

export class ColumnHandler<TData, TContext> {
  #columns: Column<TData>[] = [];

  #hidden: Set<string> = new Set([]);

  #ids: Partial<Record<string, Column<TData>>> = {};

  #dynamicColumnConfigs: Record<string, DynamicColumnConfig<TData, TContext, unknown>> = {};

  #context: TContext;

  #locale: Locale;

  #actions: Record<string, ColumnAction<TData, unknown>> = {};

  #defaultFormatters: DefaultsFormatters = {
    string: value => value as string,
    array: value => (Array.isArray(value) ? value.join(', ') : (value as string)),
    boolean: value => defaultFormatters.boolean(value, '-'),
    date: (value, format?: string) =>
      !format
        ? defaultFormatters.date(value, this.#locale, '')
        : dateFormatter({
            value,
            format,
            defaultValue: '',
            locale: this.#locale,
            exceptionOnInvalidCast: true,
          }),
    dateTime: value => defaultFormatters.dateTime(value, this.#locale, ''),
    number: value => defaultFormatters.number(value, this.#locale, '-'),
    percent: value => defaultFormatters.percent(value, this.#locale, '-'),
  };

  #defaultRenderCells: Partial<Record<ColumnType, (value: ValueType) => ReactNode>> = {
    boolean: value =>
      value
        ? createElement(CheckIcon, { fontSize: 'small' }, null)
        : createElement(ClearIcon, { fontSize: 'small' }, null),
  };

  #eventHandler: EventHandler<ColumnsEvents>;

  constructor(context: TContext, eventHandler: EventHandler<ColumnsEvents>, locale: Locale) {
    this.#context = context;
    this.#locale = locale;
    this.#eventHandler = eventHandler;
  }

  private static getDefaultWidth(text: string): ColumnWidth {
    const px = getTextWidth(text);
    const pt = (3 / 4) * px;

    return { px, pt };
  }

  private static getDefaultAlign(type: ColumnType) {
    const alignRight: ColumnType[] = ['number', 'percent'];
    const alignCenter: ColumnType[] = ['date', 'dateTime', 'boolean'];

    if (alignRight.includes(type)) return 'right';
    if (alignCenter.includes(type)) return 'center';

    return 'left';
  }

  private getDefaultRenderCell(
    type: ColumnType,
    value: ValueType,
    render: (v: ValueType) => ReactNode
  ) {
    const def = this.#defaultRenderCells[type];
    if (def) return def(value);
    return render(value);
  }

  private buildValueFormatted(config: StaticColumnConfig<TData, TContext>) {
    const { type, formatter } = config;
    if (formatter) return formatter;
    const format = 'format' in config ? config.format : undefined;
    return (value: ValueType) => this.#defaultFormatters[type](value, format);
  }

  private buildStaticColumn(config: StaticColumnConfig<TData, TContext>): Column<TData> {
    const { type, title, defaultHidden = false, renderCell, visibilityTogglingDisabled } = config;
    const id = 'name' in config ? config.name : (config.accessor as string);
    const align = config.align ? config.align : ColumnHandler.getDefaultAlign(type);

    const isAvailable = () => (config.available ? config.available(this.#context) : true);
    const isHidden = () => this.#hidden.has(id);

    const getTitle = () => title(this.#context);
    const getValue = (row: TData) =>
      'valueGetter' in config
        ? config.valueGetter(row, this.#context)
        : (row[config.accessor] as unknown as ValueType);
    const getValueFormatted = this.buildValueFormatted(config);

    const getCell = (row: TData) =>
      renderCell
        ? renderCell(getValue(row), row, this.#context)
        : this.getDefaultRenderCell(type, getValue(row), getValueFormatted);

    const getWidth = () =>
      config.width ? config.width : ColumnHandler.getDefaultWidth(getTitle());

    const totalConfig = 'total' in config ? config.total : undefined;

    const getOrder = () =>
      isFunction(config.order) ? config.order(this.#context) : config.order || 0;

    return {
      type,
      id,
      visibilityTogglingDisabled,
      align,
      totalConfig,
      defaultHidden,
      getWidth,
      getCell,
      getTitle,
      getValue,
      getValueFormatted,
      isHidden,
      isAvailable,
      getOrder,
    };
  }

  private buildDynamicColumn<TIterator>(
    config: DynamicColumnConfig<TData, TContext, TIterator>
  ): Column<TData>[] {
    const { iterator: values = [], title, type } = config;
    const id = 'name' in config ? config.name : (config.accessor as string);
    this.#dynamicColumnConfigs[id] = config as never;
    return values.map((value, index) => {
      const iteratorInfo = { value, index, values };

      const align = isFunction(config.align) ? config.align(iteratorInfo) : config.align;
      const width = isFunction(config.width) ? config.width(iteratorInfo) : config.width;
      const available = isFunction(config.available) ? config.available : undefined;
      const defaultHidden = isFunction(config.defaultHidden)
        ? config.defaultHidden(iteratorInfo)
        : config.defaultHidden;
      const total = 'total' in config ? config.total : undefined;
      const formatter = 'formatter' in config && config.formatter ? config.formatter : undefined;
      const visibilityTogglingDisabled =
        'visibilityTogglingDisabled' in config ? config.visibilityTogglingDisabled : undefined;
      const valueGetter = (row: TData, context: TContext) => {
        if ('valueGetter' in config) return config.valueGetter(iteratorInfo, row, context);
        const array = row[config.accessor];
        return Array.isArray(array) ? array[index] : array;
      };
      const renderCell = config.renderCell ? config.renderCell : undefined;

      return this.buildStaticColumn({
        name: `${id}[${index}]`,
        title: context => title(iteratorInfo, context),
        type: type as never,
        valueGetter,
        align,
        defaultHidden,
        width,
        available: available ? context => available(iteratorInfo, context) : undefined,
        formatter: formatter ? value => formatter(value, iteratorInfo) : undefined,
        total: total ? total(iteratorInfo) : total,
        renderCell: renderCell
          ? (value, row, context) => renderCell({ iteratorInfo, value, row, context })
          : undefined,
        visibilityTogglingDisabled: isFunction(visibilityTogglingDisabled)
          ? visibilityTogglingDisabled(iteratorInfo)
          : visibilityTogglingDisabled,
      });
    });
  }

  static isVisible<TData>(column: Column<TData>) {
    return column.isAvailable() && !column.isHidden();
  }

  static compare<TData>(a: TData, b: TData, column: Column<TData>) {
    const valueA = column.getValue(a);
    const valueB = column.getValue(b);
    if (valueA === valueB) return 0;
    if (!valueA) return 1;
    if (!valueB) return -1;

    switch (column.type) {
      case 'number':
        return Number(valueA) - Number(valueB);
      case 'percent':
        return Number(valueA) - Number(valueB);
      case 'date':
        return compareAsc(valueA as Date, valueB as Date);
      case 'dateTime':
        return compareAsc(valueA as Date, valueB as Date);
      default:
        return String(valueA).localeCompare(String(valueB));
    }
  }

  get columns() {
    return [...this.#columns].sort((a, b) => a.getOrder() - b.getOrder());
  }

  get hidden() {
    return this.#hidden;
  }

  get actions() {
    return { ...this.#actions };
  }

  add<TIterator>(config: ColumnConfig<TData, TContext, TIterator>) {
    const columns =
      'isDynamic' in config && config.isDynamic
        ? this.buildDynamicColumn(config)
        : [this.buildStaticColumn(config)];

    columns.forEach(column => {
      this.#columns.push(column);
      this.#ids[column.id] = column;
      if (column.defaultHidden) this.#hidden.add(column.id);
    });
  }

  addAction<THandler>(action: ColumnAction<TData, THandler>) {
    const id = `action_${Object.keys(this.#actions).length + 1}`;
    this.#actions[id] = action as ColumnAction<TData, unknown>;
  }

  setContext(context: TContext) {
    this.#context = context;
  }

  getVisibleColumns() {
    return this.#columns.filter(ColumnHandler.isVisible);
  }

  toggleVisibility(ids: string[]) {
    ids
      .filter(i => !this.#ids[i]?.visibilityTogglingDisabled)
      .forEach(i => {
        if (this.#hidden.has(i)) this.#hidden.delete(i);
        else this.#hidden.add(i);
      });
    this.#eventHandler.notify({ type: 'columnsHidden', columnsHidden: [...this.#hidden] });
  }

  getById(id: string) {
    if (id in this.#ids) return this.#ids[id] as Column<TData>;
    throw Error(`No existe una columna con id '${id}'`);
  }

  getColumnWithTotals() {
    return this.#columns
      .filter(i => i.isAvailable())
      .flatMap(i => (i.totalConfig ? [{ ...i, totalConfig: i.totalConfig }] : []));
  }

  updateIterator<TIterator>(id: string, iterator: TIterator[]) {
    const config = this.#dynamicColumnConfigs[id];
    if (!config) throw new Error(`El id '${id}' no existe como columna dinámica`);

    const reg = new RegExp(`^${id}\\[\\d+\\]$`);
    this.#columns = this.#columns.filter(c => !reg.test(c.id));
    this.#ids = this.#columns.reduce((ids, col) => ({ ...ids, [col.id]: col }), {});

    this.add({ ...config, iterator });
  }
}
