import { useMemo, useCallback, useState, useEffect } from 'react';
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Collapse, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useFlexSalesForm } from './FlexSalesForm';
import { SelectFlexSales, SelectFlexSalesProps } from '../selects/SelectFlexSales';

export type CategorySelectFormInputProps<
  TValue,
  TFieldValues extends FieldValues = FieldValues
> = Omit<
  SelectFlexSalesProps<TValue, undefined, true, undefined>,
  'renderGroup' | 'name' | 'onChange'
> & {
  validation?: ControllerProps<TFieldValues>['rules'];
  name: Path<TFieldValues>;
  required?: boolean;
  fieldValue?: keyof TValue;
};

export const NestedList = ({
  group,
  item,
  init,
  ...props
}: {
  group: string;
  item: React.ReactNode;
  init: boolean;
}) => {
  const [open, setOpen] = useState(init);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List {...props}>
      <Divider />
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={group} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText primary={item} />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
};

const CategorySelectFormInput = <TValue, TFieldValues extends FieldValues = FieldValues>({
  name,
  defaultValue,
  validation,
  required,
  label,
  fieldValue,
  ...rest
}: CategorySelectFormInputProps<TValue, TFieldValues>) => {
  const { control } = useFlexSalesForm<TFieldValues>();

  const rules = useMemo(
    () => (required ? { required: true, ...validation } : validation),
    [required, validation]
  );
  const render: ControllerProps<TFieldValues>['render'] = useCallback(
    ({ field: { ref, onChange, ...restField }, fieldState: { error } }) => (
      <SelectFlexSales
        label={label}
        onChange={value =>
          onChange(typeof value === 'string' || !fieldValue ? value : value[fieldValue])
        }
        {...restField}
        {...rest}
        renderGroup={params =>
          params.group ? (
            <NestedList key={params.key} group={params.group} item={params.children} init={false} />
          ) : (
            params.children
          )
        }
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            variant="standard"
            required={required}
            error={Boolean(error)}
            name={name}
            helperText={error?.message}
            inputRef={ref}
          />
        )}
      />
    ),
    [label, rest, required]
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as never}
      rules={rules}
      render={render}
    />
  );
};

export default CategorySelectFormInput;
