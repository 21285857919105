import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { GroupingState, IntegratedGrouping, Column } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  Toolbar,
  ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { writeFile, utils } from 'xlsx';
import { useMuiPickersLocale } from '../hooks';
import { Closing } from '../types';
import { onlyUnique } from '../helpers';

const grouping = [{ columnName: 'date' }];

interface DashboardClosingDayProps {
  country: string;
  data: Closing[];
  startDate: Date;
  endDate: Date;
}

const DashboardClosingDay = ({ data, startDate, endDate, country }: DashboardClosingDayProps) => {
  const { t } = useTranslation();
  const locale = useMuiPickersLocale();

  const title = t('dashboard.incorporation.titles.missingList');

  const startExport = useCallback(() => {
    const dataXlsx = data.map(i => ({
      [t('missing-closing-causes.columns.store')]: i.store,
      [t('missing-closing-causes.columns.date')]: i.date,
      [t('missing-closing-causes.columns.cause')]: i.cause
        .map(j => t(`missing-closing-detail.causes.${j}`))
        .join(', '),
    }));
    const worksheet = utils.json_to_sheet(dataXlsx);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, title);
    const fileName = `${title}_${country}_${format(startDate, 'yyyyMMdd')}_${format(
      endDate,
      'yyyyMMdd'
    )}.xlsx`.replace('/', '');

    writeFile(workbook, fileName, { cellDates: true });
  }, [t, data, title, country, startDate, endDate]);

  const [columns] = useState<Column[]>([
    { name: 'store', title: t('missing-closing-causes.columns.store') },
    {
      name: 'date',
      title: t('missing-closing-causes.columns.date'),
      getCellValue: (r, cn) => format(r[cn], 'P', { locale }),
    },
    {
      name: 'cause',
      title: t('missing-closing-causes.columns.cause'),
      getCellValue: (r: Closing) =>
        r.cause.map(i => t(`missing-closing-detail.causes.${i}`)).join(', '),
    },
  ]);

  const messages = useMemo(
    () => ({
      showExportMenu: t('navigation.buttons.download'),
      exportAll: t('toolbarExportButton.labels.xlsxFormat'),
    }),
    [t]
  );

  const defaultExpandedGroups = useMemo(
    () => onlyUnique(data.map(i => format(i.date, 'P', { locale }))),
    [data, locale]
  );

  return (
    <Grid rows={data} columns={columns}>
      <GroupingState grouping={grouping} defaultExpandedGroups={defaultExpandedGroups} />
      <IntegratedGrouping />
      <Table />
      <TableHeaderRow />
      <TableGroupRow />
      <Toolbar />
      <ExportPanel startExport={startExport} messages={messages} />
    </Grid>
  );
};

export default DashboardClosingDay;
