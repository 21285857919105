import { ReactNode } from 'react';
import { ColumnHandler, Column, ValueType, TotalConfig } from './ColumnHandler';
import { EventHandler } from './EventHandler';

export interface Sorting<TData> {
  column: Column<TData> | undefined;
  ascending: boolean;
}

export interface Cell<TData> extends Omit<Column<TData>, 'getValue' | 'getCell'> {
  getValue: () => ValueType;
  getValueFormatted: () => string;
  getCell: () => ReactNode;
}

export interface Row<TData> {
  cells: Cell<TData>[];
  item: TData;
}

export interface RowsChangesEvent<TData> {
  type: 'rows';
  rows: Row<TData>[];
  rowsWithVisibleColumns: Row<TData>[];
}

export interface SortingRowsChangeEvent<TData> extends Sorting<TData> {
  type: 'sorting';
  rows: Row<TData>[];
  rowsWithVisibleColumns: Row<TData>[];
}

export type RowsEvents<TData> = RowsChangesEvent<TData> | SortingRowsChangeEvent<TData>;

export type RowTotal<TData> = TotalConfig<TData> & { columnId: string; value: number };

export class RowHandler<TData, TContext> {
  #data: TData[];

  #rows: Row<TData>[];

  #rowsWithVisibleColumns: Row<TData>[];

  #totals: RowTotal<TData>[] = [];

  #columnHandler: ColumnHandler<TData, TContext>;

  #eventHandler: EventHandler<RowsEvents<TData>>;

  #sorting: Sorting<TData> = { column: undefined, ascending: true };

  constructor(
    data: TData[],
    columnHandler: ColumnHandler<TData, TContext>,
    eventHandler: EventHandler<RowsEvents<TData>>
  ) {
    this.#data = data;
    this.#columnHandler = columnHandler;
    this.#eventHandler = eventHandler;
    this.#rows = [];
    this.#rowsWithVisibleColumns = [];
  }

  private getInitialSum(): Record<string, number> {
    return this.#columnHandler.getColumnWithTotals().reduce((sum, { id, totalConfig: config }) => {
      if (config.type === 'avg' && 'amount' in config)
        return { ...sum, [config.amount as string]: 0, [config.quantity as string]: 0 };

      return { ...sum, [id]: 0 };
    }, {} as Record<string, number>);
  }

  private static ensureDivision(dividend: number, divisor: number) {
    return divisor ? dividend / divisor : 0;
  }

  private buildTotals(sums: Record<string, number>): RowTotal<TData>[] {
    return this.#columnHandler.getColumnWithTotals().map(({ id, totalConfig: config }) => {
      const result = (value: number) => ({ ...config, value, columnId: id });
      const { type } = config;
      if (type === 'sum') return result(sums[id]);
      if (type === 'avg') {
        if ('amount' in config)
          return result(
            RowHandler.ensureDivision(
              sums[config.amount as string],
              sums[config.quantity as string]
            )
          );
        return result(RowHandler.ensureDivision(sums[id], this.#rows.length));
      }
      throw new Error(`El tipo '${type}' no tiene definido el cálculo del total`);
    });
  }

  private setTotals(sums: Record<string, number>) {
    this.#totals = this.#rows.length ? this.buildTotals(sums) : [];
  }

  get sorting() {
    return this.#sorting;
  }

  get totals() {
    return [...this.#totals];
  }

  setRows() {
    const rows: Row<TData>[] = [];
    const rowsWithVisibleColumns: Row<TData>[] = [];
    const sums: Record<string, number> = this.getInitialSum();

    const { column: sortingColumn } = this.#sorting;
    const data = sortingColumn
      ? [...this.#data].sort((a, b) => ColumnHandler.compare(a, b, sortingColumn))
      : this.#data;

    data.forEach(item => {
      const cells: Cell<TData>[] = [];
      const cellsVisibles: Cell<TData>[] = [];

      this.#columnHandler.columns.forEach(col => {
        const { getValue, getCell, getValueFormatted, ...rest } = col;
        cells.push({
          ...rest,
          getValue: () => getValue(item),
          getCell: () => getCell(item),
          getValueFormatted: () => getValueFormatted(getValue(item)),
        });

        if (ColumnHandler.isVisible(col)) cellsVisibles.push(cells[cells.length - 1]);
        if (col.id in sums) {
          const valNum = Number(getValue(item));
          if (Number.isNaN(valNum))
            throw new Error(`Columna ${col.id} necesaria para calcular los totales no numérica`);
          sums[col.id as string] += valNum;
        }
      });
      rows.push({ cells, item });
      rowsWithVisibleColumns.push({ cells: cellsVisibles, item });
    });

    this.#rows = rows;
    this.#rowsWithVisibleColumns = rowsWithVisibleColumns;
    this.setTotals(sums);
  }

  setData(data: TData[]) {
    if (this.#data !== data && (this.#data.length || data.length)) {
      this.#data = data;
      this.setRows();
      this.#eventHandler.notify({
        type: 'rows',
        rows: [...this.#rows],
        rowsWithVisibleColumns: [...this.#rowsWithVisibleColumns],
      });
    }
  }

  getRows(visibleColumns = false) {
    if (visibleColumns) return this.#rowsWithVisibleColumns;
    return this.#rows;
  }

  setSorting(columnId: string | undefined, ascending: boolean | undefined = undefined) {
    const column = columnId ? this.#columnHandler.getById(columnId) : undefined;
    const currentColumnId = column ? column.id : undefined;
    const asc = ascending === undefined ? this.#sorting.ascending : ascending;
    if (columnId !== currentColumnId && asc !== this.#sorting.ascending) {
      this.#sorting = { column, ascending: asc };
      this.setRows();
      this.#eventHandler.notify({
        type: 'sorting',
        rows: [...this.#rows],
        rowsWithVisibleColumns: [...this.#rowsWithVisibleColumns],
        ...this.#sorting,
      });
    }
  }
}
