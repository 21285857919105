import { Action } from 'redux';
import { ThemePreference } from '../../types';

export interface State {
  theme: ThemePreference;
  showSaveDialog: boolean;
  serviceWorkerUpdated: boolean;
  serviceWorkerRegistration?: ServiceWorkerRegistration;
}

export enum ActionType {
  changeTheme = 'preferences/changeTheme',
  changeShowSaveDialog = 'preferences/changeShowSaveDialog',
  updateAvailable = 'preferences/updateAvailable',
}

export interface ChangeThemeAction extends Action<ActionType.changeTheme> {
  theme: ThemePreference;
}

export interface ChangeShowSaveDialogAction extends Action<ActionType.changeShowSaveDialog> {
  showSaveDialog: boolean;
}

export interface UpdateAvailable extends Action<ActionType.updateAvailable> {
  registration: ServiceWorkerRegistration;
}

export type Actions = ChangeThemeAction | ChangeShowSaveDialogAction | UpdateAvailable;
