import styled from '@emotion/styled';
import { PettyCashDifferenceByPaymentTypeDataGrid, PettyCashDifferenceSwitch } from '../components';
import FiltersLayoutHandler from '../components/FiltersLayoutHandler';
import PettyCashDifferenceByPaymentTypeSearch from '../components/PettyCashDifferenceByPaymentTypeSearch';
import { reportPettyCashDifferenceByPaymentTypeLoadingSelector } from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PettyCashDifferenceByPaymentTypeSearchContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const PettyCashDifferenceByPaymentType = () => (
  <Container>
    <PettyCashDifferenceSwitch checked />
    <PettyCashDifferenceByPaymentTypeSearchContainer>
      <FiltersLayoutHandler selector={reportPettyCashDifferenceByPaymentTypeLoadingSelector}>
        <PettyCashDifferenceByPaymentTypeSearch />
      </FiltersLayoutHandler>
    </PettyCashDifferenceByPaymentTypeSearchContainer>
    <PettyCashDifferenceByPaymentTypeDataGrid />
  </Container>
);

export default PettyCashDifferenceByPaymentType;
