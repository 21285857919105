import { useCallback } from 'react';
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import { useFlexSalesForm } from './FlexSalesForm';
import SimpleSelect, { SimpleSelectProps } from '../selects/SimpleSelect';

type SimpleSelectComponentProps = Omit<SimpleSelectProps, 'name' | 'onChange'>;

export type SimpleSelectFormInputProps<TFieldValues extends FieldValues = FieldValues> =
  SimpleSelectComponentProps & {
    defaultValue?: string;
    name: Path<TFieldValues>;
  };

const SimpleSelectFormInput = <TFieldValues extends FieldValues = FieldValues>({
  name,
  defaultValue,
  required,
  value,
  ...rest
}: SimpleSelectFormInputProps<TFieldValues>): JSX.Element => {
  const { control } = useFlexSalesForm<TFieldValues>();

  const render: ControllerProps<TFieldValues>['render'] = useCallback(
    ({ field: { ref, ...restField }, fieldState: { error } }) => (
      <SimpleSelect
        {...restField}
        {...rest}
        name={name}
        error={Boolean(error)}
        helperText={error?.message}
        required={required}
        inputRef={ref}
      />
    ),
    [name, rest, required]
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as never}
      render={render}
    />
  );
};

export default SimpleSelectFormInput;
