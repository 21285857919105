import { createContext, ReactNode, useContext, useMemo } from 'react';
import { AnyAction } from 'redux';
import { AppState, reportFilterAppliedSelector } from '../../state';
import { DiscountsJoin, FilterApplied } from '../../types';

interface GroupByProps<TGroupBy> {
  options: TGroupBy extends string ? Record<TGroupBy, boolean> : undefined;
  selector: (state: AppState) => Array<TGroupBy>;
  action: TGroupBy extends string ? (groupBy: TGroupBy[]) => AnyAction : undefined;
}

export interface DiscountsProps {
  options: Record<DiscountsJoin, boolean>;
  selector: (state: AppState) => DiscountsJoin[];
  action: (discountsJoin: DiscountsJoin[]) => AnyAction;
  showJoins?: boolean;
}

export interface DialogFiltersProps {
  options: FilterApplied;
  selector: (state: AppState) => FilterApplied;
  filters: ReactNode;
}

interface ReportFiltersContextType<TGroupBy> {
  groupByConfig?: GroupByProps<TGroupBy>;
  discountsConfig?: DiscountsProps;
  dialogFiltersConfig?: DialogFiltersProps;
  loadingSelector: (state: AppState) => boolean;
  applyAction: (() => AnyAction) | (() => AnyAction[]);
  onedriveAction?: () => AnyAction;
  showOpStructureFilters?: boolean;
}

const initialState: ReportFiltersContextType<unknown> = {
  groupByConfig: undefined,
  discountsConfig: undefined,
  dialogFiltersConfig: {
    options: [],
    selector: reportFilterAppliedSelector,
    filters: undefined,
  },
  loadingSelector: () => false,
  applyAction: () => ({ type: '' }),
  onedriveAction: () => ({ type: '' }),
  showOpStructureFilters: false,
};

const ReportFiltersContext = createContext<ReportFiltersContextType<unknown>>(initialState);

export interface ReportFiltersProviderProps<TGroupBy> {
  children: ReactNode;
  groupByConfig?: ReportFiltersContextType<TGroupBy>['groupByConfig'];
  discountsConfig?: ReportFiltersContextType<TGroupBy>['discountsConfig'];
  dialogFiltersConfig?: ReportFiltersContextType<TGroupBy>['dialogFiltersConfig'];
  loadingSelector: ReportFiltersContextType<TGroupBy>['loadingSelector'];
  applyAction: ReportFiltersContextType<TGroupBy>['applyAction'];
  onedriveAction?: ReportFiltersContextType<TGroupBy>['onedriveAction'];
  showOpStructureFilters?: ReportFiltersContextType<TGroupBy>['showOpStructureFilters'];
}

const ReportFiltersProvider = <TGroupBy extends string | unknown>({
  groupByConfig,
  discountsConfig,
  dialogFiltersConfig,
  loadingSelector,
  applyAction,
  onedriveAction,
  showOpStructureFilters,
  ...others
}: ReportFiltersProviderProps<TGroupBy>) => {
  const value = useMemo(
    () => ({
      groupByConfig,
      discountsConfig,
      dialogFiltersConfig,
      loadingSelector,
      applyAction,
      onedriveAction,
      showOpStructureFilters,
    }),
    [
      groupByConfig,
      discountsConfig,
      dialogFiltersConfig,
      loadingSelector,
      applyAction,
      onedriveAction,
      showOpStructureFilters,
    ]
  );

  return <ReportFiltersContext.Provider value={value} {...others} />;
};

export const useReportFilters = <TGroupBy extends string>() => {
  const context = useContext(ReportFiltersContext) as ReportFiltersContextType<TGroupBy>;

  if (context === undefined)
    throw new Error('useReportFilters debe estar dentro de ReportFiltersContext.Provider');

  return context;
};

export default ReportFiltersProvider;
