import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { ClosingDataGrid, ClosingSearch } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const Closing = () => (
  <>
    <Container>
      <ClosingSearch />
      <ClosingDataGrid />
    </Container>
    <Outlet />
  </>
);

export default Closing;
