import { ReactNode, useMemo } from 'react';
import { Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Template, Plugin, TemplateConnector, Getter, ComputedFn } from '@devexpress/dx-react-core';
import { TableColumn } from '@devexpress/dx-react-grid';
import TableCell from '@mui/material/TableCell';
import { VirtualTableCellStyled } from './Cells';
import { ColumnAction, DataGridHandler } from '../DataGridHandler';
import { useDataGridFlexSales } from '../DataGridFlexSalesProvider';

export interface ActionColumn<TData = unknown, TContext = unknown>
  extends ColumnAction<TData, TContext> {
  columnId: string;
}

export interface ActionColumnsProps {
  actions: ActionColumn[] | undefined;
}
const ACTION_COLUMN_TYPE = Symbol('ACTION_COLUMN');

const dependencies = [{ name: 'VirtualTable' }];

const getComputeColumns =
  (actionColumns: Record<string, ActionColumn>): ComputedFn =>
  getters => {
    const tableColumns = getters.tableColumns as TableColumn[];
    return tableColumns.map(tableColumn => {
      if (!tableColumn.column || !actionColumns[tableColumn.column.name]) return tableColumn;
      return { ...tableColumn, type: ACTION_COLUMN_TYPE, width: 40 };
    });
  };

const isActionTableHeader = (props: object) => {
  const { tableRow, tableColumn } = props as Table.CellProps;
  return tableRow.type === TableHeaderRow.ROW_TYPE && tableColumn.type === ACTION_COLUMN_TYPE;
};

const isActionTableCell = (props: object) => {
  const { tableRow, tableColumn } = props as Table.CellProps;
  return tableRow.type === Table.ROW_TYPE && tableColumn.type === ACTION_COLUMN_TYPE;
};

const getRenderCell =
  (handler: DataGridHandler<unknown, unknown>, actionColumns: Record<string, ActionColumn>) =>
  ({ tableColumn, tableRow, ...rest }: Table.DataCellProps) =>
    (
      <TemplateConnector>
        {() => {
          const { Button, isVisible } = actionColumns[tableColumn.column?.name || ''];
          const row = tableRow.row.ORIGINAL_ITEM;
          const { context } = handler;
          const styles = { textAlign: 'center' as const };
          return isVisible({ row, context }) ? (
            <VirtualTableCellStyled
              style={styles}
              tableColumn={tableColumn}
              tableRow={tableRow}
              {...rest}
            >
              <Button row={row} context={context} />
            </VirtualTableCellStyled>
          ) : (
            <TableCell />
          );
        }}
      </TemplateConnector>
    );

const ActionColumns = ({ actions }: ActionColumnsProps) => {
  const actionsDict = useMemo(
    () => (actions || []).reduce((r, c) => ({ ...r, [c.columnId]: c }), {}),
    [actions]
  );
  const { handler } = useDataGridFlexSales();
  const computeColumns: ComputedFn = useMemo(() => getComputeColumns(actionsDict), [actionsDict]);
  const renderCell = useMemo(() => getRenderCell(handler, actionsDict), [handler, actionsDict]);
  return (
    <Plugin name="ActionColumns" dependencies={dependencies}>
      <Getter name="tableColumns" computed={computeColumns} />
      <Template name="tableCell" predicate={isActionTableHeader}>
        {() => <TemplateConnector>{() => <TableCell />}</TemplateConnector>}
      </Template>
      <Template name="tableCell" predicate={isActionTableCell}>
        {renderCell as unknown as ReactNode}
      </Template>
    </Plugin>
  );
};

export default ActionColumns;
