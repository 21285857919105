import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import {
  reprocessFilterCountryCodeSelector,
  reprocessReprocessLoadingSelector,
  reprocessFindRequested,
  activeCountriesSelector,
} from '../../state';
import { LoadingButton } from '../buttons';
import { ReprocessFilters } from './ReprocessFilters';

const ActionContainer = styled.div`
  display: flex;
  justify-content: right;
  > button:first-of-type {
    margin-right: ${props => props.theme.spacing(2)};
  }
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: center;
  }
`;

const ButtonApply = () => {
  const dispatch = useDispatch();
  const handleClickApply = useCallback(() => dispatch(reprocessFindRequested()), [dispatch]);
  const { t } = useTranslation();
  const loading = useSelector(reprocessReprocessLoadingSelector);
  const hasCountry = Boolean(useSelector(reprocessFilterCountryCodeSelector));
  const disabled = !hasCountry;
  return (
    <LoadingButton
      color="primary"
      variant="contained"
      onClick={handleClickApply}
      loading={loading}
      disabled={disabled}
    >
      {t('navigation.buttons.apply')}
    </LoadingButton>
  );
};

const ButtonCreate = () => {
  const navigate = useNavigate();
  const handleClickApply = useCallback(
    () => navigate(routes.protectedNestedRoutes.reprocess.children.create.path),
    [navigate]
  );
  const { t } = useTranslation();
  const { loaded } = useSelector(activeCountriesSelector);
  const disabled = !loaded;

  return (
    <Button variant="contained" onClick={handleClickApply} disabled={disabled}>
      {t('reprocess.labels.create_reprocess')}
    </Button>
  );
};

const ReprocessSearch = () => {
  const actions = (
    <ActionContainer>
      <ButtonCreate />
      <ButtonApply />
    </ActionContainer>
  );
  return <ReprocessFilters actions={actions} />;
};

export default ReprocessSearch;
