import { getStatusColor } from '../../helpers';
import { MasterStatusDetail } from '../../types';
import { DataGridHandler } from '../DataGridFlexSales';

export interface MastersStatusDataGridContext {
  t: (key: string | string[]) => string;
}

const initialContext: MastersStatusDataGridContext = {
  t: () => '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<MasterStatusDetail, MastersStatusDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'creationDate',
    type: 'date',
    title: ({ t }) => t('masters-status.columns.creationDate'),
  });

  handler.addColumn({
    accessor: 'masterType',
    type: 'string',
    title: ({ t }) => t('masters-status.columns.masterType'),
  });

  handler.addColumn({
    accessor: 'registerQty',
    type: 'number',
    title: ({ t }) => t('masters-status.columns.registerQty'),
  });

  handler.addColumn({
    name: 'status',
    type: 'string',
    title: ({ t }) => t('masters-status.columns.status'),
    valueGetter: row => row.status,
    renderCell: (_value, row, { t }) => (
      <div style={{ color: getStatusColor(row.status), fontWeight: 'bold' }}>
        {t(`masters-status.status.${row.status}`)}
      </div>
    ),
    width: { px: 140, pt: 95 },
  });

  handler.addColumn({
    accessor: 'error',
    type: 'string',
    title: ({ t }) => t('masters-status.columns.error'),
    width: { px: 100, pt: 95 },
  });

  return handler;
};
