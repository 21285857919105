import { Action } from 'redux';
import {
  MasterData,
  RemoteResource,
  HttpErrorBaseAction,
  Country,
  AppParameters,
} from '../../types';

export interface State {
  masterData: RemoteResource<MasterData | undefined>;
  activeCountries: RemoteResource<Country[]>;
  parameters: RemoteResource<AppParameters | undefined>;
}

export enum ActionType {
  masterDataRequested = 'parameters/masterDataRequested',
  masterDataSuccess = 'parameters/masterDataSuccess',
  masterDataFailure = 'parameters/masterDataFailure',
  activeCountriesRequested = 'parameters/activeCountriesRequested',
  activeCountriesSuccess = 'parameters/activeCountriesSuccess',
  activeCountriesFailure = 'parameters/activeCountriesFailure',
  parametersRequested = 'parameters/parametersRequested',
  parametersSuccess = 'parameters/parametersSuccess',
  parametersFailure = 'parameters/parametersFailure',
}

export type MasterDataRequestedAction = Action<ActionType.masterDataRequested>;

export type MasterDataSuccessAction = Action<ActionType.masterDataSuccess> & {
  masterData: MasterData;
};

export type MasterDataFailureAction = HttpErrorBaseAction<ActionType.masterDataFailure>;

export type ActiveCountriesRequestedAction = Action<ActionType.activeCountriesRequested>;

export type ActiveCountriesSuccessAction = Action<ActionType.activeCountriesSuccess> & {
  activeCountries: Country[];
};

export type ActiveCountriesFailureAction = HttpErrorBaseAction<ActionType.activeCountriesFailure>;

export type ParametersRequestedAction = Action<ActionType.parametersRequested>;

export type ParametersSuccessAction = Action<ActionType.parametersSuccess> & {
  parameters: AppParameters;
};

export type ParametersFailureAction = HttpErrorBaseAction<ActionType.parametersFailure>;

export type Actions =
  | MasterDataRequestedAction
  | MasterDataSuccessAction
  | MasterDataFailureAction
  | ActiveCountriesRequestedAction
  | ActiveCountriesSuccessAction
  | ActiveCountriesFailureAction
  | ParametersRequestedAction
  | ParametersSuccessAction
  | ParametersFailureAction;
