import { AppNotification } from '../../types';
import { ActionType, DequeueNotificationAction, EnqueueNotificationAction } from './types';

export const enqueueNotification = (
  notification: AppNotification | AppNotification[]
): EnqueueNotificationAction => ({ type: ActionType.enqueueNotification, notification });

export const dequeueNotification = (
  notificationId: string | string[]
): DequeueNotificationAction => ({ type: ActionType.dequeueNotification, notificationId });
