import { useSelector } from 'react-redux';
import { userPermissionsDataSelector } from '../state';
import { AppPermissions, Permission, Stores } from '../types';
import useStoreSelect from './useStoreSelect';

export const getAuthorizedStores = (
  resource: AppPermissions[],
  permissions: Permission[],
  countryCode: string
) => {
  const authorizedStores: string[] = [];
  resource.forEach(r => {
    const permission = permissions.find(i => i.permission === r);
    if (permission) {
      permission.countries
        .find(i => i.countryAcronym === countryCode)
        ?.stores.forEach(s => authorizedStores.push(s));
    }
  });
  return authorizedStores;
};

const useStorePermissions = (
  resource: AppPermissions[],
  countryCode: string,
  storesOptions: Stores[] = []
) => {
  const permissions = useSelector(userPermissionsDataSelector);
  const { options } = useStoreSelect(countryCode);
  const authorizedStores: (string | undefined)[] = getAuthorizedStores(
    resource,
    permissions,
    countryCode
  );
  if (storesOptions.length > 0) {
    if (authorizedStores.length > 0) {
      const filteredStoresOptions = storesOptions.filter(i =>
        authorizedStores.includes(i.storeAcronym)
      );
      return filteredStoresOptions;
    }
    return storesOptions;
  }

  if (authorizedStores.length > 0) {
    const filteredOptions = options.filter(i => authorizedStores.includes(i.description));
    return filteredOptions;
  }

  return options;
};

export default useStorePermissions;
