import Grid from '@mui/material/Grid';
import TextFieldMui from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { AccountingParameter } from '../../types';
import FrequencySelectFormInput from './FrequencySelectFormInput';
import { useContryName } from '../../hooks';

const inputPropsReadOnly = { readOnly: true };

interface CompanyCodeEditFormProps {
  accountingParameter: AccountingParameter;
}

const MasterField = ({ accountingParameter }: CompanyCodeEditFormProps) => {
  const { t } = useTranslation();
  const countryName = useContryName({ countryId: accountingParameter.countryId });

  return (
    <Grid container flexDirection="row" spacing={1}>
      <Grid item xs>
        <TextFieldMui
          type="text"
          variant="standard"
          label={t('accountingParameters.columns.country')}
          value={countryName}
          inputProps={inputPropsReadOnly}
          fullWidth
        />
      </Grid>
      <Grid item xs>
        <TextFieldMui
          type="text"
          variant="standard"
          label={t('accountingParameters.columns.journalType')}
          value={accountingParameter.journalType}
          inputProps={inputPropsReadOnly}
          fullWidth
        />
      </Grid>
      <Grid item xs>
        <TextFieldMui
          type="text"
          variant="standard"
          label={t('accountingParameters.columns.source')}
          value={accountingParameter.source}
          inputProps={inputPropsReadOnly}
          fullWidth
        />
      </Grid>
      <Grid item xs>
        <TextFieldMui
          type="text"
          variant="standard"
          label={t('accountingParameters.columns.category')}
          value={accountingParameter.category}
          inputProps={inputPropsReadOnly}
          fullWidth
        />
      </Grid>
      <Grid item xs>
        <TextFieldMui
          type="text"
          variant="standard"
          label={t('accountingParameters.columns.categoryAlias')}
          value={accountingParameter.categoryAlias || ''}
          inputProps={inputPropsReadOnly}
          fullWidth
        />
      </Grid>
      <Grid item xs>
        <FrequencySelectFormInput
          name="frequency"
          label={t('accountingParameters.columns.frequency')}
          required
        />
      </Grid>
    </Grid>
  );
};

export default MasterField;
