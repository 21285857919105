import { Action } from 'redux';
import {
  HttpErrorBaseAction,
  RemoteResource,
  AccountingEntriesFilter,
  AccountingEntry,
  CreateAccountingEntriesForm,
  DeleteAccountingEntriesForm,
  Country,
  AccountingEntryStatus,
} from '../../types';

export interface State {
  filters: AccountingEntriesFilter;
  accountingEntries: RemoteResource<AccountingEntry[]>;
  downloadingReportId: number | undefined;
  delete: AccountingEntry | undefined;
}

export enum ActionType {
  accountingEntriesChangeFilterCountry = 'accountingEntries/changeFilterCountry',
  accountingEntriesChangeFilterAccountingMonth = 'accountingEntries/changeFilterAccountingMonth',
  accountingEntriesChangeFilterJournalType = 'accountingEntries/changeFilterJournalType',
  accountingEntriesChangeFilterStatus = 'accountingEntries/changeFilterStatus',
  accountingEntriesFindRequested = 'accountingEntries/findRequested',
  accountingEntriesFindSuccess = 'accountingEntries/findSuccess',
  accountingEntriesFindFailure = 'accountingEntries/findFailure',
  accountingEntriesCreateRequested = 'accountingEntries/createRequested',
  accountingEntriesCreateSuccess = 'accountingEntries/createSuccess',
  accountingEntriesCreateFailure = 'accountingEntries/createFailure',
  accountingEntriesReportRequested = 'accountingEntries/reportRequested',
  accountingEntriesReportSuccess = 'accountingEntries/reportSuccess',
  accountingEntriesReportFailure = 'accountingEntries/reportFailure',
  accountingEntriesChangeDelete = 'accountingEntries/changeDelete',
  accountingEntriesDeleteRequested = 'accountingEntries/deleteRequested',
  accountingEntriesDeleteSuccess = 'accountingEntries/deleteSuccess',
  accountingEntriesDeleteFailure = 'accountingEntries/deleteFailure',
}

export interface AccountingEntriesChangeFilterCountryAction
  extends Action<ActionType.accountingEntriesChangeFilterCountry> {
  country: Country | null;
}

export interface AccountingEntriesChangeFilterAccountingMonthAction
  extends Action<ActionType.accountingEntriesChangeFilterAccountingMonth> {
  accountingMonth: Date | null;
}

export interface AccountingEntriesChangeFilterJournalTypeAction
  extends Action<ActionType.accountingEntriesChangeFilterJournalType> {
  journalType: string | null;
}

export interface AccountingEntriesChangeFilterStatusAction
  extends Action<ActionType.accountingEntriesChangeFilterStatus> {
  status: AccountingEntryStatus | null;
}

export type AccountingEntriesFindRequestedAction =
  Action<ActionType.accountingEntriesFindRequested>;
export type AccountingEntriesFindSuccessAction = Action<ActionType.accountingEntriesFindSuccess> & {
  accountingEntries: AccountingEntry[];
};
export type AccountingEntriesFindFailureAction =
  HttpErrorBaseAction<ActionType.accountingEntriesFindFailure>;

export type AccountingEntriesCreateRequestedAction =
  Action<ActionType.accountingEntriesCreateRequested> & {
    payload: CreateAccountingEntriesForm;
  };
export type AccountingEntriesCreateSuccessAction =
  Action<ActionType.accountingEntriesCreateSuccess>;
export type AccountingEntriesCreateFailureAction =
  HttpErrorBaseAction<ActionType.accountingEntriesCreateFailure>;

export type AccountingEntriesReportRequestedAction =
  Action<ActionType.accountingEntriesReportRequested> & {
    id: number;
  };
export type AccountingEntriesReportSuccessAction =
  Action<ActionType.accountingEntriesReportSuccess>;
export type AccountingEntriesReportFailureAction =
  HttpErrorBaseAction<ActionType.accountingEntriesReportFailure>;

export interface AccountingEntriesChangeDeleteAction
  extends Action<ActionType.accountingEntriesChangeDelete> {
  accountingEntry: AccountingEntry | undefined;
}

export type AccountingEntriesDeleteRequestedAction =
  Action<ActionType.accountingEntriesDeleteRequested> & {
    payload: DeleteAccountingEntriesForm;
  };
export type AccountingEntriesDeleteSuccessAction =
  Action<ActionType.accountingEntriesDeleteSuccess>;
export type AccountingEntriesDeleteFailureAction =
  HttpErrorBaseAction<ActionType.accountingEntriesDeleteFailure>;

export type Actions =
  | AccountingEntriesChangeFilterCountryAction
  | AccountingEntriesFindRequestedAction
  | AccountingEntriesFindSuccessAction
  | AccountingEntriesFindFailureAction
  | AccountingEntriesCreateSuccessAction
  | AccountingEntriesCreateFailureAction
  | AccountingEntriesChangeFilterAccountingMonthAction
  | AccountingEntriesChangeFilterJournalTypeAction
  | AccountingEntriesChangeFilterStatusAction
  | AccountingEntriesReportRequestedAction
  | AccountingEntriesReportSuccessAction
  | AccountingEntriesReportFailureAction
  | AccountingEntriesChangeDeleteAction
  | AccountingEntriesDeleteRequestedAction
  | AccountingEntriesDeleteSuccessAction
  | AccountingEntriesDeleteFailureAction;
