import styled from '@emotion/styled';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';

import Skeleton from '@mui/material/Skeleton';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import constants from '../helpers/constants';
import {
  changeFilterCountry,
  changeClosingFilterViewMode,
  findClosingCollectionsRequested,
  findClosingSalesRequested,
  reportClosingSalesSelector,
  reportClosingCollectionsSelector,
} from '../state';
import { useFlexNavigate, useMuiPickersLocale } from '../hooks';

import { DashboardClosingDay, DialogFlexSales } from '../components';
import { ClosingViewMode } from '../types';

const DialogContentStyled = styled(DialogContent)`
  width: 40em;
`;

const startDate = constants.dashboardIncorporationDates.lastMonthFrom;
const endDate = constants.dashboardIncorporationDates.yesterday;

const DashboardClosing = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locale = useMuiPickersLocale();
  const [searchParams] = useSearchParams();
  const country = searchParams.get('country') as string;
  const viewMode = searchParams.get('viewMode') as ClosingViewMode;
  const navigate = useFlexNavigate();
  const pageTitle = t('dashboard.incorporation.titles.missingList');

  useEffect(() => {
    dispatch(changeClosingFilterViewMode(viewMode));
    dispatch(changeFilterCountry(country));

    if (viewMode === ClosingViewMode.SALES) {
      dispatch(findClosingSalesRequested(startDate, endDate));
    }
    if (viewMode === ClosingViewMode.COLLECTIONS) {
      dispatch(findClosingCollectionsRequested(startDate, endDate));
    }
  }, [country, viewMode, dispatch]);

  const selector =
    viewMode === ClosingViewMode.SALES
      ? reportClosingSalesSelector
      : reportClosingCollectionsSelector;

  const { data, loaded, loading } = useSelector(selector);

  const description = useMemo(
    () =>
      t(`dashboard.incorporation.messages.detail_${viewMode}`, {
        country: t(`operativeStructure.countries.${country}`),
        from: format(startDate, 'P', { locale }),
        to: format(endDate, 'P', { locale }),
      }),
    [t, country, locale, viewMode]
  );

  const handleBack = useCallback(() => {
    navigate(r => r.protectedNestedRoutes.dashboard.index.path);
  }, [navigate]);

  return (
    <DialogFlexSales open maxWidth="md">
      <DialogTitle>{pageTitle}</DialogTitle>
      <DialogContentStyled>
        <Typography>{description}</Typography>
        {loaded && (
          <>
            <Typography>
              {t('dashboard.incorporation.messages.totalsMissing', { length: data.length })}
            </Typography>
            <DashboardClosingDay
              country={country}
              data={data}
              startDate={startDate}
              endDate={endDate}
            />
          </>
        )}
        {loading && (
          <>
            <Skeleton />
            <Skeleton />
          </>
        )}
      </DialogContentStyled>
      <DialogActions>
        <Button onClick={handleBack}>{t('navigation.buttons.close')}</Button>
      </DialogActions>
    </DialogFlexSales>
  );
};

export default DashboardClosing;
