import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import {
  filterParametersSelector,
  reportFilterCountryCodeSelector,
  reportFilterDiscountsJoinSelector,
  reportGcsFilterSelector,
  reportGcsSelector,
} from '../../state';
import { Gcs } from '../../types';
import handlerFactory, { GcsDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<Gcs, GcsDataGridContext>();

  const { t } = useTranslation();
  const title = t('gcs.title');
  const country = useSelector(reportFilterCountryCodeSelector);
  const gcsResource = useSelector(reportGcsSelector);
  const discountsJoin = useSelector(reportFilterDiscountsJoinSelector);
  const appParameters = useSelector(filterParametersSelector);
  const reportGcsFilters = useSelector(reportGcsFilterSelector);

  const { data, loading } = gcsResource;

  const gridContext = useMemo(
    () => ({
      t,
      country,
      storeExtraInfo: appParameters.data?.storeExtraInfo || [],
      discountsJoin,
    }),
    [t, country, appParameters.data, discountsJoin]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);
  handler.setFilters(reportGcsFilters);

  return null;
};

const GcsDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default GcsDataGrid;
