import { css } from '@emotion/react';

const GlobalStyles = css`
  html,
  body,
  div#root {
    height: 100%;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 15px;
    height: 20px;
    max-height: 20px !important;
    line-height: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-corner {
    display: none;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
`;

export default GlobalStyles;
