import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { useCallback, useEffect, useState } from 'react';
import { useDataGridFlexSales } from './DataGridFlexSalesProvider';
import DialogFlexSales from '../DialogFlexSales';

const paperProps = {
  style: {
    maxHeight: '45em',
  },
};

const ListColumns = () => {
  const { handler } = useDataGridFlexSales();
  const [columnsHidden, setColumnsHidden] = useState([...handler.columnsHidden]);

  useEffect(() => {
    const listenerColumnsHidden = handler.addEventListener('columnsHidden', ({ columnsHidden }) =>
      setColumnsHidden(columnsHidden)
    );

    return () => {
      listenerColumnsHidden.dispose();
    };
  }, [handler]);

  return (
    <List>
      {handler.columns
        .filter(i => i.isAvailable())
        .map(i => (
          <ListItem key={i.id} disablePadding dense>
            <ListItemButton
              role={undefined}
              onClick={() => handler.toggleColumnVisibility(i.id)}
              dense
              disabled={i.visibilityTogglingDisabled}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={!columnsHidden.includes(i.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': i.id }}
                />
              </ListItemIcon>
              <ListItemText id={i.id} primary={i.getTitle()} />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
};

const ColumnChooser = () => {
  const { handler } = useDataGridFlexSales();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const handleShowAll = useCallback(
    () => handler.toggleColumnVisibility([...handler.columnsHidden]),
    [handler]
  );
  const handleHideAll = useCallback(
    () =>
      handler.toggleColumnVisibility(
        handler.columns.filter(i => !i.isHidden() && i.isAvailable()).map(i => i.id)
      ),
    [handler]
  );

  return (
    <>
      <Tooltip
        title={t('dataGridFlexSales.labels.showColumnChooser') as string}
        placement="bottom"
        enterDelay={300}
      >
        <IconButton onClick={openDialog}>
          <ViewColumnIcon />
        </IconButton>
      </Tooltip>
      <DialogFlexSales onClose={handleClose} open={open} maxWidth="xs" PaperProps={paperProps}>
        <DialogTitle>{t('dataGridFlexSales.columnChooser.title')}</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {t('dataGridFlexSales.columnChooser.contentText')}
          </Typography>
          <ListColumns />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHideAll}>{t('dataGridFlexSales.labels.hideAll')}</Button>
          <Button onClick={handleShowAll}>{t('dataGridFlexSales.labels.showAll')}</Button>
        </DialogActions>
      </DialogFlexSales>
    </>
  );
};

export default ColumnChooser;
