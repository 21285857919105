import { useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { OptionsObject, useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { AccountingParameter, EditAccountingParameterForm } from '../../types';
import { FlexSalesForm, FlexSalesFormActions } from '../FlexSalesForm';
import routes from '../../config/routes';
import { useListener } from '../../hooks';
import DetailsFormInputArray from './DetailsFormInputArray';
import MasterField from './MasterField';
import { AccountingParametersActionType, accountingParametersFindRequested } from '../../state';
import { getSchema } from './schema';

const messageOptions: OptionsObject = {
  variant: 'success',
  autoHideDuration: 6000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
};

interface CompanyCodeEditFormProps {
  accountingParameter: AccountingParameter;
}

const AccountingParameterForm = ({ accountingParameter }: CompanyCodeEditFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const resolver = useMemo(() => yupResolver(getSchema()), []);
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues: EditAccountingParameterForm = accountingParameter;

  const formListener = useListener(
    AccountingParametersActionType.accountingParametersEditRequested,
    AccountingParametersActionType.accountingParametersEditSuccess,
    AccountingParametersActionType.accountingParametersEditFailure
  );
  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    navigate(routes.protectedNestedRoutes.accountingParameters.index.path);
  }, [navigate]);

  const onSubmit = async (data: EditAccountingParameterForm) => {
    await formListener(data);
    handleCancel();
    dispatch(accountingParametersFindRequested());
    enqueueSnackbar(t('accountingParameters.messages.editSuccess'), messageOptions);
  };

  return (
    <FlexSalesForm onValidSubmit={onSubmit} defaultValues={defaultValues} resolver={resolver}>
      <Grid container flexDirection="column" alignContent="space-between" spacing={2}>
        <Grid item xs>
          <MasterField accountingParameter={accountingParameter} />
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle2">
            {t('accountingParameters.titles.tableDetail')}
          </Typography>
        </Grid>
        <DetailsFormInputArray />
      </Grid>
      <FlexSalesFormActions
        onCancel={handleCancel}
        cancelLabel={t('accountingParameters.labels.cancel')}
        submitLabel={t('accountingParameters.labels.save')}
      />
    </FlexSalesForm>
  );
};

export default AccountingParameterForm;
