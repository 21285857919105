import Dialog, { DialogProps } from '@mui/material/Dialog';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

const PaperComponent = (props: PaperProps) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const DialogFlexSales = (props: DialogProps) => {
  const {
    children,
    onClose,
    open,
    maxWidth,
    PaperProps,
    scroll,
    fullScreen,
    'aria-describedby': ariaDescribedBy,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={maxWidth}
      PaperProps={PaperProps}
      scroll={scroll}
      fullScreen={fullScreen}
      aria-describedby={ariaDescribedBy}
    >
      {children}
    </Dialog>
  );
};

export default DialogFlexSales;
