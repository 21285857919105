import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import PrivateLayout from './PrivateLayout/PrivateLayout';
import PublicLayout from './PublicLayout';
import routes from '../config/routes';
import { NavigateDefaultRoute } from '../components';
import { isAuthenticatedSelector } from '../state';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PublicRoute from './PublicRoute/PublicRoute';

const protectedRoutes = Object.values(routes.protectedRoutes);
const protectedNestedRoutes = Object.values(routes.protectedNestedRoutes);

const LayoutComponent = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  if (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
    return null;
  }

  return isAuthenticated ? <PrivateLayout /> : <PublicLayout />;
};

const Layout = () => (
  <Routes>
    <Route element={<LayoutComponent />}>
      <Route path="/" element={<PrivateRoute component={NavigateDefaultRoute} />} />
      <Route
        path={routes.signinRoute.path}
        element={<PublicRoute component={routes.signinRoute.component} />}
      />
      {protectedRoutes.map(i => (
        <Route
          key={i.key}
          path={i.path}
          element={<PrivateRoute component={i.component} permissions={i.permissions} />}
        />
      ))}
      {protectedNestedRoutes.map(i => (
        <Route
          key={i.index.key}
          path={i.index.path}
          element={<PrivateRoute component={i.index.component} permissions={i.index.permissions} />}
        >
          {Object.values(i.children).map(j => (
            <Route
              key={j.key}
              path={j.path}
              element={<PrivateRoute component={j.component} permissions={j.permissions} />}
            />
          ))}
        </Route>
      ))}
      <Route path="*" element={<PrivateRoute component={routes.notFoundComponent} />} />
    </Route>
  </Routes>
);

export default Layout;
