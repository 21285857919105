import { useState } from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { SimpleSelect } from './selects';
import { themeSelector, changeTheme } from '../state';
import { ThemePreference } from '../types';

const ListSubheaderStyled = styled(ListSubheader)`
  text-align: center;
`;

const SimpleSelectStyled = styled(SimpleSelect)`
  min-width: 7em;
`;

const AppSettings = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const theme = useSelector(themeSelector);
  const [lang, setlang] = useState(i18n.language);
  const handleChangeTheme = (value: string) => dispatch(changeTheme(value as ThemePreference));

  // TODO: manejar con Redux
  const handleChangeLang = (value: string) => {
    setlang(value);
    i18n.changeLanguage(value);
  };

  // TODO: quitar hardcodeo
  const themeOptions = [
    { id: 'light', description: t('settings.labels.theme.light') },
    { id: 'dark', description: t('settings.labels.theme.dark') },
    { id: 'system', description: t('settings.labels.theme.system') },
  ];
  const langOptions = [
    { id: 'es', description: 'Español' },
    { id: 'en', description: 'English' },
    { id: 'pt', description: 'Português' },
    { id: 'fr', description: 'Français' },
  ];

  return (
    <List>
      <ListSubheaderStyled>{t('settings.labels.settings')}</ListSubheaderStyled>
      <ListItem>
        <ListItemIcon>
          <TranslateIcon />
        </ListItemIcon>
        <ListItemText primary={t('settings.labels.languageSelection')} />
        <ListItemSecondaryAction>
          <SimpleSelectStyled value={lang} options={langOptions} onChange={handleChangeLang} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Brightness4Icon />
        </ListItemIcon>
        <ListItemText primary={t('settings.labels.theme.themePreference')} />
        <ListItemSecondaryAction>
          <SimpleSelectStyled value={theme} options={themeOptions} onChange={handleChangeTheme} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

export default AppSettings;
