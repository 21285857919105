import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { IdTokenClaims } from '@azure/msal-common';
import axios, { AxiosError, AxiosRequestConfig, AxiosHeaders } from 'axios';
import { HttpRequestError } from '../types';
import { msalInstance, loginRequest } from './auth.config';

const ID_TOKEN_CLAIMS = 'id-token-claims';

const getNormalizedError = (axiosError: AxiosError): HttpRequestError => {
  const data = axiosError.response || { data: {}, status: -1 };
  return {
    notified: false,
    code: data.status,
    data: data.data as object | undefined,
    innerException: axiosError,
  };
};

const errorResponseHandler = (originalError: AxiosError): Promise<AxiosError> => {
  const error: HttpRequestError = getNormalizedError(originalError);
  // TODO: Agregar manejo de errores genéricos
  return Promise.reject(error);
};

function forceRefresh(currentTime: number, itc: IdTokenClaims | null) {
  if (!itc) return true;
  if (itc.exp && itc.exp - currentTime < 600000) return true;
  return false;
}

const setIdTokenClaims = (itc: IdTokenClaims) => {
  localStorage.setItem(ID_TOKEN_CLAIMS, JSON.stringify(itc));
};

const getIdTokenClaims = (): IdTokenClaims | null =>
  JSON.parse(localStorage.getItem(ID_TOKEN_CLAIMS) || 'null');

const requestHeaderHandler = async ({ headers: axiosHeaders, ...rest }: AxiosRequestConfig) => {
  let result: AuthenticationResult | null = null;
  try {
    const currentTime = new Date().getTime();
    const itc = getIdTokenClaims();
    result = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      forceRefresh: forceRefresh(currentTime, itc),
    });
    setIdTokenClaims(result.idTokenClaims);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      result = await msalInstance.acquireTokenPopup(loginRequest);
    }
  }
  if (!result) throw new Error('token-error');

  const headers = (axiosHeaders as AxiosHeaders) || new AxiosHeaders();
  headers['access-token'] = result.accessToken;
  headers.Authorization = `Bearer ${result.idToken}`;
  return { headers, ...rest };
};

const axiosFactory = (baseURL?: string, extraHeaders: Record<string, string> = {}) => {
  const axiosInstance = axios.create({ baseURL, headers: extraHeaders });

  axiosInstance.interceptors.request.use(requestHeaderHandler, Promise.reject);
  axiosInstance.interceptors.response.use(response => response, errorResponseHandler);

  return axiosInstance;
};

export const rsAxios = axiosFactory(process.env.RS_API_URL, { appId: 'way' });
export const salesAxios = axiosFactory(process.env.SALES_API_URL);
export const collectionsAxios = axiosFactory(process.env.COLLECTIONS_API_URL);
export const salesReprocessAxios = axiosFactory(process.env.SALES_REPROCESS_API_URL);
export const salesParametersAxios = axiosFactory(process.env.SALES_PARAMETERS_API_URL);
