import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useMediaQuery,
  Theme,
} from '@mui/material';
import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../state';

const StyledText = styled(Typography)({
  opacity: 0.5,
  fontWeight: 500,
});

interface Props {
  children: JSX.Element;
  selector: (state: AppState) => boolean;
}

const FiltersLayoutHandler = ({ children, selector }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [expanded, setExpanded] = React.useState(false);
  const loading = useSelector(selector);

  React.useEffect(() => {
    if (loading) setExpanded(false);
  }, [loading]);

  const togglePanel = () => {
    setExpanded(state => !state);
  };

  if (isMobile)
    return (
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={togglePanel} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledText>{t('toolbar.labels.filters')}</StyledText>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  return children;
};

export default FiltersLayoutHandler;
