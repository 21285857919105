import * as yup from 'yup';
import addMonths from 'date-fns/addMonths';
import { CreatePendingInterfacesForm } from '../../types';

export const maxDate = new Date();
export const minDate = addMonths(maxDate, -6);

export const getSchema = (): yup.ObjectSchema<CreatePendingInterfacesForm> =>
  yup.object().shape(
    {
      country: yup.string().required(),
      store: yup.string(),
      stores: yup.array(),
      businessDateFrom: yup
        .date()
        .required()
        .min(minDate)
        .when('businessDateTo', ([businessDateTo], schema) =>
          businessDateTo ? schema.max(yup.ref('businessDateTo')) : schema
        ),
      businessDateTo: yup
        .date()
        .required()
        .max(maxDate)
        .when('businessDateFrom', ([businessDateFrom], schema) =>
          businessDateFrom ? schema.min(yup.ref('businessDateFrom')) : schema
        ),
      justification: yup.string().required().max(80),
      allFailed: yup.bool(),
    },
    [['businessDateFrom', 'businessDateTo']]
  );
