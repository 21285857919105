import { Action } from 'redux';
import { AppNotification } from '../../types';

export interface State {
  queue: AppNotification[];
}

export enum ActionType {
  enqueueNotification = 'notifications/enqueueNotification',
  dequeueNotification = 'notifications/dequeueNotification',
}

export type EnqueueNotificationAction = Action<ActionType.enqueueNotification> & {
  notification: AppNotification | AppNotification[];
};

export type DequeueNotificationAction = Action<ActionType.dequeueNotification> & {
  notificationId: string | string[];
};

export type Actions = EnqueueNotificationAction | DequeueNotificationAction;
