import { SalesJournalFcs, Translation } from '../../../types';
import { DataGridHandler } from '../../DataGridFlexSales';

export interface FcsDataGridContext {
  t: Translation;
  country: string;
}

const initialContext: FcsDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<SalesJournalFcs, FcsDataGridContext>(initialContext, locale);

  handler.addColumn({
    accessor: 'fiscalPosCode',
    type: 'string',
    title: ({ t }) => t('sales-journal.table.columns.fiscal_pos_code'),
    width: { px: 150, pt: 145 },
  });

  handler.addColumn({
    accessor: 'fiscalReceiptNum',
    type: 'number',
    title: ({ t }) => t('sales-journal.table.columns.fiscal_receipt_num'),
    width: { px: 150, pt: 145 },
  });

  handler.addColumn({
    accessor: 'fiscalRegisterId',
    type: 'string',
    title: ({ t }) => t('sales-journal.table.columns.fiscal_register_id'),
    width: { px: 150, pt: 145 },
  });

  handler.addColumn({
    accessor: 'fiscalName',
    type: 'string',
    title: ({ t }) => t('sales-journal.table.columns.fiscal_name'),
    width: { px: 150, pt: 145 },
  });

  handler.addColumn({
    accessor: 'invoiceType',
    type: 'string',
    title: ({ t }) => t('sales-journal.table.columns.invoice_type'),
  });

  handler.addColumn({
    accessor: 'invoiceKind',
    type: 'string',
    title: ({ t }) => t('sales-journal.table.columns.invoice_kind'),
  });

  handler.addColumn({
    accessor: 'ivaPercentage',
    type: 'number',
    title: ({ t }) => t('sales-journal.table.columns.iva_percentage'),
  });

  handler.addColumn({
    accessor: 'iva',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sales-journal.table.columns.iva'),
    width: { px: 100, pt: 95 },
  });

  handler.addColumn({
    accessor: 'netSale',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sales-journal.table.columns.net_sale'),
  });

  handler.addColumn({
    accessor: 'grossSale',
    type: 'number',
    total: { type: 'sum' },
    title: ({ t }) => t('sales-journal.table.columns.gross_sale'),
  });

  return handler;
};
