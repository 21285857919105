import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { startOfMonth, subMonths } from 'date-fns';

import {
  reportFilterStartOperationDateSelector,
  reportFilterEndOperationDateSelector,
  changeFilterStartOperationDate,
  changeFilterEndOperationDate,
  filterParametersDataSelector,
} from '../../../state';
import { constants, isEqualNullableDate } from '../../../helpers';
import DateRangeFlexSales, {
  DateRangeFlexSalesProps,
  DateRangeFlexSalesValue,
} from '../../DateRangeFlexSales';

type OperationDateRangeFilterProps = Pick<DateRangeFlexSalesProps<Date>, 'spacing'>;

const OperationDateRangeFilter = (props: OperationDateRangeFilterProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const startDate = useSelector(reportFilterStartOperationDateSelector);
  const endDate = useSelector(reportFilterEndOperationDateSelector);
  const filterParameters = useSelector(filterParametersDataSelector);
  const minDate = startOfMonth(subMonths(new Date(), filterParameters?.filtersLimitMonths || 3));

  const dateValue: DateRangeFlexSalesValue<Date> = useMemo(
    () => [startDate.raw, endDate.raw] as DateRangeFlexSalesValue<Date>,
    [startDate.raw, endDate.raw]
  );

  const handleChange = useCallback(
    ([newStartDate, newEndDate]: DateRangeFlexSalesValue<Date>) => {
      if (!isEqualNullableDate(newStartDate, startDate.raw as Date)) {
        dispatch(changeFilterStartOperationDate(newStartDate));
      } else if (!isEqualNullableDate(newEndDate, endDate.raw as Date))
        dispatch(changeFilterEndOperationDate(newEndDate));
    },
    [dispatch, endDate, startDate]
  );

  return (
    <DateRangeFlexSales
      {...props}
      maxDate={constants.reportFilterRangeMaxDate}
      minDate={minDate}
      startText={t('toolbar.labels.fromDateOperation')}
      endText={t('toolbar.labels.toDateOperation')}
      value={dateValue}
      onChange={handleChange}
    />
  );
};

export default OperationDateRangeFilter;
