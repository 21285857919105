import {
  constants,
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoading,
  remoteResourceLoadSuccess,
} from '../../helpers';
import { ClosingDashboardViewMode } from '../../types';
import { Actions, ActionType, State } from './types';

const lastMonth = new Date();
lastMonth.setDate(0);
const lastDay = new Date();
lastDay.setDate(lastDay.getDate() - 1);

const initialState: State = {
  sales: {
    viewMode: ClosingDashboardViewMode.yesteday,
    closing: remoteResourceInitialState([]),
  },
  collections: {
    viewMode: ClosingDashboardViewMode.yesteday,
    closing: remoteResourceInitialState([]),
  },
  closedMonths: {
    accountingMonth: lastMonth,
    closedMonths: remoteResourceInitialState([]),
  },
  dailySales: {
    delayedStores: remoteResourceInitialState([]),
    dailySales: remoteResourceInitialState([]),
  },
  autoUpdate: {
    time: new Date().getTime() + constants.defaultDashboardTimer * 60000,
    lastUpdate: new Date(),
  },
  masterStatus: {
    mastersStatus: remoteResourceInitialState([]),
    mastersStatusDetails: remoteResourceInitialState([]),
  },
  masterStatus2: remoteResourceInitialState([]),
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionType.findClosingSalesStatusRequested:
      return {
        ...state,
        sales: {
          ...state.sales,
          closing: remoteResourceLoading([]),
        },
      };
    case ActionType.findClosingSalesStatusSuccess:
      return {
        ...state,
        sales: {
          ...state.sales,
          closing: remoteResourceLoadSuccess(action.closing),
        },
      };
    case ActionType.findClosingSalesStatusFailure:
      return {
        ...state,
        sales: {
          ...state.sales,
          closing: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.changeSalesViewMode:
      return {
        ...state,
        sales: {
          ...state.sales,
          viewMode: action.viewMode,
          closing: remoteResourceLoading([]),
        },
      };
    case ActionType.findClosingCollectionsStatusRequested:
      return {
        ...state,
        collections: {
          ...state.collections,
          closing: remoteResourceLoading([]),
        },
      };
    case ActionType.findClosingCollectionsStatusSuccess:
      return {
        ...state,
        collections: {
          ...state.collections,
          closing: remoteResourceLoadSuccess(action.closing),
        },
      };
    case ActionType.findClosingCollectionsStatusFailure:
      return {
        ...state,
        collections: {
          ...state.collections,
          closing: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.changeCollectionsViewMode:
      return {
        ...state,
        collections: {
          ...state.collections,
          viewMode: action.viewMode,
          closing: remoteResourceLoading([]),
        },
      };
    case ActionType.findClosedMonthsRequested:
      return {
        ...state,
        closedMonths: {
          ...state.closedMonths,
          closedMonths: remoteResourceLoading([]),
        },
      };
    case ActionType.findClosedMonthsSuccess:
      return {
        ...state,
        closedMonths: {
          ...state.closedMonths,
          closedMonths: remoteResourceLoadSuccess(action.closedMonths),
        },
      };
    case ActionType.findClosedMonthsFailure:
      return {
        ...state,
        closedMonths: {
          ...state.closedMonths,
          closedMonths: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.changeAccountingMonth:
      return {
        ...state,
        closedMonths: {
          ...state.closedMonths,
          accountingMonth: action.accountingMonth,
        },
      };
    case ActionType.findDailySalesRequested:
      return {
        ...state,
        dailySales: {
          ...state.dailySales,
          dailySales: remoteResourceLoading([]),
        },
      };
    case ActionType.findDailySalesSuccess:
      return {
        ...state,
        dailySales: {
          ...state.dailySales,
          dailySales: remoteResourceLoadSuccess(action.dailySales),
        },
      };
    case ActionType.findDailySalesFailure:
      return {
        ...state,
        dailySales: {
          ...state.dailySales,
          dailySales: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.findDelayedStoresRequested:
      return {
        ...state,
        dailySales: {
          ...state.dailySales,
          delayedStores: remoteResourceLoading([]),
        },
      };
    case ActionType.findDelayedStoresSuccess:
      return {
        ...state,
        dailySales: {
          ...state.dailySales,
          delayedStores: remoteResourceLoadSuccess(action.delayedStores),
        },
      };
    case ActionType.findDelayedStoresFailure:
      return {
        ...state,
        dailySales: {
          ...state.dailySales,
          delayedStores: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.changeAutoUpdate:
      return {
        ...state,
        autoUpdate: {
          time: action.autoUpdate.time,
          lastUpdate: action.autoUpdate.lastUpdate,
        },
      };
    case ActionType.findMastersStatusMastersStatusDetailsRequested:
      return {
        ...state,
        masterStatus: {
          ...state.masterStatus,
          mastersStatusDetails: remoteResourceLoading([]),
        },
      };
    case ActionType.findMastersStatusMastersStatusDetailsSuccess:
      return {
        ...state,
        masterStatus: {
          ...state.masterStatus,
          mastersStatusDetails: remoteResourceLoadSuccess(action.mastersStatusDetails),
        },
      };
    case ActionType.findMastersStatusMastersStatusDetailsFailure:
      return {
        ...state,
        masterStatus: {
          ...state.masterStatus,
          mastersStatusDetails: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.findMastersStatusRequested:
      return {
        ...state,
        masterStatus: {
          ...state.masterStatus,
          mastersStatus: remoteResourceLoading([]),
        },
      };
    case ActionType.findMastersStatusSuccess:
      return {
        ...state,
        masterStatus: {
          ...state.masterStatus,
          mastersStatus: remoteResourceLoadSuccess(action.mastersStatus),
        },
      };
    case ActionType.findMastersStatusFailure:
      return {
        ...state,
        masterStatus: {
          ...state.masterStatus,
          mastersStatus: remoteResourceLoadFailure([], action.error),
        },
      };
    case ActionType.findMastersStatus2Requested:
      return {
        ...state,
        masterStatus2: remoteResourceLoading([]),
      };
    case ActionType.findMastersStatus2Success:
      return {
        ...state,
        masterStatus2: remoteResourceLoadSuccess(action.mastersStatus),
      };
    case ActionType.findMastersStatus2Failure:
      return {
        ...state,
        masterStatus2: remoteResourceLoadFailure([], action.error),
      };
    default:
      return state;
  }
};

export default reducer;
