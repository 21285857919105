import styled from '@emotion/styled';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { format } from 'date-fns';
import {
  changeAccountingMonth,
  dashboardClosedMonthsAccountingMonthSelector,
  dashboardClosedMonthsClosedMonthsSelector,
  findClosedMonthsRequested,
} from '../../../../state';
import ClosingContent from './ClosingContent';
import { useMuiPickersLocale } from '../../../../hooks';

const lastMonth = new Date();
lastMonth.setDate(0);
lastMonth.setDate(1);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleContainer = styled(Typography)`
  display: flex;
  align-items: center;
`;

interface ButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const PreviousButton = ({ onClick, disabled }: ButtonProps) => (
  <Button onClick={onClick} disabled={disabled}>
    <NavigateBeforeIcon />
  </Button>
);
const NextButton = ({ onClick, disabled }: ButtonProps) => (
  <Button onClick={onClick} disabled={disabled}>
    <NavigateNextIcon />
  </Button>
);

const MonthClosingIncorporation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accountingMonth = useSelector(dashboardClosedMonthsAccountingMonthSelector);
  const closedMonths = useSelector(dashboardClosedMonthsClosedMonthsSelector);
  const locale = useMuiPickersLocale();

  const handleChangeAccountingMonthPrevious = useCallback(() => {
    accountingMonth.setDate(0);
    dispatch(changeAccountingMonth(accountingMonth));
    dispatch(findClosedMonthsRequested());
  }, [accountingMonth, dispatch]);

  const handleChangeAccountingMonthNext = useCallback(() => {
    const nextMonthLastDay = new Date(
      accountingMonth.getFullYear(),
      accountingMonth.getMonth() + 2,
      0
    );
    dispatch(changeAccountingMonth(nextMonthLastDay));
    dispatch(findClosedMonthsRequested());
  }, [accountingMonth, dispatch]);

  return (
    <Card>
      <CardContent>
        <Container>
          <PreviousButton
            onClick={handleChangeAccountingMonthPrevious}
            disabled={accountingMonth <= new Date('2022-02-01')}
          />
          <TitleContainer variant="subtitle2">
            {t('dashboard.month-closing-status')} {format(accountingMonth, 'MMMM yyyy', { locale })}
          </TitleContainer>
          <NextButton
            onClick={handleChangeAccountingMonthNext}
            disabled={accountingMonth >= lastMonth}
          />
        </Container>
        <ClosingContent resource={closedMonths} />
      </CardContent>
    </Card>
  );
};

export default MonthClosingIncorporation;
