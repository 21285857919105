import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { VirtualTableProps } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { useDataGridFlexSales } from '../DataGridFlexSalesProvider';

const TdStyled = styled.td`
  position: sticky;
  padding: 2em;
  big:first-of-type {
    position: sticky;
    left: 50%;
  }
`;

const VirtualTableNoDataCell: VirtualTableProps['noDataCellComponent'] = ({ colSpan, rowSpan }) => {
  const { handler } = useDataGridFlexSales();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(handler.loading);

  useEffect(() => {
    const loadingListener = handler.addEventListener('loading', ({ loading: isLoading }) =>
      setLoading(isLoading)
    );

    return () => {
      loadingListener.dispose();
    };
  }, [handler]);

  return (
    <TdStyled colSpan={colSpan} rowSpan={rowSpan}>
      <big>
        {loading ? (
          <CircularProgress size={28} />
        ) : (
          <span>{t('dataGridFlexSales.labels.noRows')}</span>
        )}
      </big>
    </TdStyled>
  );
};

export default VirtualTableNoDataCell;
