import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { companyCodesCompanyCodesSelector, userPermissionsDataSelector } from '../../state';
import handlerFactory, { CompanyCodeDataGridContext } from './handlerFactory';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import { CompanyCode } from '../../types';

const DataGridConfig = () => {
  const { t } = useTranslation();
  const { handler } = useDataGridFlexSales<CompanyCode, CompanyCodeDataGridContext>();
  const userPermissions = useSelector(userPermissionsDataSelector);
  const { data, loading } = useSelector(companyCodesCompanyCodesSelector);

  const gridContext = useMemo(
    () => ({
      t,
      userPermissions: userPermissions.map(i => i.permission),
    }),
    [t, userPermissions]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(t('companyCodes.titles.dataGrid'));
  handler.setLoading(loading);

  return null;
};

const CompanyCodesDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default CompanyCodesDataGrid;
