import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { AccountingParametersSearch, AccountingParametersTable } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const AccountingParameters = () => (
  <Container>
    <AccountingParametersSearch />
    <AccountingParametersTable />
    <Outlet />
  </Container>
);

export default AccountingParameters;
