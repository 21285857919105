import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useRegionalManagement from '../../../hooks/useRegionalManagement';
import {
  changeOpStrucuresFilterRegionalManagement,
  reportFilterCountryCodeSelector,
  reportOpStructuresFilterManagementSelector,
  reportOpStructuresFilterRegionalManagementSelector,
  reportOpStructuresFilterRegionSelector,
  reportOpStructuresFilterSupervisionSelector,
} from '../../../state';
import { RegionalManagement } from '../../../types';
import { MultiSelect } from '../../selects';

const RegionalManagementFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const countryCode = useSelector(reportFilterCountryCodeSelector);
  const regionalManagements = useSelector(reportOpStructuresFilterRegionalManagementSelector);
  const regions = useSelector(reportOpStructuresFilterRegionSelector);
  const managements = useSelector(reportOpStructuresFilterManagementSelector);
  const supervisions = useSelector(reportOpStructuresFilterSupervisionSelector);
  const { options, disabled, loading } = useRegionalManagement(
    countryCode,
    regions,
    managements,
    supervisions
  );

  const handleChange = useCallback(
    (value: RegionalManagement[]) => dispatch(changeOpStrucuresFilterRegionalManagement(value)),
    [dispatch]
  );

  return (
    <MultiSelect
      label={t('toolbar.labels.regionalMgmt')}
      options={options}
      propDesc="label"
      disabled={disabled}
      value={regionalManagements}
      onChange={handleChange}
      loading={loading}
    />
  );
};

export default RegionalManagementFilter;
