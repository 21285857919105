import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ReprocessDataGrid, ReprocessSearch } from '../components';
import { reprocessParametersRequested } from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const Reprocess = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reprocessParametersRequested());
  }, [dispatch]);
  return (
    <>
      <Container>
        <ReprocessSearch />
        <ReprocessDataGrid />
      </Container>
      <Outlet />
    </>
  );
};

export default Reprocess;
