import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getRoutePathDefault } from '../../helpers';
import { isAuthenticatedSelector, userPermissionsDataSelector } from '../../state';
import NavigateFromReturnUrl from './NavigateFromReturnUrl';

interface PublicRouteProps {
  component: FunctionComponent;
}

const PublicRoute = ({ component: Component }: PublicRouteProps) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userPermissions = useSelector(userPermissionsDataSelector).map(i => i.permission);
  const otherwise = getRoutePathDefault(userPermissions) || '/';
  if (isAuthenticated) return <NavigateFromReturnUrl otherwise={otherwise} />;

  return <Component />;
};

export default PublicRoute;
