import {
  ActionType,
  DownloadRequestedAction,
  DownloadFailureAction,
  DownloadSuccessAction,
  DownloadReportPdfAction,
} from './types';

export const downloadRequested = (
  action: Omit<DownloadRequestedAction, 'type'>
): DownloadRequestedAction => ({
  type: ActionType.downloadRequested,
  ...action,
});

export const downloadSuccess = (): DownloadSuccessAction => ({ type: ActionType.downloadSuccess });

export const downloadFailure = (
  action: Omit<DownloadFailureAction, 'type'>
): DownloadFailureAction => ({ type: ActionType.downloadFailure, ...action });

export const downloadReportPdf = (action: Omit<DownloadReportPdfAction, 'type'>) => ({
  type: ActionType.downloadReportPdf,
  ...action,
});
