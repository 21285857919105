import { Actions, State, ActionType } from './types';
import {
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoading,
  remoteResourceLoadSuccess,
} from '../../helpers';

const initialState: State = {
  filters: {
    countryId: '',
  },
  companyCodes: remoteResourceInitialState([]),
  companyCode: remoteResourceInitialState(undefined),
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.companyCodesChangeFilterCountry:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            countryId: action.countryId,
          },
        },
      };
    case ActionType.companyCodesFindRequested:
      return {
        ...state,
        companyCodes: remoteResourceLoading([]),
      };
    case ActionType.companyCodesFindSuccess:
      return {
        ...state,
        companyCodes: remoteResourceLoadSuccess(action.companyCodes),
      };
    case ActionType.companyCodesFindFailure:
      return {
        ...state,
        companyCodes: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.companyCodesGetRequested:
      return {
        ...state,
        companyCode: remoteResourceLoading(undefined),
      };
    case ActionType.companyCodesGetSuccess:
      return {
        ...state,
        companyCode: remoteResourceLoadSuccess(action.companyCode),
      };
    case ActionType.companyCodesGetFailure:
      return {
        ...state,
        companyCode: remoteResourceLoadFailure(undefined, action.error),
      };
    default:
      return state;
  }
};

export default reducer;
