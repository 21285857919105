import { format, isValid } from 'date-fns';
import { t } from 'i18next';

export interface FormatterPropsBase {
  value: unknown;
  defaultValue: string;
}

export type NumberFormatterProps = FormatterPropsBase & {
  locale: Locale;
  maximumFractionDigits?: number;
  exceptionOnInvalidCast?: boolean;
  factor?: number;
};

export enum DateFormat {
  date = 'P',
  dateTime = 'P kk:mm:ss',
}

export type DateFormatterProps = FormatterPropsBase & {
  locale: Locale;
  exceptionOnInvalidCast?: boolean;
  format?: DateFormat | string;
};

export type BooleanFormatterProps = FormatterPropsBase & {
  textTrue: string;
  textFalse: string;
};

export type PercentFormatterProps = Omit<NumberFormatterProps, 'factor'>;

export const numberFormatter = ({
  value,
  exceptionOnInvalidCast,
  defaultValue,
  locale,
  maximumFractionDigits = 2,
  factor = 1,
}: NumberFormatterProps) => {
  if (!value && value !== 0) return defaultValue;
  const numValue = Number(value);
  if (Number.isNaN(numValue)) {
    if (exceptionOnInvalidCast)
      throw new Error(`'${value}' no se puede convertir a un valor numérico`);
    else return defaultValue;
  }
  return new Intl.NumberFormat(locale.code, { maximumFractionDigits }).format(numValue * factor);
};

export const percentFormatter = ({
  value,
  defaultValue,
  maximumFractionDigits = 2,
  ...rest
}: PercentFormatterProps) => {
  if (!value && value !== 0) return defaultValue;
  const numValue = numberFormatter({
    value,
    defaultValue,
    maximumFractionDigits,
    factor: 100,
    ...rest,
  });
  return `${numValue}%`;
};

export const dateFormatter = ({
  value,
  locale,
  format: formatDate = DateFormat.date,
  defaultValue = '',
}: DateFormatterProps) => {
  if (!value) return defaultValue;
  if (!isValid(value)) throw new Error();
  return format(value as Date, formatDate, { locale });
};

export const booleanFormatter = ({
  value,
  textFalse,
  textTrue,
  defaultValue = '',
}: BooleanFormatterProps) => {
  if (value === null || value === undefined) return defaultValue;
  return value ? textTrue : textFalse;
};

export const defaultFormatters = {
  date: (value: unknown, locale: Locale, defaultValue = '') =>
    dateFormatter({
      value,
      locale,
      defaultValue,
      exceptionOnInvalidCast: true,
      format: DateFormat.date,
    }),
  dateTime: (value: unknown, locale: Locale, defaultValue = '') =>
    dateFormatter({
      value,
      locale,
      defaultValue,
      exceptionOnInvalidCast: true,
      format: DateFormat.dateTime,
    }),
  percent: (value: unknown, locale: Locale, defaultValue = '') =>
    percentFormatter({
      value,
      locale,
      defaultValue,
      maximumFractionDigits: 2,
      exceptionOnInvalidCast: true,
    }),
  number: (value: unknown, locale: Locale, defaultValue = '') =>
    numberFormatter({
      value,
      locale,
      defaultValue,
      maximumFractionDigits: 2,
      exceptionOnInvalidCast: true,
    }),

  boolean: (value: unknown, defaultValue = '') =>
    booleanFormatter({
      value,
      defaultValue,
      textFalse: t('common.no'),
      textTrue: t('common.yes'),
    }),
};
