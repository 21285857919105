import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccountingParameterForm, DialogForm } from '../components';
import {
  accountingParametersAccountingParameterSelector,
  accountingParametersGetRequested,
} from '../state';

const AccountingParametersEdit = () => {
  const { t } = useTranslation();
  const { data, error, loading } = useSelector(accountingParametersAccountingParameterSelector);
  const { countryIdJournalType = '' } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const [countryId, journalType] = countryIdJournalType.split(':');
    dispatch(accountingParametersGetRequested(countryId, journalType));
  }, [dispatch, countryIdJournalType]);

  return (
    <DialogForm
      title={t('accountingParameters.titles.edit')}
      loading={loading}
      notFound={Boolean(error)}
      maxWidth="xl"
    >
      {data && <AccountingParameterForm accountingParameter={data} />}
    </DialogForm>
  );
};

export default AccountingParametersEdit;
