import { Cancellation, ReportCancellationsGroupBy } from '../../types';
import { DataGridHandler } from '../DataGridFlexSales';

export interface CancellationDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
  groupBy: ReportCancellationsGroupBy[];
}

const initialContext: CancellationDataGridContext = {
  t: () => '',
  country: '',
  groupBy: [],
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<Cancellation, CancellationDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'accountingDate',
    type: 'date',
    title: ({ t }) => t('table.columns.accounting_date'),
    available: ({ groupBy }) => groupBy.includes('accountingDate'),
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
    available: ({ groupBy }) => groupBy.includes('storeAcronym'),
  });

  handler.addColumn({
    name: 'operation',
    type: 'string',
    title: ({ t }) => t('cancellations.columns.operation'),
    valueGetter: (row, { t }) => {
      if (!row.operation) return '-';
      return t(`cancellations.operations.${row.operation}`);
    },
    width: { px: 200, pt: 195 },
  });

  handler.addColumn({
    accessor: 'quantity',
    type: 'number',
    title: ({ t }) => t('cancellations.columns.quantity'),
  });

  handler.addColumn({
    accessor: 'amount',
    type: 'number',
    title: ({ t }) => t('cancellations.columns.amount'),
  });

  handler.addColumn({
    accessor: 'grossSale',
    type: 'number',
    title: ({ t }) => t('cancellations.columns.gross-sale'),
  });

  handler.addColumn({
    name: 'percent',
    type: 'percent',
    title: () => '%',
    valueGetter: row => row.amount / row.grossSale,
    width: { px: 50, pt: 40 },
  });

  return handler;
};
