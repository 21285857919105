import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Dispatch, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { dateResponseMap } from '../../helpers';
import {
  changeCausesFilterCause,
  changeCausesFilterCountry,
  changeCausesFilterDate,
  changeCausesFilterStore,
  findMissingClosingCollectionsCausesRequested,
  findMissingClosingSalesCausesRequested,
  reportClosingFilterViewModeSelector,
} from '../../state';
import { ClosingViewMode } from '../../types';
import { ErrorHeader } from './ErrorHeader';
import { CollectionErrors } from './CollectionErrors';
import { SaleErrors } from './SaleErrors';
import routes from '../../config/routes';
import DialogFlexSales from '../DialogFlexSales';

const handleSearchParams = (
  dispatch: Dispatch<AnyAction>,
  searchParams: URLSearchParams,
  salesRequested: boolean,
  collectionsRequested: boolean
) => {
  const country = searchParams.get('country');
  const store = searchParams.get('store');
  const date = dateResponseMap(searchParams.get('date')) as Date;
  const cause = searchParams.get('cause');

  if (country && store && date && cause) {
    dispatch(changeCausesFilterCountry(country));
    dispatch(changeCausesFilterStore(store));
    dispatch(changeCausesFilterDate(date));
    dispatch(changeCausesFilterCause(cause));
    if (salesRequested) dispatch(findMissingClosingSalesCausesRequested());
    if (collectionsRequested) dispatch(findMissingClosingCollectionsCausesRequested());
  }
};

const DetailsDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const viewMode = useSelector(reportClosingFilterViewModeSelector);
  const showSaleErrors = [ClosingViewMode.CONSOLIDATED, ClosingViewMode.SALES].includes(viewMode);
  const showCollectionErrors = [ClosingViewMode.CONSOLIDATED, ClosingViewMode.COLLECTIONS].includes(
    viewMode
  );

  const handleCancel = useCallback(() => {
    navigate(routes.protectedNestedRoutes.missingClosingDetail.index.path);
  }, [navigate]);

  useEffect(() => {
    handleSearchParams(dispatch, searchParams, showSaleErrors, showCollectionErrors);
  }, [showCollectionErrors, dispatch, showSaleErrors, searchParams, viewMode]);

  return (
    <DialogFlexSales onClose={handleCancel} open scroll="body" maxWidth="md">
      <DialogTitle>{t('missing-closing-causes.title')}</DialogTitle>
      <DialogContent>
        <Stack>
          <ErrorHeader />
          {showSaleErrors && <SaleErrors />}
          {showCollectionErrors && <CollectionErrors />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('navigation.buttons.close')}</Button>
      </DialogActions>
    </DialogFlexSales>
  );
};

const MissingsDialog = () => <DetailsDialog />;

export default MissingsDialog;
