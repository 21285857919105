import { Navigate, useLocation } from 'react-router-dom';

interface Props {
  path: string;
}

const getToWithReturnUrl = (path: string, currentSearch: string, currentPathname: string) => {
  const queryString = currentSearch ? encodeURIComponent(currentSearch) : '';
  const search = currentPathname ? `?returnUrl=${currentPathname}${queryString}` : undefined;
  return { pathname: path, search };
};

const NavigateWithReturnUrl = ({ path }: Props) => {
  const { search: currentSearch, pathname: currentPathname } = useLocation();
  const to = getToWithReturnUrl(path, currentSearch, currentPathname);
  return <Navigate to={to} />;
};

export default NavigateWithReturnUrl;
