import { ExecutionStatusPendingInterfaces } from '../../types';
import { DataGridHandler, commonColProps } from '../DataGridFlexSales';

export interface ExecutionsPendingInterfacesDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
}

const initialContext: ExecutionsPendingInterfacesDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<
    ExecutionStatusPendingInterfaces,
    ExecutionsPendingInterfacesDataGridContext
  >(initialContext, locale);

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
  });

  handler.addColumn({
    accessor: 'justification',
    type: 'string',
    title: ({ t }) => t('reprocess.columns.justification'),
  });

  handler.addColumn({
    accessor: 'executionDate',
    type: 'string',
    title: ({ t }) => t('reprocess.columns.execution_date'),
  });

  handler.addColumn({
    accessor: 'state',
    type: 'string',
    title: ({ t }) => t('reprocess.columns.status'),
  });

  return handler;
};
