import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CompanyCodeForm, DialogFlexSales } from '../components';
import { companyCodesCompanyCodeSelector, companyCodesGetRequested } from '../state';
import Http404 from './Http404';

const DialogContentStyled = styled(DialogContent)`
  width: 20em;
`;

const LoadingContainer = styled(DialogContent)`
  display: flex;
  justify-content: center;
`;

const CompanyCodeEdit = () => {
  const { t } = useTranslation();
  const { data, error, loading } = useSelector(companyCodesCompanyCodeSelector);
  const { countryIdCompanyId = '' } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const [countryId, companyId] = countryIdCompanyId.split('-');
    dispatch(companyCodesGetRequested(countryId, parseInt(companyId, 10)));
  }, [dispatch, countryIdCompanyId]);

  return (
    <DialogFlexSales open maxWidth="sm">
      <DialogTitle>{t('companyCodes.titles.edit')}</DialogTitle>
      <DialogContentStyled>
        {error && <Http404 />}
        {!error && data && <CompanyCodeForm companyCode={data} />}
        {loading && (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
      </DialogContentStyled>
    </DialogFlexSales>
  );
};

export default CompanyCodeEdit;
