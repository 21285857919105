import { FcsDataGrid } from './FcsDataGrid';
import { GcsDataGrid } from './GcsDataGrid';
import { NcsDataGrid } from './NcsDataGrid';
import { SalesJournalTotals } from './SalesJournalTotals';
import { ThirdPartyDataGrid } from './ThirdPartyDataGrid';

const SalesJournalDataGrid = () => (
  <>
    <GcsDataGrid />
    <NcsDataGrid />
    <FcsDataGrid />
    <ThirdPartyDataGrid />
    <SalesJournalTotals />
  </>
);

export default SalesJournalDataGrid;
