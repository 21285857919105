import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { MonthClosingAnnulForm, MonthClosingDataGrid, MonthClosingSearch } from '../components';
import { monthClosingAnnulSelector } from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const MonthClosing = () => {
  const monthClosingAnnul = useSelector(monthClosingAnnulSelector);
  return (
    <>
      <Container>
        <MonthClosingSearch />
        <MonthClosingDataGrid />
        {monthClosingAnnul && <MonthClosingAnnulForm monthClosing={monthClosingAnnul} />}
      </Container>
      <Outlet />
    </>
  );
};
export default MonthClosing;
