import styled from '@emotion/styled';
import { ReactNode } from 'react';
import ApplyButton from './ApplyButton';
import OnedriveButton from './OnedriveButton';
import { FilterDialog } from './FilterDialog';
import { GroupByDialog } from './GroupByDialog';
import OpStructuresDialog from './OpStructuresDialog';
import { DiscountsDialog } from './DiscountsDialog';

interface ReportFilterActionsProps {
  applyDisabled?: boolean;
  children?: ReactNode;
}

const ActionContainer = styled.div`
  display: flex;
  justify-content: right;
  > button {
    margin-right: ${props => props.theme.spacing(2)};
  }
  > span {
    margin-right: ${props => props.theme.spacing(2)};
  }
  > button:last-of-type {
    margin-right: 0;
  }
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: center;
  }
`;

const ReportFilterActions = ({ applyDisabled, children }: ReportFilterActionsProps) => (
  <ActionContainer>
    {children}
    <OpStructuresDialog />
    <DiscountsDialog />
    <FilterDialog />
    <GroupByDialog />
    <ApplyButton disabled={applyDisabled} />
    <OnedriveButton />
  </ActionContainer>
);

export default ReportFilterActions;
