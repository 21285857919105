import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilterPos, reportFilterPosSelector, reportPosOptionsSelector } from '../../../state';
import { MultiSelect } from '../../selects';

const PosFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const posOptionsResource = useSelector(reportPosOptionsSelector);
  const pos = useSelector(reportFilterPosSelector);
  const disabled = !posOptionsResource.loaded;
  const posOptions = posOptionsResource.data || [];

  const handleChange = useCallback(
    (value: string[]) => dispatch(changeFilterPos(value)),
    [dispatch]
  );

  return (
    <MultiSelect
      label={t('toolbar.labels.pos')}
      options={posOptions}
      disabled={disabled}
      value={pos}
      onChange={handleChange}
      loading={posOptionsResource.loading}
    />
  );
};

export default PosFilter;
