import * as yup from 'yup';
import { EditCompanyCodeForm, Translation } from '../../types';

export const ledgerMaxLength = 10;
export const currencyMaxLength = 10;
export const companyOakIdLength = 1;
export const classCodeLength = 10;

const getMinFromLength = (length: number) => {
  if (length === 1) return 0;
  return 10 ** (length - 1);
};
const getMaxFromLength = (length: number) => 10 ** length - 1;

export const getSchema = (t: Translation): yup.ObjectSchema<EditCompanyCodeForm> =>
  yup.object().shape({
    countryId: yup.string().required(),
    name: yup.string().required(),
    codeWh: yup.number().required(),
    isAccounted: yup.bool().required(),
    companyOakId: yup
      .number()
      .required()
      .integer()
      .min(
        getMinFromLength(companyOakIdLength),
        t('validations.numberLength', { length: companyOakIdLength })
      )
      .max(
        getMaxFromLength(companyOakIdLength),
        t('validations.numberLength', { length: companyOakIdLength })
      ),
    classCode: yup
      .number()
      .required()
      .integer()
      .min(
        getMinFromLength(classCodeLength),
        t('validations.numberLength', { length: classCodeLength })
      )
      .max(
        getMaxFromLength(classCodeLength),
        t('validations.numberLength', { length: classCodeLength })
      ),
    codeEbs: yup.string().when('isAccounted', { is: true, then: schema => schema.required() }),
    ledger: yup.string().when('isAccounted', { is: true, then: schema => schema.required() }),
    currency: yup.string().when('isAccounted', { is: true, then: schema => schema.required() }),
  });
