import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { AppSettings, UserAppInfo } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin: ${props => props.theme.spacing(1)};
  }
`;

const CardBasicInfo = styled(Card)`
  border-radius: 2px;
  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: 20%;
    margin-left: 20%;
  }
`;

const Profile = () => (
  <Container>
    <CardBasicInfo elevation={4}>
      <CardContent>
        <UserAppInfo />
      </CardContent>
    </CardBasicInfo>
    <CardBasicInfo elevation={4}>
      <CardContent>
        <AppSettings />
      </CardContent>
    </CardBasicInfo>
  </Container>
);

export default Profile;
