import { ActionType, ModeModalAction, ToggleSidebarAction } from './types';

export const modeModal = (isModeModal: boolean): ModeModalAction => ({
  type: ActionType.modeModal,
  isModeModal,
});

export const toggleSidebar = (): ToggleSidebarAction => ({
  type: ActionType.toggleSidebar,
});
