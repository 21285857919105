import { useCallback } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../state';
import { Country } from '../../types';
import { DivisionCountrySelect, SimpleSelectProps } from '../selects';

type DivisionCountryFilterProps = Pick<SimpleSelectProps, 'fullWidth' | 'required'> & {
  valueSelector: (state: AppState) => Country | null;
  actionChange: (country: Country | null) => AnyAction;
  disableClearable?: boolean;
};

const DivisionCountryFilter = ({
  valueSelector,
  actionChange,
  ...rest
}: DivisionCountryFilterProps) => {
  const dispatch = useDispatch();
  const country = useSelector(valueSelector);

  const handleChange = useCallback(
    (value: Country | null) => dispatch(actionChange(value)),
    [dispatch, actionChange]
  );

  return <DivisionCountrySelect {...rest} onChange={handleChange} value={country} />;
};

export default DivisionCountryFilter;
