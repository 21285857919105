import Skeleton from '@mui/material/Skeleton';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { MonthClosing, RemoteResource } from '../../../../types';
import { useMuiPickersLocale } from '../../../../hooks';
import { activeCountriesSelector, masterDataSelector } from '../../../../state';

export interface ClosingContentProps {
  resource: RemoteResource<MonthClosing[]>;
}

const StyledDiv = styled.div`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ItemsContainer = styled(Stack)`
  padding-top: ${prop => prop.theme.spacing(2)};
  padding-bottom: ${prop => prop.theme.spacing(1)};
`;

const IncorporationSkeleton = () => (
  <>
    <Skeleton />
    <Skeleton />
  </>
);

const IncorporationError = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" color="error" padding={1} spacing={1}>
      <ErrorIcon color="error" />
      <Typography variant="body1" color="error">
        {t('errors.generics.loadingData')}
      </Typography>
    </Stack>
  );
};

const ClosingContent = ({ resource }: ClosingContentProps) => {
  const { t } = useTranslation();
  const masterDataResource = useSelector(masterDataSelector);
  const activeCountriesResource = useSelector(activeCountriesSelector);
  const { data, loaded, error } = resource;
  const loading = resource.loading || masterDataResource.loading || activeCountriesResource.loading;
  const locale = useMuiPickersLocale();

  return (
    <ItemsContainer spacing={1}>
      {!loading &&
        !error &&
        loaded &&
        data.map(i => (
          <div key={i.country}>
            <StyledDiv>
              <div>{t(`operativeStructure.countries.${i.country}`)}</div>
              <Typography variant="body1">
                <Box component="span">{format(i.creationDate, 'P', { locale })}</Box>
              </Typography>
            </StyledDiv>
          </div>
        ))}
      {!loading && error && <IncorporationError />}
      {loading && <IncorporationSkeleton />}
      {!loading && !error && loaded && data.length === 0 && <div>{t('table.labels.noData')}</div>}
    </ItemsContainer>
  );
};

export default ClosingContent;
