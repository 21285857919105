import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  reportFilterCountryCodeSelector,
  reportGcsFilterSelector,
  reportGcsPaymentTypesSelector,
} from '../../state';
import { GcsByPaymentTypes } from '../../types';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { GcsByPaymentTypeDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<GcsByPaymentTypes, GcsByPaymentTypeDataGridContext>();
  const { t } = useTranslation();
  const country = useSelector(reportFilterCountryCodeSelector);
  const reportGcsFilters = useSelector(reportGcsFilterSelector);

  const { loading, data } = useSelector(reportGcsPaymentTypesSelector);

  const gridContext = useMemo(() => ({ t, country }), [t, country]);

  handler.setTitle(t('gcs-payment-type.title'));
  handler.setContext(gridContext);
  handler.setLoading(loading);
  handler.setData(data);
  handler.setFilters(reportGcsFilters);

  return null;
};

const GcsByPaymentTypeDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default GcsByPaymentTypeDataGrid;
