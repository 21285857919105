import { Stack } from '@mui/material';
import TextFieldMui from '@mui/material/TextField';

import { OptionsObject, useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContryName, useListener } from '../../hooks';
import { CompanyCodesActionType } from '../../state';
import routes from '../../config/routes';
import {
  FlexSalesForm,
  FlexSalesFormActions,
  SwitchFormInput,
  TextFieldFormInput,
  TextFieldFormInputProps,
  useFlexSalesForm,
} from '../FlexSalesForm';
import { CompanyCode, EditCompanyCodeForm } from '../../types';
import {
  classCodeLength,
  companyOakIdLength,
  currencyMaxLength,
  getSchema,
  ledgerMaxLength,
} from './schema';

const inputPropsReadOnly = { readOnly: true };
const inputPropsCurreny = { maxLength: currencyMaxLength };
const inputPropsLedger = { maxLength: ledgerMaxLength };
const inputPropsClassCode = { maxLength: classCodeLength };
const inputPropsCompanyOakId = { maxLength: companyOakIdLength };

const messageOptions: OptionsObject = {
  variant: 'success',
  autoHideDuration: 6000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
};

interface CompanyCodeEditFormProps {
  companyCode: CompanyCode;
}

const TextFieldAccountedFormInput = (props: TextFieldFormInputProps) => {
  const { control } = useFlexSalesForm();
  const isAccounted = useWatch({ control, name: 'isAccounted' });

  return <TextFieldFormInput type="text" variant="standard" required={isAccounted} {...props} />;
};

const CompanyCodeForm = ({ companyCode }: CompanyCodeEditFormProps) => {
  const { t } = useTranslation();
  const countryName = useContryName({ countryId: companyCode.countryId });
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues: EditCompanyCodeForm = companyCode;
  const resolver = useMemo(() => yupResolver(getSchema(t)), [t]);
  const formListener = useListener(
    CompanyCodesActionType.companyCodesEditRequested,
    CompanyCodesActionType.companyCodesEditSuccess,
    CompanyCodesActionType.companyCodesEditFailure
  );
  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    navigate(routes.protectedNestedRoutes.companyCodes.index.path);
  }, [navigate]);

  const onSubmit = async (data: EditCompanyCodeForm) => {
    await formListener(data);
    handleCancel();
    enqueueSnackbar(t('companyCodes.messages.editSuccess'), messageOptions);
  };

  return (
    <FlexSalesForm onValidSubmit={onSubmit} defaultValues={defaultValues} resolver={resolver}>
      <Stack spacing={2}>
        <SwitchFormInput
          name="isAccounted"
          required
          label={t('companyCodes.columns.isAccounted')}
        />
        <TextFieldMui
          type="text"
          variant="standard"
          label={t('companyCodes.columns.country')}
          value={countryName}
          inputProps={inputPropsReadOnly}
          fullWidth
        />
        <TextFieldMui
          type="text"
          variant="standard"
          label={t('companyCodes.columns.name')}
          value={companyCode.name}
          inputProps={inputPropsReadOnly}
          fullWidth
        />
        <TextFieldMui
          type="text"
          variant="standard"
          label={t('companyCodes.columns.codeWh')}
          value={companyCode.codeWh}
          inputProps={inputPropsReadOnly}
          fullWidth
        />
        <TextFieldFormInput
          name="companyOakId"
          required
          label={t('companyCodes.columns.companyOakId')}
          inputProps={inputPropsCompanyOakId}
        />
        <TextFieldFormInput
          name="classCode"
          required
          label={t('companyCodes.columns.classCode')}
          inputProps={inputPropsClassCode}
        />
        <TextFieldAccountedFormInput name="codeEbs" label={t('companyCodes.columns.codeEbs')} />
        <TextFieldAccountedFormInput
          name="ledger"
          label={t('companyCodes.columns.ledger')}
          inputProps={inputPropsLedger}
        />
        <TextFieldAccountedFormInput
          name="currency"
          label={t('companyCodes.columns.currency')}
          inputProps={inputPropsCurreny}
        />
      </Stack>
      <FlexSalesFormActions
        onCancel={handleCancel}
        cancelLabel={t('companyCodes.labels.cancel')}
        submitLabel={t('companyCodes.labels.save')}
      />
    </FlexSalesForm>
  );
};

export default CompanyCodeForm;
