import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleSelectFormInput, { SimpleSelectFormInputProps } from './SimpleSelectFormInput';
import { useContrySelect } from '../../hooks';
import { useFlexSalesForm } from './FlexSalesForm';
import { Country } from '../../types';

export type CountrySelectFormInputProps = Omit<SimpleSelectFormInputProps, 'options' | 'value'> & {
  propId?: keyof Country;
};

const CountrySelectFormInput = ({
  name,
  propId,
  disabled,
  ...rest
}: CountrySelectFormInputProps) => {
  const { t } = useTranslation();
  const { options, loading, disabled: disabledProp } = useContrySelect(propId);
  const { setValue } = useFlexSalesForm();
  const isDisabled = disabled || disabledProp;
  useEffect(() => {
    if (options.length === 1) setValue(name, options[0].id);
  }, [setValue, name, options]);

  return (
    <SimpleSelectFormInput
      {...rest}
      name={name}
      label={t('toolbar.labels.country')}
      options={options}
      disabled={isDisabled}
      loading={loading}
    />
  );
};

export default CountrySelectFormInput;
