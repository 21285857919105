import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { dateLocal } from '../config/i18n.config';
import { SupportedLanguages } from '../config/i18n/translations';

interface LocalizationIntegrationProps {
  children: ReactNode;
}

const LocalizationIntegration = ({ children }: LocalizationIntegrationProps) => {
  const { i18n } = useTranslation();
  const lang = i18n.language as SupportedLanguages;
  const locale = dateLocal[lang];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};

export default LocalizationIntegration;
