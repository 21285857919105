import styled from '@emotion/styled';
import TableRow from '@mui/material/TableRow';

const HeaderRowTableFlexSales = styled(TableRow)`
  background-color: ${props => props.theme.palette.primary.main};
  .MuiTableCell-head {
    color: white;
  }
`;

export default HeaderRowTableFlexSales;
