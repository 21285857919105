import styled from '@emotion/styled';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useMuiPickersLocale } from '../../../../hooks';
import { DailySalesDataGrid } from './DailySalesDataGrid';

const lastMonthLastDay = new Date();
lastMonthLastDay.setDate(0);

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15%;
`;

const GridContainer = styled(Grid)`
  height: 150px;
  width: 100%;
`;

const DailySalesIncorporation = () => {
  const { t } = useTranslation();
  const locale = useMuiPickersLocale();

  return (
    <Card sx={{ height: '225px' }}>
      <CardContent sx={{ height: '100%' }}>
        <TitleContainer>
          <Typography variant="subtitle2">
            {t('dashboard.daily-sales.title', { date: format(new Date(), 'P', { locale }) })}
          </Typography>
        </TitleContainer>
        <GridContainer>
          <DailySalesDataGrid />
        </GridContainer>
      </CardContent>
    </Card>
  );
};

export default DailySalesIncorporation;
