import { ThemePreference } from '../../types';
import {
  ActionType,
  ChangeThemeAction,
  ChangeShowSaveDialogAction,
  UpdateAvailable,
} from './types';

export const changeTheme = (theme: ThemePreference): ChangeThemeAction => ({
  type: ActionType.changeTheme,
  theme,
});

export const changeShowSaveDialog = (showSaveDialog: boolean): ChangeShowSaveDialogAction => ({
  type: ActionType.changeShowSaveDialog,
  showSaveDialog,
});

export const changeUpdate = (registration: ServiceWorkerRegistration): UpdateAvailable => ({
  type: ActionType.updateAvailable,
  registration,
});
