import styled from '@emotion/styled';
import { CancellationsSearch, CancellationsDataGrid } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const Cancellations = () => (
  <Container>
    <CancellationsSearch />
    <CancellationsDataGrid />
  </Container>
);

export default Cancellations;
