import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSimpleSelectOptionMapper } from '../components';
import { masterDataSelector } from '../state';
import { Stores } from '../types';

const storesSort = (a: Stores, b: Stores) => (a.storeAcronym > b.storeAcronym ? 1 : -1);

const useStoreSelect = (countryCode: string, emptySelection: string | undefined = undefined) => {
  const masterData = useSelector(masterDataSelector);

  const storesForDivisonCountry = useMemo(() => {
    const stores = masterData.loaded && masterData.data ? masterData.data.stores : [];
    return stores.filter(i => i.countryCode === countryCode).sort(storesSort);
  }, [masterData, countryCode]);
  const disabled = !countryCode;

  const options = useSimpleSelectOptionMapper(
    storesForDivisonCountry || [],
    'storeAcronym',
    'storeAcronym'
  );

  if (emptySelection !== undefined && options.length && options[0].id)
    options.unshift({ id: '', description: emptySelection });

  return { options, disabled, loading: masterData.loading };
};

export default useStoreSelect;
