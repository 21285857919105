import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useContrySelect } from '../../../hooks';
import { Country } from '../../../types';
import SimpleSelect, { SimpleSelectProps } from '../SimpleSelect';

export interface CountrySimpleSelectProps extends Omit<SimpleSelectProps, 'options' | 'value'> {
  value: string;
  propId?: keyof Country;
}

const CountrySelect = ({
  value: countryCode,
  onChange,
  propId,
  ...others
}: CountrySimpleSelectProps) => {
  const { t } = useTranslation();
  const { options, loading, disabled } = useContrySelect(propId);

  useEffect(() => {
    if (options.length === 1) onChange(options[0].id);
  }, [onChange, options]);

  return (
    <SimpleSelect
      {...others}
      label={t('toolbar.labels.country')}
      options={options}
      disabled={disabled}
      value={countryCode}
      loading={loading}
      onChange={onChange}
    />
  );
};

export default CountrySelect;
