import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ActionType } from './types';
import { GetPermissionsResponse, SalesApi } from '../../services';
import { permissionsRequested, permissionsSuccess, permissionsFailure } from './actionCreators';
import { HttpRequestError } from '../../types';

function* getPermissions() {
  yield put(permissionsRequested());
  try {
    // TODO: Cambiar de nuevo a RsApi después de implementar cambios de roles de FlexVentas
    const { data }: AxiosResponse<GetPermissionsResponse> = yield call(SalesApi.getPermissions);
    yield put(permissionsSuccess(data));
  } catch (error) {
    yield put(permissionsFailure(error as HttpRequestError));
  }
}

export default [takeLatest(ActionType.msalSignInSuccess, getPermissions)];
