import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import { useCallback } from 'react';
import DatePickerFlexSales, { DatePickerFlexSalesProps } from '../DatePickerFlexSales';
import { useFlexSalesForm } from './FlexSalesForm';

export type DatePickerComponentProps = Omit<DatePickerFlexSalesProps<Date>, 'name'>;

export type DatePickerFormInputProps<TFieldValues extends FieldValues = FieldValues> =
  DatePickerComponentProps & {
    name: Path<TFieldValues>;
  };

const DatePickerFormInput = <TFieldValues extends FieldValues = FieldValues>({
  name,
  required,
  defaultValue,
  ...rest
}: DatePickerFormInputProps<TFieldValues>) => {
  const { control } = useFlexSalesForm<TFieldValues>();

  const render: ControllerProps<TFieldValues>['render'] = useCallback(
    ({ field: { ref, ...restField }, fieldState: { error } }) => (
      <DatePickerFlexSales
        required={required}
        {...restField}
        {...rest}
        error={error?.message}
        inputRef={ref}
      />
    ),
    [rest, required]
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={(defaultValue || null) as never}
      render={render}
    />
  );
};

export default DatePickerFormInput;
