import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStorePermissions from '../../../hooks/useStorePermissions';
import {
  changeMissingClosingTotalFilteredStores,
  masterDataSelector,
  reportFilterStoresSelector,
} from '../../../state';
import {
  Agreement,
  Company,
  Management,
  Region,
  RegionalManagement,
  Stores,
  Supervision,
} from '../../../types';
import { usePermissions } from '../../Permissions';
import MultiSelect, { MultiSelectProps } from '../MultiSelect';

export interface StoreMultiSelectProps
  extends Omit<MultiSelectProps<Stores>, 'options' | 'propDesc'> {
  countryCode: string;
  regionalManagements: RegionalManagement[];
  regions: Region[];
  managements: Management[];
  supervisions: Supervision[];
  agreements: Agreement[];
  companies: Company[];
  selectAll?: boolean;
}

const storesSort = (a: Stores, b: Stores) => (a.storeAcronym > b.storeAcronym ? 1 : -1);

const StoreMultiSelect = ({
  disabled,
  countryCode,
  regionalManagements,
  regions,
  managements,
  supervisions,
  agreements,
  companies,
  onChange,
  selectAll,
  ...others
}: StoreMultiSelectProps) => {
  const masterData = useSelector(masterDataSelector);
  const stores = useSelector(reportFilterStoresSelector);
  const { resourcePermissions } = usePermissions();

  const storesForCountry = useMemo(() => {
    const stores = masterData.loaded && masterData.data ? masterData.data.stores : [];
    return stores.filter(i => i.countryCode === countryCode).sort(storesSort);
  }, [masterData, countryCode]);

  const storesForPermissions = useStorePermissions(
    resourcePermissions,
    countryCode,
    storesForCountry
  ) as Stores[];

  const storesForRegionalManagement = useMemo(() => {
    const stores = storesForPermissions;
    return regionalManagements.length > 0
      ? stores
          .filter(r => regionalManagements.map(i => i.regionalMgmtId).includes(r.regionalMgmtId))
          .sort(storesSort)
      : stores;
  }, [regionalManagements, storesForPermissions]);

  const storesForRegion = useMemo(() => {
    const stores = storesForRegionalManagement;
    return regions.length > 0
      ? stores.filter(r => regions.map(i => i.regionId).includes(r.regionId)).sort(storesSort)
      : stores;
  }, [storesForRegionalManagement, regions]);

  const storesForManagement = useMemo(() => {
    const stores = storesForRegion;
    return managements.length > 0
      ? stores.filter(r => managements.map(i => i.mgmtId).includes(r.mgmtId)).sort(storesSort)
      : stores;
  }, [storesForRegion, managements]);

  const storesForSupervision = useMemo(() => {
    const stores = storesForManagement;
    return supervisions.length > 0
      ? stores
          .filter(r => supervisions.map(i => i.supervisionId).includes(r.supervisionId))
          .sort(storesSort)
      : stores;
  }, [storesForManagement, supervisions]);

  const storesForAgreement = useMemo(() => {
    const stores = storesForSupervision;
    return agreements.length > 0
      ? stores
          .filter(r => agreements.map(i => i.agreementId).includes(r.agreementId))
          .sort(storesSort)
      : stores;
  }, [storesForSupervision, agreements]);

  const storesForCompany = useMemo(() => {
    const stores = storesForAgreement;
    return companies.length > 0
      ? stores.filter(r => companies.map(i => i.companyId).includes(r.companyId)).sort(storesSort)
      : stores;
  }, [storesForAgreement, companies]);

  const isDisabled = disabled || !countryCode;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeMissingClosingTotalFilteredStores(storesForCompany));
  }, [dispatch, storesForCompany]);

  useEffect(() => {
    if (
      storesForCompany.length === 1 &&
      !stores.find(i => i.storeAcronym === storesForCompany[0].storeAcronym)
    )
      onChange(storesForCompany);
  }, [onChange, stores, storesForCompany]);

  return (
    <MultiSelect<Stores>
      {...others}
      options={storesForCompany}
      propDesc="storeAcronym"
      disabled={isDisabled}
      loading={masterData.loading}
      onChange={onChange}
      selectAll={selectAll}
    />
  );
};

export default StoreMultiSelect;
