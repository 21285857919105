import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  changeFilterItemDiscount,
  reportFilterItemDiscountSelector,
  reportItemDiscountOptionsSelector,
} from '../../../state';
import { Discount } from '../../../types';
import { MultiSelect } from '../../selects';
import routes from '../../../config/routes';

const ItemDiscountFilter = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const discountResource = useSelector(reportItemDiscountOptionsSelector);
  const { data, loading } = discountResource;
  const discount = useSelector(reportFilterItemDiscountSelector);

  const disabled = !discountResource.loaded;

  const handleChange = useCallback(
    (value: Discount[]) => {
      dispatch(changeFilterItemDiscount(value));
    },
    [dispatch]
  );

  const options = useMemo(() => {
    if (location.pathname === routes.protectedRoutes.gcs.path) {
      return data.filter(i => [0, 14].includes(i.transactionType));
    }
    return data;
  }, [location, data]);

  return (
    <MultiSelect
      label={t('toolbar.labels.item_discount')}
      propDesc="description"
      options={options}
      disabled={disabled}
      value={discount}
      onChange={handleChange}
      loading={loading}
    />
  );
};

export default ItemDiscountFilter;
