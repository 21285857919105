import { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import AccountingMonthFilter from './AccountingMonthFilter';
import StatusFilter from './StatusFilter';
import JournalTypeFilter from './JournalTypeFilter';
import { AppPermissions } from '../../../types';
import { PermissionsProvider } from '../../Permissions';
import { DivisionCountryFilter } from '../../filters';
import {
  accountingEntriesChangeFilterCountry,
  accountingEntriesFilterCountrySelector,
} from '../../../state';

export interface AccountingEntriesFiltersProps {
  actions: ReactNode;
}

const AccountingEntriesFilters = ({ actions }: AccountingEntriesFiltersProps) => (
  <PermissionsProvider
    resourcePermissions={[
      AppPermissions.ACCOUNTING_ENTRIES_COMPLETE,
      AppPermissions.ACCOUNTING_ENTRIES_QUERY,
    ]}
  >
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12} md={4} lg={2} xl={2}>
        <DivisionCountryFilter
          valueSelector={accountingEntriesFilterCountrySelector}
          actionChange={accountingEntriesChangeFilterCountry}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4} lg={2} xl={2}>
        <AccountingMonthFilter />
      </Grid>
      <Grid item xs={12} md={4} lg={2} xl={2}>
        <JournalTypeFilter fullWidth />
      </Grid>
      <Grid item xs={12} md={4} lg={2} xl={2}>
        <StatusFilter fullWidth />
      </Grid>
      <Grid item xs={12} md lg xl>
        {actions}
      </Grid>
    </Grid>
  </PermissionsProvider>
);

export default AccountingEntriesFilters;
