import { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import CountrySelect from './CountryFilter';
import OakBrookMonthFilter from './OakBrookMonthFilter';
import { AppPermissions } from '../../../types';
import { PermissionsProvider } from '../../Permissions';

export interface OakBrookFiltersProps {
  actions: ReactNode;
}

const OakBrookFilters = ({ actions }: OakBrookFiltersProps) => (
  <PermissionsProvider resourcePermissions={[AppPermissions.OAK_BROOK_SALES]}>
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12} md={4} lg={2} xl={2}>
        <CountrySelect fullWidth />
      </Grid>
      <Grid item xs={12} md={4} lg={2} xl={2}>
        <OakBrookMonthFilter />
      </Grid>
      <Grid item xs={12} md lg xl>
        {actions}
      </Grid>
    </Grid>
  </PermissionsProvider>
);

export default OakBrookFilters;
