import { Locale } from 'date-fns';
import { ValidationErrorCause, CollectionsDifferencesCause, Translation } from '../../../types';
import { DataGridHandler } from '../../DataGridFlexSales';

export interface CollectionsMissingsDataGridContext {
  t: Translation;
}

const initialContext: CollectionsMissingsDataGridContext = {
  t: () => '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<
    CollectionsDifferencesCause & ValidationErrorCause,
    CollectionsMissingsDataGridContext
  >(initialContext, locale);

  handler.addColumn({
    accessor: 'transferType',
    type: 'string',
    title: ({ t }) => t('missing-closing-causes.columns.transfer-type'),
  });

  handler.addColumn({
    accessor: 'sequence',
    type: 'string',
    title: ({ t }) => t('missing-closing-causes.columns.sequence'),
  });

  handler.addColumn({
    accessor: 'closeTransferQty',
    type: 'number',
    title: ({ t }) => t('missing-closing-causes.columns.close-transfer-qty'),
  });

  handler.addColumn({
    accessor: 'detailTransferQty',
    type: 'number',
    title: ({ t }) => t('missing-closing-causes.columns.detail-transfer-qty'),
  });

  return handler;
};
