import { Actions, State, ActionType } from './types';
import {
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoading,
  remoteResourceLoadSuccess,
} from '../../helpers';

const initialState: State = {
  accountingParameters: remoteResourceInitialState([]),
  accountingParameter: remoteResourceInitialState(undefined),
  country: null,
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionType.accountingParametersFindRequested:
      return {
        ...state,
        accountingParameters: remoteResourceLoading([]),
      };
    case ActionType.accountingParametersFindSuccess:
      return {
        ...state,
        accountingParameters: remoteResourceLoadSuccess(action.accountingParameters),
      };
    case ActionType.accountingParametersFindFailure:
      return {
        ...state,
        accountingParameters: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.accountingParametersGetRequested:
      return {
        ...state,
        accountingParameter: remoteResourceLoading(undefined),
      };
    case ActionType.accountingParametersGetSuccess:
      return {
        ...state,
        accountingParameter: remoteResourceLoadSuccess(action.accountingParameter),
      };
    case ActionType.accountingParametersGetFailure:
      return {
        ...state,
        accountingParameter: remoteResourceLoadFailure(undefined, action.error),
      };
    case ActionType.accountingParametersChangeCountry:
      return {
        ...state,
        country: action.country,
      };
    default:
      return state;
  }
};

export default reducer;
