import { Locale } from 'date-fns';
import { Translation, MissingClosingCauses } from '../../../types';
import { DataGridHandler } from '../../DataGridFlexSales';

export interface HeaderDataGridContext {
  t: Translation;
}

const initialContext: HeaderDataGridContext = {
  t: () => '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<MissingClosingCauses, HeaderDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    name: 'cause',
    type: 'string',
    title: ({ t }) => t('missing-closing-causes.columns.cause'),
    width: { px: 400, pt: 350 },
    valueGetter: (row, { t }) =>
      row.cause ? (t(`missing-closing-detail.causes.${row.cause}`) as string) : '',
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t }) => t('missing-closing-causes.columns.store'),
    width: { px: 150, pt: 100 },
  });

  handler.addColumn({
    accessor: 'date',
    type: 'date',
    title: ({ t }) => t('missing-closing-causes.columns.date'),
    width: { px: 120, pt: 115 },
  });

  return handler;
};
