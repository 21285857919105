import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  ActionType,
  MonthClosingAnnulRequestedAction,
  MonthClosingCloseRequestedAction,
} from './types';
import { SalesApi } from '../../services';
import {
  monthClosingFindSuccess,
  monthClosingFindFailure,
  monthClosingCloseSuccess,
  monthClosingCloseFailure,
  monthClosingAnnulSuccess,
  monthClosingAnnulFailure,
} from './actionCreators';
import { HttpRequestError, MonthClosingFilter } from '../../types';
import {
  findMonthClosingRequestMap,
  findMonthClosingResponseMap,
  monthClosingAnnulRequestMap,
  monthClosingCloseRequestMap,
} from '../../helpers';
import { monthClosingFilterSelector } from '../selectors';
import { FindMonthClosingResponse } from '../../services/SalesApi';

function* findMonthClosing() {
  const filters: MonthClosingFilter = yield select(monthClosingFilterSelector);

  try {
    const { data }: AxiosResponse<FindMonthClosingResponse> = yield call(
      SalesApi.findMonthClosing,
      findMonthClosingRequestMap(filters)
    );

    yield put(monthClosingFindSuccess(findMonthClosingResponseMap(data)));
  } catch (error) {
    yield put(monthClosingFindFailure(error as HttpRequestError));
  }
}

function* closeMonth({ payload }: MonthClosingCloseRequestedAction) {
  try {
    yield call(SalesApi.closeMonth, monthClosingCloseRequestMap(payload));
    yield put(monthClosingCloseSuccess());
  } catch (error) {
    yield put(monthClosingCloseFailure(error as HttpRequestError));
  }
}

function* annulMonthClosing({ payload }: MonthClosingAnnulRequestedAction) {
  try {
    yield call(SalesApi.annulMonthClosing, monthClosingAnnulRequestMap(payload));
    yield put(monthClosingAnnulSuccess());
  } catch (error) {
    yield put(monthClosingAnnulFailure(error as HttpRequestError));
  }
}

export default [
  takeLatest(ActionType.monthClosingFindRequested, findMonthClosing),
  takeLatest(ActionType.monthClosingCloseRequested, closeMonth),
  takeLatest(ActionType.monthClosingAnnulRequested, annulMonthClosing),
];
