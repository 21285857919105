import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { hasPermissions } from '../../helpers';
import { CompanyCode, AppPermissions } from '../../types';
import { commonColProps, DataFlexSalesActionButton, DataGridHandler } from '../DataGridFlexSales';
import routes from '../../config/routes';

export interface CompanyCodeDataGridContext {
  t: (key: string | string[]) => string;
  userPermissions: string[];
}

const initialContext: CompanyCodeDataGridContext = {
  t: () => '',
  userPermissions: [],
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<CompanyCode, CompanyCodeDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'countryId',
    type: 'string',
    title: ({ t }) => t('companyCodes.columns.country'),
    ...commonColProps.countryId,
  });
  handler.addColumn({
    accessor: 'name',
    type: 'string',
    title: ({ t }) => t('companyCodes.columns.name'),
    width: { px: 170, pt: 160 },
  });
  handler.addColumn({
    accessor: 'codeWh',
    type: 'string',
    title: ({ t }) => t('companyCodes.columns.codeWh'),
    width: { px: 175, pt: 162 },
  });
  handler.addColumn({
    accessor: 'codeEbs',
    type: 'string',
    title: ({ t }) => t('companyCodes.columns.codeEbs'),
    width: { px: 135, pt: 120 },
  });
  handler.addColumn({
    accessor: 'ledger',
    type: 'string',
    title: ({ t }) => t('companyCodes.columns.ledger'),
  });
  handler.addColumn({
    accessor: 'currency',
    type: 'string',
    title: ({ t }) => t('companyCodes.columns.currency'),
  });
  handler.addColumn({
    accessor: 'isAccounted',
    type: 'boolean',
    title: ({ t }) => t('companyCodes.columns.isAccounted'),
  });
  handler.addColumn({
    accessor: 'companyOakId',
    type: 'string',
    title: ({ t }) => t('companyCodes.columns.companyOakId'),
    width: { px: 100, pt: 90 },
  });
  handler.addColumn({
    accessor: 'classCode',
    type: 'string',
    title: ({ t }) => t('companyCodes.columns.classCode'),
    width: { px: 100, pt: 90 },
  });

  handler.addAction({
    isVisible: ({ context }) =>
      hasPermissions(context.userPermissions, AppPermissions.COMPANY_CODES),
    Button: ({ row }) => {
      const navigate = useNavigate();
      const countryIdCompanyId = `${row.countryId}-${row.codeWh}`;
      const path = `${routes.protectedNestedRoutes.companyCodes.index.path}/${countryIdCompanyId}`;
      const handleClickApply = useCallback(() => navigate(path), [navigate, path]);
      const icon = useCallback(() => <EditIcon fontSize="small" />, []);

      return <DataFlexSalesActionButton onClick={handleClickApply} icon={icon} />;
    },
  });

  return handler;
};
