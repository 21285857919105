import { ReactNode } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import DialogFlexSales from '../../DialogFlexSales';

export interface ReportFiltersDialogProps {
  onApply: () => void;
  onClose: () => void;
  children: ReactNode;
}

const ReportFiltersDialog = ({ children, onApply, onClose }: ReportFiltersDialogProps) => {
  const { t } = useTranslation();

  return (
    <DialogFlexSales onClose={onClose} open scroll="body">
      <DialogTitle>{t('toolbar.labels.filter')}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>{children}</Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onApply}>{t('navigation.buttons.close')}</Button>
      </DialogActions>
    </DialogFlexSales>
  );
};

export default ReportFiltersDialog;
