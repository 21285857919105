import { utils, writeFile } from 'xlsx';
import { TablesExportBase } from './TablesExportBase';

const getUniqueSheetName = (name: string, sheetNames: string[]) => {
  const count = sheetNames.filter(i => i.toLowerCase().trim() === name.toLowerCase().trim()).length;
  return count ? `${name}_${count + 1}` : name;
};

export class TablesExportXlsx extends TablesExportBase<'XLSX'> {
  constructor() {
    super('XLSX');
  }

  export(filename: string): void {
    const wb = utils.book_new();
    const sheetNames: string[] = [];

    this.tables.forEach(({ headers, rows, sheetName, totals = [] }) => {
      const data = [headers, ...rows, totals];
      const ws = utils.aoa_to_sheet(data, { cellDates: true });
      utils.book_append_sheet(wb, ws, getUniqueSheetName(sheetName, sheetNames));
      sheetNames.push(sheetName);
    });

    writeFile(wb, `${filename}.xlsx`, { cellDates: true });
  }
}
